<template>
  <button
    type="button"
    class="btn"
    :class="[
      {'btn--sm': small},
      {'btn--xs': tiny},
      {'cursor-wait': loading || isBusy},
      color
    ]"
    :disabled="disabled || loading || isBusy"
    @click="clicked"
  >
    <ui-icon
      v-if="icon && icon != '' && !loading && !isBusy"
      :name="icon"
      :class="[
        {'h-5 w-5': !small && !tiny},
        {'h-4 w-4': small},
        {'h-3 w-3': tiny},
        {'mr-1': hasChildren}
      ]"
    />
    <ui-icon
      v-if="loading || isBusy"
      name="animate-spin-circle"
      class="animate-spin"
      :class="[
        {'h-5 w-5': !small},
        {'h-4 w-4': small}
      ]"
    />
    <slot v-if="!loading && !isBusy"></slot>
  </button>
</template>

<style scoped>
  .btn {
    @apply
      inline-flex justify-center content-center items-center
      pt-3 md:pt-2 pb-2 px-3
      border border-transparent
      shadow-sm rounded-sm
      text-sm font-medium
      focus:outline-none focus:ring-1 focus:ring-offset-transparent focus:ring-red-400
      select-none;
  }
  .btn--sm {
    @apply pb-1 pt-1 px-2 text-xs;
  }
  .btn--xs {
    @apply pb-0 pt-0 px-1 text-2xs;
  }
  .default {
    @apply
      text-gray-700 dark:text-gray-200 dark:hover:text-white-100
      bg-white-100 dark:bg-gray-600
      border-gray-200 dark:border-gray-600 dark:hover:border-gray-700
      hover:shadow
      hover:bg-gray-100 hover:border-black-900 dark:hover:bg-gray-700
      focus:ring-white-100;
  }
  .primary {
    @apply
      text-white-100 bg-red-400
      hover:bg-red-700
      focus:ring-white-100;
  }
  .secondary {
    @apply
      bg-white-100/75 dark:bg-black-900/50 text-gray-700 dark:text-gray-200
      hover:bg-white-100 hover:text-black-900 dark:hover:bg-black-900 dark:hover:text-white-100
      focus:ring-white-100;
  }
  .danger {
    @apply
      text-white-100 bg-red-400
      hover:bg-red-700
      focus:ring-white-100;
  }
  .dark {
    @apply bg-black-900/50 text-white-100 hover:bg-black-900/75;
  }
  .clear {
    @apply
      px-2
      text-gray-700 dark:text-gray-400 bg-transparent border-transparent shadow-none
      hover:bg-white-100 hover:text-black-900 dark:hover:bg-gray-900 dark:hover:text-white-100 hover:shadow-sm;
  }
  .success {
    @apply
      text-green-400
      border-green-400
      focus:ring-green-400;
  }
  .btn:disabled {
    @apply opacity-50 cursor-not-allowed;

    &:hover {
      &.primary,
      &.danger {
        @apply bg-red-400;
      }
      &.secondary {
        @apply bg-white-100/75 dark:bg-black-900/50 text-gray-700 dark:text-gray-200;
      }
      &.dark {
        @apply bg-black-900/50;
      }
      &.clear {
        @apply text-gray-700 dark:text-gray-400 bg-transparent border-transparent shadow-none;
      }
    }
  }
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiButton',

  components: {
    uiIcon
  },

  setup(props, { slots }) {
    let hasChildren = false;
    if (slots.default) {
      hasChildren = slots.default().length >= 1;
    }
    return { hasChildren };
  },

  data() {
    return {
      isBusy: false
    };
  },

  props: {
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return [
          'default',
          'primary',
          'secondary',
          'danger',
          'dark',
          'clear',
          'success'
        ].indexOf(value) !== -1
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    busy: {
      type: String
    }
  },
  methods: {
    clicked(val) {
      this.$gtag.event('click', {
        'event_category': 'Interaction',
        'event_label': 'Button',
        'value': this.$el.dataset.title || this.$el.innerText
      })

      if (typeof this.busy === 'string') {
        const busyNumber = parseInt(this.busy);
        // Set default busy time to 1 second
        const busyLength = busyNumber ? busyNumber : 1000;
        // Get clicked button dimensions and apply to busy state
        const evtBtn = val.target.closest('button');
        const {width, height} = evtBtn.getBoundingClientRect();

        // Set busy state
        this.isBusy = true;
        evtBtn.style.width = `${width}px`;
        evtBtn.style.height = `${height}px`;

        setTimeout(() => {
          // Remove busy state
          this.isBusy = false;
          evtBtn.style.width = null;
          evtBtn.style.height = null;
        }, busyLength);
      }
    }
  }
}
</script>
