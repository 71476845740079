<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>
        {{ $t('views.settings.apiTokens') }}

        <template v-slot:actionButton>
          <div>
            <ui-button
              v-if="$isMobile()"
              @click="this.$router.push({ name: 'SettingsApiTokensAdd' });"
              color="clear"
              :data-title="$t('views.settings.apiTokenAdd')"
            >
              <ui-icon name="plus" class="h-5 w-5 inline-block text-red-400" />
            </ui-button>
            <ui-button
              v-else
              @click="this.$router.push({ name: 'SettingsApiTokensAdd' });"
              color="primary"
            >
              {{ $t('views.settings.apiTokenAdd') }}
            </ui-button>
          </div>
        </template>
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box tight>
        <div class="px-6 py-4" v-if="items?.length == 0">
          <ui-alert>
            No API keys created.
          </ui-alert>
        </div>
        <ui-grid
          :loading="loading"
          v-else-if="items?.length > 0"
        >
          <ui-grid-item
            v-for="( item ) in items"
            :key="item.id"
          >
            <!-- item name & date created -->
            <ui-grid-item-col type="main">
              <ui-grid-item-col>
                <ui-grid-item-col-content type="heading">
                  {{ item.name }}
                </ui-grid-item-col-content>
                <ui-grid-item-col-content type="text">
                  <time datetime="{{ item.created_on }}">
                    {{ $filters.dateTimeFormat(item.created_on) }}
                  </time>
                </ui-grid-item-col-content>
                <ui-grid-item-col-content type="text">
                  <ui-badge class="mt-1" :title="item.scope">
                    <template v-if="$store.getters['account/apiKeyScopeById'](item.scope)?.title">{{ $store.getters['account/apiKeyScopeById'](item.scope)?.title }}</template>
                    <template v-else>{{ item.scope }}</template>
                  </ui-badge>
                </ui-grid-item-col-content>
              </ui-grid-item-col>
            </ui-grid-item-col>

            <!-- action button - delete api key -->
            <ui-grid-item-col type="right-action">
              <ui-grid-item-col-content>
                <ui-button @click.stop="confirmItemDelete(item)" small>
                  {{ $t('views.settings.delete') }}
                </ui-button>
              </ui-grid-item-col-content>
            </ui-grid-item-col>

          </ui-grid-item>
        </ui-grid>

        <ui-confirm-dialog
          ref="confirmDeleteDialog"
          :headerText="$t('views.settings.apiTokenDeleteTitle')"
          :confirmBtnText="$t('views.settings.deleteConfirm')"
          :dismissBtnText="$t('views.settings.deleteCancel')"
        >
          <p>{{ $t('views.settings.apiTokenDeleteWarning') }}</p>
        </ui-confirm-dialog>
      </ui-page-section-box>
    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SettingsApiTokens',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton,
    uiConfirmDialog,
    uiAlert,
    uiIcon,
    uiBadge,
    SettingsNavigation
  },

  data: () => ({
    items: null,
    loading: true,
    contextMenuItemId: null
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('account', { loadApiKeys: 'apiKeys' }),
    ...mapGetters('account', { getApiKeys: 'apiKeys' }),
    ...mapActions('account', ['deleteApiKey']),

    async init() {
      this.loading = true;
      this.items = null;
      await this.loadApiKeys();
      this.items = this.getApiKeys();
      this.loading = false;
    },

    confirmItemDelete: function(item) {
      this.$refs.confirmDeleteDialog.showDialog().then((result) => {
        if (result) {
          this.loading = true;
          this.deleteApiKey({ id: item.id }).then(() => {
            this.init();
          });
        }
      });
    }
  }
}
</script>
