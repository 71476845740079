import httpClient from '@/httpClient';
import { throwNetworkUnhandledError } from '@/errors';

export function loadPrinters({ dispatch, rootGetters }) {
  return dispatch('loadPrintersByGroupId', { id: rootGetters['account/currentGroupIdOrLogout'] });
}

export function loadPrintersByGroupId({ commit }, payload) {
  if (payload.id) {
    // let url = '/groups/' + payload.id + '/printers/?fields=status,client';
    let url = '/groups/' + payload.id + '/printers/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setPrinters', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export async function loadPrintersStates({ commit, rootGetters }) {
  let url = '/groups/' + rootGetters['account/currentGroupIdOrLogout'] + '/devices-states/';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setPrintersStatesData', response?.data);
    return response?.data;
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadCameraSnapshot(commit, payload) {
  let url = '/printers/' + payload.id + '/snapshot/';
  return httpClient.request({
    responseType: 'arraybuffer',
    url: url,
    method: 'get',
    authorizeRequest: true,
    headers: {
      'if-none-match': payload.etag
    }
  }).then(function (response) {
    return response;
  }).catch(function (error) {
    return error;
  });
}

export function printGcode(commit, payload) {
  let url = '/printers/' + payload.printerId + '/files/';
  return httpClient.post(url,
    { file_id: payload.gcodeId, print: true, make_video: payload.makeVideo },
    { authorizeRequest: true }
  ).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadPrinterLocalFiles({ commit }, payload) {
  let url = '/printers/' + payload.printerId + '/files/';
  return httpClient.get(url, { authorizeRequest: true }).then((response) => {
    commit('setPrinterLocalFiles', { data: response?.data, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function startPrinterLocalFile(commit, payload) {
  let url = '/printers/' + payload.printerId + '/files/start';
  return httpClient.post(url, { path: payload.path }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadKlipperMacros({ commit }, payload) {
  let url = '/printers/' + payload.printerId + '/klipper-macros/';
  return httpClient.get(url, { authorizeRequest: true }).then((response) => {
    commit('setPrinterKlipperMacrosData', { data: response?.data, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadKlipperTerminalHistory({ commit }, payload) {
  let url = '/printers/' + payload.printerId + '/klipper-gcode-store/';
  return httpClient.get(url, { authorizeRequest: true }).then((response) => {
    commit('setPrinterKlipperTerminalHistoryData', { data: response?.data, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadKlipperPrinterInfo({ commit }, payload) {
  let url = '/printers/' + payload.printerId + '/klipper-printer-info/';
  return httpClient.get(url, { authorizeRequest: true }).then((response) => {
    commit('setPrinterKlipperPrinterInfo', { data: response?.data, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function addPrinter(commit, payload) {
  let url = '/printers/';
  let obj = {
    api_key: payload.apiKey,
    group: payload.groupId,
    name: payload.name,
    protocol: 'http',  // TODO - not implemented
    token: payload.token,
    camera_enabled: payload.cameraEnabled,
    camera_rotation: payload.cameraRotation,
    jobox_enabled: payload.joboxEnabled
  };
  return httpClient.post(url, obj, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function updatePrinter({ commit }, payload) {
  let url = '/printers/' + payload.id + '/';
  let obj = {
    name: payload.name,
    note: payload.note,
    token: payload.token,
    api_key: payload.apiKey,
    camera_enabled: payload.cameraEnabled,
    camera_rotation: payload.cameraRotation,
    jobox_enabled: payload.joboxEnabled,
    enabled: payload.enabled,
    filament_type: payload.filamentType
  };
  return httpClient.patch(url, obj, { authorizeRequest: true }).then(function (response) {
    if (response?.data?.client?.octoprint?.error?.code != 'moved-to-background') {
      commit('setPrinterBaseData', response?.data);
    }
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function deletePrinter({ commit }, payload) {
  let url = '/printers/' + payload.id + '/';
  return httpClient.delete(url, { authorizeRequest: true }).then(() => {
    commit('deletePrinter', payload.id);
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function connect(commit, payload) {
  let url = '/printers/' + payload.printerId + '/connection/';
  return httpClient.post(url, null, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function disconnect(commit, payload) {
  let url = '/printers/' + payload.printerId + '/connection/';
  return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function setKarmenLed(commit, payload) {
  let url = '/printers/' + payload.printerId + '/led/';
  return httpClient.put(url, { color: payload.color }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function executeControlCommand(commit, payload) {
  let url = '/printers/' + payload.printerId + '/' + payload.action + '/';
  return httpClient.post(url, payload.obj, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadPrintHistoryNext({ commit }, payload) {
  return httpClient.get(payload.url, { authorizeRequest: true }).then(function (response) {
    commit('setPrinterPrintJobs', { data: response?.data, append: true, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadPrintHistory({ commit, rootGetters }, payload) {
  let url = '/groups/' + rootGetters['account/currentGroupIdOrLogout'] + '/print-jobs/?printer_id=' + payload.printerId;
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setPrinterPrintJobs', { data: response?.data, append: false, printerId: payload.printerId });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

// TODO: we don't know spectoda flashlight state, so I just switch light based
// spectodaFlashlighState value which change to oposite every time switched
let spectodaFlashlighState = false;
export function switchSpectodaFlashlight(commit, payload) {
  let url = '/printers/' + payload.printerId + '/spectoda/flashlight/';

  // TODO: see TODO above...
  spectodaFlashlighState = !spectodaFlashlighState;

  return httpClient.put(url, {'percentage': spectodaFlashlighState ? 0 : 100}, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function changeSpectodaColor(commit, payload) {
  let url = '/printers/' + payload.printerId + '/spectoda/color/';
  return httpClient.put(url, {'color': payload.value}, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function changeSpectodaBrightness(commit, payload) {
  let url = '/printers/' + payload.printerId + '/spectoda/brightness/';
  return httpClient.put(url, {'percentage': payload.value}, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function changePowerState(commit, payload) {
  let url = '/printers/' + payload.printerId + '/power/';
  if (payload.state) { // power on
    return httpClient.post(url, {}, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  } else { // power off
    return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}
