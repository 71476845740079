export function printerGroups (state) {
  return state.printerGroups;
}

export function printerGroupById (state) {
  return (id) => {
    for (let i = 0; i < state.printerGroups.length; i++) {
      if (state.printerGroups[i].id == id) {
        return state.printerGroups[i];
      }
    }
    return null;
  }
}
