<template>
  <div
    v-if="visible"
    class="flex fixed z-50 inset-0 overflow-y-auto bg-black-900 p-2"
    @click.stop.prevent="closeCallback();"
  >
    <img
      :src="image"
      class="max-w-full max-h-full h-full w-full object-contain"
    />
    <div class="absolute top-3 right-3">
      <ui-button @click.stop.prevent="closeCallback()" icon="x" color="dark"></ui-button>
    </div>
  </div>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'uiFullscreenImage',

  components: {
    uiButton,
  },

  emits: [
    'onDismiss',
    'onConfirm'
  ],

  props: {
    image: {
      type: String,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    closeCallback: {
      type: Function
    }
  },
}
</script>
