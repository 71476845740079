<template>
  <ui-aside-menu>
    <ui-aside-menu-item
      routeName="SettingsAccount"
      iconName="user"
      data-title="SettingsNavigation: Account"
    >
      {{ $t('views.settings.account') }}
    </ui-aside-menu-item>
    <ui-aside-menu-item
      routeName="SettingsPrinters"
      iconName="printer"
      data-title="SettingsNavigation: Printers"
    >
      {{ $t('views.settings.printers') }}
    </ui-aside-menu-item>
    <ui-aside-menu-item
      routeName="SettingsWorkspaces"
      iconName="user-group"
      data-title="SettingsNavigation: Workspaces"
    >
      {{ $t('views.settings.workspaces') }}
    </ui-aside-menu-item>
    <ui-aside-menu-item
      routeName="SettingsAppearance"
      iconName="photograph"
      data-title="SettingsNavigation: Appearance"
    >
      {{ $t('views.settings.appearance.title') }}
    </ui-aside-menu-item>
    <ui-aside-menu-item
      routeName="SettingsNotifications"
      iconName="eye"
      data-title="SettingsNavigation: Notifications"
    >
      {{ $t('views.settings.notifications.title') }}
    </ui-aside-menu-item>
    <ui-aside-menu-item
      routeName="SettingsApiTokens"
      iconName="lock-open"
      data-title="SettingsNavigation: API Tokens"
    >
      {{ $t('views.settings.apiTokens') }}
    </ui-aside-menu-item>
  </ui-aside-menu>
</template>

<script>
import uiAsideMenu from '@/components/ui/uiAsideMenu.vue';
import uiAsideMenuItem from '@/components/ui/uiAsideMenuItem.vue';

export default {
  name: 'SettingsNavigation',

  components: {
    uiAsideMenu,
    uiAsideMenuItem
  }
}
</script>
