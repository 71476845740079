<template>
  <unauthorized-layout
    :headerText="headerText"
    :loading="loading"
    :showError="showError"
    :errorHeaderText="errorHeaderText"
  >
    <div v-if="!sent && !tokenError">
      <p class="mt-2 mb-8 text-center text-sm text-gray-700 dark:text-gray-400 max-w">
        Please choose password to finish your account activation.<br/>
      </p>
      <ui-form class="px-8">
        <ui-form-input
          v-model:value="email"
          labelText="Your Email"
          id="email"
          type="email"
          :required="true"
          :disabled="true"
        ></ui-form-input>
        <ui-form-input
          v-model:value="password"
          labelText="New Password"
          id="password"
          type="password"
          :required="true"
        ></ui-form-input>
        <ui-form-input
          v-model:value="passwordAgain"
          labelText="Confirm Password"
          id="passwordAgain"
          type="password"
          :required="true"
        ></ui-form-input>
        <div class="text-center">
          <ui-button @click.stop="send()" color="primary" type="submit">Continue</ui-button>
        </div>
      </ui-form>
    </div>
    <div v-if="sent" class="px-8">
      <p class="mt-2 text-sm text-center text-gray-700 dark:text-gray-400 max-w">Your account <strong>{{ this.email }}</strong> has been activated, please login with your new password.</p>
      <div class="mt-8 text-center">
        <ui-button @click="this.$router.push({ name: 'Login' });" color="primary">Continue to login</ui-button>
      </div>
    </div>
  </unauthorized-layout>
</template>

<script>
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiButton from '@/components/ui/uiButton.vue';
import UnauthorizedLayout from '@/components/layouts/UnauthorizedLayout.vue';
import { mapActions } from 'vuex';
import jwt_decode from 'jwt-decode';

export default {
  name: 'RegistrationConfirm',

  components: {
    uiForm,
    uiFormInput,
    uiButton,
    UnauthorizedLayout
  },

  props: {
    token: {
      type: String,
      default: null
    }
  },

  data: () => ({
    password: null,
    passwordAgain: null,
    email: null,
    sent: false,
    showError: false,
    tokenError: false,
    loading: false,
    headerText: 'Confirm Your Account',
    errorHeaderText: ''
  }),

  created: function() {
    try {
      this.email = jwt_decode(this.token).sub;
    } catch (e) {
      this.showError = true;
      this.tokenError = true;
      this.errorHeaderText = 'Registration token is invalid or expired. Please try to register again.';
    }
  },

  methods: {
    ...mapActions('account', ['confirmUserPassword']),

    validateForm: function() {
      this.showError = false;

      if (this.password != this.passwordAgain) {
        this.showError = true;
        this.errorHeaderText = 'Passwords do not match!';
      } else if (!this.password || this.password.length <= 0) {
        this.showError = true;
        this.errorHeaderText = 'Password can`t be empty.';
      }

      return !this.showError;
    },

    send: function() {
      if (this.validateForm()) {
        this.showError = false;
        this.loading = true;
        this.confirmUserPassword({ password: this.password, token: this.token }).then(() => {
          this.sent = true;
          this.loading = false;
          this.headerText = 'Your account is ready.';
          this.$router.push({ name: 'RegistrationConfirm' });

        }).catch((error) => {

          this.showError = true;
          this.loading = false;

          if (error.response.status == 401
            && error.response.data.code == 'token_not_valid') {
            this.tokenError = true;
            this.errorHeaderText = 'Registration token is invalid or expired. Please try to register again.';

          } else if (error.response.status == 400
            && error.response.data.password?.length>0) {
            this.errorHeaderText = 'Invalid password. ' + error.response.data.password;

          } else {
            this.errorHeaderText = 'Uknown error: ' + JSON.stringify(error.response.data);
          }
        });
      }
    }
  }
}
</script>
