<template>

  <div v-if="loading" class="mt-8">
    <ui-loader class="h-12" />
  </div>

  <template v-else>

    <!-- Klipper files list -->
    <div class="mx-2 mb-2">
      <div class="my-1 flex justify-end">
        <ui-button
          @click.stop="loadFiles()"
          icon="refresh"
          data-title="Refresh"
          small
        />
      </div>
      <div class="border border-gray-200 dark:border-gray-700">
        <ui-grid>
          <ui-grid-item v-for="file, i in filesList" :key="i" @click.stop.prevent="showPrintDialog(file)">
            <ui-grid-item-col>
              <ui-grid-item-col-content type="text" class="break-all pr-2">
                {{ file.path }}
              </ui-grid-item-col-content>
            </ui-grid-item-col>
            <ui-grid-item-col type="right">
              <ui-grid-item-col>
                <ui-grid-item-col-content type="text">
                  {{ $filters.humanFileSize(file.size) }}
                </ui-grid-item-col-content>
              </ui-grid-item-col>
            </ui-grid-item-col>
          </ui-grid-item>
        </ui-grid>
      </div>
    </div>

  </template>

  <ui-confirm-dialog
    ref="startPrintConfirmDialog"
    :headerText="'Start job'"
    :confirmBtnText="$t('components.printDialog.startPrint')"
    :dismissBtnText="$t('components.modal.cancel')"
    :autoCloseOnConfirm="false"
    @onConfirm="startPrint(selectedFile.path)"
    :loading="isPrintDialogLoading"
    :disableConfirmButton="printer?.printerState != 'READY_TO_PRINT' || !this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
  >
    <div>Do you want to start?</div>
    <div class="mt-2 font-bold break-all">{{ selectedFile?.path.split('/').reverse()[0] }}</div>
    <div class="mt-4 text-xs">
      <div class="break-all">Path: /{{ selectedFile.path }}</div>
      <div>Size: {{ $filters.humanFileSize(selectedFile.size) }}</div>
    </div>
  </ui-confirm-dialog>

</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';

export default {
  name: 'PrinterLocalFiles',

  components: {
    uiButton,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiLoader,
    uiConfirmDialog
  },

  emits: ['printStarted'],

  props: {
    printerId: {
      type: String,
      default: null,
      required: true
    }
  },

  data: () => ({
    printer: null,
    filesList: [],
    loading: true,
    filepathToPrint: null,
    isPrintDialogLoading: false,
    selectedFile: null
  }),

  created: async function() {
    this.loading = true;
    this.printer = this.$store.getters['printers/printerById'](this.printerId);
    await this.loadFiles();
    this.loading = false;
  },

  methods: {
    async loadFiles() {
      this.loading = true;
      await this.$store.dispatch('printers/loadPrinterLocalFiles', { printerId: this.printerId });
      if (this.printer.printerLocalFiles) {
        this.filesList = structuredClone(this.printer.printerLocalFiles);
        this.filesList.sort((a, b) => {
          if (!a.path.includes('/') && b.path.includes('/')) {
              return -1; // a comes first
          }
          if (a.path < b.path) {
              return -1; // a comes first
          }
          if (a.path > b.path) {
              return 1; // b comes first
          }
          return 0; // a and b are equal
        });
      }
      this.loading = false;
    },

    async showPrintDialog(file) {
      this.selectedFile = file;
      await this.$refs.startPrintConfirmDialog.showDialog();
    },

    async startPrint(path) {
      this.isPrintDialogLoading = true;
      this.$store.commit('printers/setPrinterOnStateChanging', { printerId: this.printerId, state: true });
      await this.$store.dispatch('printers/startPrinterLocalFile', { printerId: this.printerId, path: path });
      this.isPrintDialogLoading = false;
      await this.$refs.startPrintConfirmDialog.close();
      this.$emit('printStarted');
    }
  }
}
</script>
