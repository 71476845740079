import httpClient from '@/httpClient';
import { throwNetworkUnhandledError } from '@/errors';


export function loadGcodesNext({ commit }, payload) {
  return httpClient.get(payload.url, { authorizeRequest: true }).then(function (response) {
    // see comment below to explain what vuexStoreMethodName is good for
    commit(payload.vuexStoreMethodName ? payload.vuexStoreMethodName : 'setGcodes', { data: response?.data, append: true });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadGcodes({ commit, rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/files/';

    let labelsFilter = null;
    if (payload.labelsFilterIds?.length > 0) {
      labelsFilter = '';
      payload.labelsFilterIds.forEach((id, index) => {
        labelsFilter += (index == 0) ? '?' : '&';
        if (id == 'UNCATEGORIZED') { // specail ID used to indicate filter of files without any label
          labelsFilter += 'noLabels=true';
        } else {
          labelsFilter += 'label=' + id;
        }
      });

      url = url + labelsFilter;
    }

    if (payload.searchFilter) {
      url = labelsFilter ? url = url + '&' : url = url + '?';
      url = url + 'search=' + payload.searchFilter;
    }

    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      // vuexStoreMethodName indicates different store for data, as this whole function loadGcodes is shared
      // with loading gcodes into files list and with print dialog files list too
      commit(payload.vuexStoreMethodName ? payload.vuexStoreMethodName : 'setGcodes', { data: response?.data, append: false });
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function loadLastPrintedGcodes({ commit, rootGetters }) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/last-printed-files/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      // "last-printed-files" API endpoint is not returning paginated response, so to keep response data
      // compatible with "setPrintDialogGcodes" commit function, I have to create this "fake" paginated data object
      let data = {
        results: response?.data || [],
        count: response?.data?.length,
        next: null,
        previous: null
      }
      commit('setPrintDialogGcodes', { data: data, append: false });
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function loadGcodeDetail({ commit, rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/files/' + payload.id + '/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setGcodeDetail', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function deleteGcode({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/files/' + payload.id + '/';
    return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function updateGcode({ commit, rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  let obj = {
    name: payload.name,
    note: payload.note,
    filament_type: payload.filament_type,
    filament_color: payload.filament_color == 'NULL'? '' : payload.filament_color,
    fileLabels: payload.labelIDs
  }
  if (groupId) {
    let url = '/groups/' + groupId + '/files/' + payload.id + '/';
    return httpClient.patch(url, obj, { authorizeRequest: true }).then((response) => {
      commit('setGcodeDetail', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function updateGcodeIsPublic({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/files/' + payload.id + '/public/';
    return httpClient({
       method: payload.isPublic ? 'put' : 'delete',
       url: url,
       authorizeRequest: true,
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function uploadGcode(commit, payload) {
  let url = `/groups/${payload.groupId}/files/`;

  let formData = new FormData();
  formData.append('file', payload.file);
  formData.append('name', payload.name);
  formData.append('note', payload.note)

  return httpClient.post(url, formData, { authorizeRequest: true, onUploadProgress: payload.onUploadProgressCallback }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadFileLabels({ commit, rootGetters }) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/file-labels/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setFileLabels', response?.data);
    });
  }
}

export function deleteFileLabel({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/file-labels/' + payload.id + '/';
    return httpClient.delete(url, { authorizeRequest: true });
  }
}

export function addFileLabel({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/file-labels/';
    let obj = {
      name: payload.name,
      parent_id: payload.parentId,
    };
    return httpClient.post(url, obj, { authorizeRequest: true });
  }
}

export function updateFileLabel({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/file-labels/' + payload.id + '/';
    let obj = {
      name: payload.name,
      parent_id: payload.parentId,
    };
    return httpClient.patch(url, obj, { authorizeRequest: true });
  }
}

export function loadVideos({ commit, rootGetters }) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/videos/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setVideos', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function loadVideoDetail({ commit, rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/videos/' + payload.id + '/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setVideoDetail', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function updateVideoIsPublic({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/videos/' + payload.id + '/';
    let obj = {
      is_public: payload.isPublic
    };
    return httpClient.patch(url, obj, { authorizeRequest: true });
  }
}

export function deleteVideo({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/videos/' + payload.id + '/';
    return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function loadPrintJobsByFileId({ commit, rootGetters }, payload) {
  let url = '/groups/' + rootGetters['account/currentGroupIdOrLogout'] + '/print-jobs/?file_id=' + payload.fileId;
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setPrintJobs', response?.data);
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadPrintJobs({ commit, rootGetters }) {
  let url = '/groups/' + rootGetters['account/currentGroupIdOrLogout'] + '/print-jobs/';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setPrintJobs', response?.data);
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadVideosByFileId({ commit }, payload) {
  if (payload.id) {
    let url = '/files/' + payload.id + '/videos/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setVideos', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function downloadVideoPhotoStripeAsObjectURL(commit, payload) {
  let url = '/print-job-videos/download/' + payload.videoId + '/photo-stripe/?download=1';
  return httpClient.get(url, { authorizeRequest: true, responseType: 'blob' }).then((res) => {
    if (res?.data) {
      return URL.createObjectURL(res.data);
    }
    return;
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function downloadGcodePreviewImageAsObjectURL(commit, payload) {
  let url = '/files/' + payload.gcodeId + '/preview-images/' + payload.previewImageId + '/';
  return httpClient.get(url, { authorizeRequest: true, responseType: 'blob' }).then((res) => {
    if (res?.data) {
      return URL.createObjectURL(res.data);
    }
    return;
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}
