<template>
  <ui-confirm-dialog
    ref="confirmDeleteDialog"
    :headerText="$t('components.fileList.modalDeleteTitle')"
    :confirmBtnText="$t('components.fileList.modalDeleteConfirmCta')"
    confirmBtnType="danger"
    :dismissBtnText="$t('components.modal.cancel')"
  >
    <p v-if="gcodesToDelete.length > 1">
      {{ $t('components.fileList.modalDeleteConfirmMessageMany') }}
    </p>
    <template v-if="gcodesToDelete.length == 1">
      <p>{{ $t('components.fileList.modalDeleteConfirmMessage') }}</p>
      <p class="px-2 py-4 my-2 bg-gray-100 dark:bg-gray-900 break-all font-bold font-mono">{{ gcodesToDelete[0].name }}</p>
      <p>{{ $t('components.fileList.modalDeleteWarning') }}</p>
    </template>
  </ui-confirm-dialog>
</template>

<script>
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';

export default {
  name: 'DeleteFilesConfirmDialog',

  components: {
    uiConfirmDialog
  },

  emits: [
    'canceled',
    'deleting',
    'finished'
  ],

  props: {
    gcodesToDelete: {
      type: Array
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.$refs.confirmDeleteDialog.showDialog().then((result) => {
          if (result) {
            this.$emit('deleting');
            Promise.all(
              this.gcodesToDelete.map(item => this.$store.dispatch('gcodes/deleteGcode', {id: item.id}))
            ).then(() => {
              this.$emit('finished');
            });
          } else {
            this.$emit('canceled');
          }
        });
      }
    }
  }
}
</script>
