<template>
  <router-link :to="{ name: 'PrinterDetail', params: { id: printer ? printer?.id : 'still-loading-printer-id' }}">

    <div
      class="relative flex flex-col shadow-sm h-48 border border-1
        border-gray-200 bg-white-100 text-gray-700 dark:text-white-100
        dark:border-gray-600 dark:bg-gray-700 hover:bg-gray-150 dark:hover:bg-gray-750"
      :class="{'h-96': showCamera}"
    >

      <div v-if="showSelectableLayer && canBeSelected" class="absolute border border-4 border-green-400 top-0 bottom-0 left-0 right-0">
        <div class="absolute top-0 bottom-0 left-0 right-0 bg-gray-200 dark:bg-gray-600 opacity-80"></div>
        <div class="absolute top-0 right-0">
          <input
            type="checkbox"
            :checked="isSelected"
            class="
              w-7 h-7 mr-6 mt-6
              cursor-pointer appearance-none
              rounded-md
              border border-2 border-green-500
              bg-white-100
              bg-no-repeat bg-center
              checked:bg-green-500
              checked:bg-no-repeat checked:bg-center
            "
          />
        </div>
        <div class="absolute top-0 bottom-0 left-0 right-0" @click.stop.prevent="onSelectableLayerClick()"></div>
      </div>
      <div v-else-if="showSelectableLayer && !canBeSelected" class="absolute border border-1 border-gray-300 top-0 bottom-0 left-0 right-0" @click.stop.prevent="">
        <div class="absolute top-0 bottom-0 left-0 right-0 bg-gray-200 dark:bg-gray-600 opacity-80">
          <div class="h-full w-full grid place-content-center p-6">
            <div class="text-center text-sm font-bold italic">{{ $t('components.printer.actionNotAvailableTitle') }}</div>
            <div class="grid place-content-center"><ui-button class="inline" icon="question-mark-circle" color="clear" small @click="this.$store.commit('app/setNotification', ({title: $t('components.printer.actionNotAvailableTitle'), text: $t('components.printer.actionNotAvailableText')}));" /></div>
          </div>
        </div>
      </div>

      <div v-if="showCamera && printer" class="w-full h-48 overflow-hidden content-center pb-4">
        <printer-camera
          ref="camera"
          :id="printer?.id"
          :showFullscreenIcon="false"
          :showLightIcon="false"
        ></printer-camera>
      </div>

      <div class="flex p-2">
        <div class="grow">
          <printer-state v-if="printer" :printer="printer" />
        </div>
        <filament-type v-if="!showSelectableLayer" :name="printer?.filamentType" @typeChange="onFilamentTypeChange" allowEmptyTypeValue :hasPermission="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)" />
      </div>

      <div class="text-base font-bold px-2 truncate">{{ printer?.name }}</div>

      <div class="w-full grow px-2">
        <div v-if="printer?.isDevicePairedWithPrinter && (printer?.printerState == 'PRINTING' || printer?.currentJob?.completion > 0)" class="w-full bg-gray-200 dark:bg-gray-600 font-medium text-xs text-white-100 leading-none text-center">
          <div class="text-center"
            :style="'width: ' + ((!printer?.currentJob?.completion || printer?.currentJob?.completion?.toFixed(0)) <= 2 ? 2 : printer?.currentJob?.completion?.toFixed(0)) + '%'"
          >
            <div class="bg-green-400 px-1">{{ printer?.currentJob?.completion > 0 ? printer?.currentJob?.completion?.toFixed(0) : 0 }}%</div>
          </div>
        </div>
        <div v-if="printer?.printerState == 'PRINTING'">
          <div v-if="printer?.currentJob?.name" class="text-nowrap truncate text-xs font-normal mt-1">
            {{ printer?.currentJob?.name }}
          </div>
          <div v-if="printer?.currentJob?.username" class="text-xs font-normal flex space-x-1">
            <ui-icon name="user" class="w-3" />
            <div class="text-nowrap truncate">{{ printer?.currentJob?.username }}</div>
          </div>
        </div>
      </div>

      <div v-if="printer?.isDevicePairedWithPrinter" class="font-medium text-xs h-9 px-2">
        <div class="" v-if="printer?.isDevicePairedWithPrinter">
          <div class="flex grow gap-x-1"><ui-icon name="nozzle" class="w-4" /><strong>{{ printer?.temperatures?.tool0?.actual?.toFixed(1) }} / {{ printer?.temperatures?.tool0?.target?.toFixed(1) }} °C</strong></div>
          <div class="flex grow gap-x-1"><ui-icon name="bed" class="w-4" /><strong>{{ printer?.temperatures?.bed?.actual?.toFixed(1) }} / {{ printer?.temperatures?.bed?.target?.toFixed(1) }} °C</strong></div>
        </div>
      </div>

      <div class="flex justify-end space-x-1 px-2 pb-2">
        <template v-if="printer?.IS_CHANGING_STATE">
          <ui-button small color="danger" loading></ui-button>
        </template>

        <!-- Printing - Pause or Stop -->
        <template v-else-if="printer?.printerState == 'PRINTING'">
          <ui-button small color="danger" icon="pause" @click.stop.prevent="goToDetailControlsTab()">Pause</ui-button>
          <ui-button small icon="stop" @click.stop.prevent="goToDetailControlsTab()">Cancel</ui-button>
        </template>

        <!-- Connect or Turn On -->
        <template v-else-if="
            printer?.isDeviceConnectedToInternet
            && !printer?.isDevicePairedWithPrinter
            && printer?.printerState == 'PRINTER_NOT_PAIRED'
            && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)
          "
        >
          <ui-button v-if="printer?.hasPowerRelay" small color="danger" icon="bolt" @click.stop.prevent="switchPowerOn();">{{ $t('components.printer.actionPowerOn') }}</ui-button>
          <ui-button v-else small color="danger" icon="bolt" @click.stop.prevent="connectPrinterToOctoprint();">{{ $t('views.printerDetail.btnPrinterNotSynced') }}</ui-button>
        </template>

        <!-- Ready to print -->
        <template v-else-if="printer?.printerState == 'READY_TO_PRINT' && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)">
          <ui-button small icon="refresh" @click.stop.prevent="rePrintLastPrintJob();">{{ $t('components.printer.actionRePrint') }}</ui-button>
          <ui-button small color="primary" icon="play" @click.stop.prevent="this.$printDialogService.showPrintDialog({ printerId: printer?.id });">{{ $t('components.printer.actionPrint') }}</ui-button>
        </template>

        <!-- Context Menu -->
        <ui-button
          @click.stop.prevent="showItemContextMenu(true)"
          icon="dots-vertical"
          data-title="File list item context menu"
          small
          :disabled="printer?.IS_CHANGING_STATE"
        ></ui-button>
        <div class="absolute bottom-12 right-2">
          <ui-context-menu
            :mobileTitle="printer?.name"
            :show="isContextMenuOpened"
            @close="showItemContextMenu(false)"
            data-title="Printer context menu"
          >
            <ui-context-menu-item @click.stop.prevent="goToPrinterDetail(); showItemContextMenu(false);" icon="search">{{ $t('components.printer.actionDetail') }}</ui-context-menu-item>

            <ui-context-menu-item
              v-if="printer?.printerState == 'READY_TO_PRINT' && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
              @click.stop.prevent="this.$printDialogService.showPrintDialog({ printerId: printer?.id }); showItemContextMenu(false);"
              icon="play">{{ $t('components.printer.actionPrint') }}</ui-context-menu-item>

            <ui-context-menu-item
              v-if="printer?.isDevicePairedWithPrinter"
              @click.stop.prevent="emergencyStop(); showItemContextMenu(false);"
              icon="exclamation"
              divider
              class="uppercase">{{ $t('components.printer.actionEmergencyStop') }}</ui-context-menu-item>

          </ui-context-menu>
        </div>

      </div>
    </div>

  </router-link>

</template>

<script>
import PrinterState from '@/components/PrinterState.vue';
import PrinterCamera from '@/components/PrinterCamera.vue';
import FilamentType from '@/components/FilamentType.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiContextMenu from '@/components/ui/uiContextMenu.vue';
import uiContextMenuItem from '@/components/ui/uiContextMenuItem.vue';

export default {
  name: 'PrinterInList',

  components: {
    PrinterState,
    PrinterCamera,
    FilamentType,
    uiButton,
    uiIcon,
    uiContextMenu,
    uiContextMenuItem
  },

  emits: [
    'selectedClick'
  ],

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    showCamera: {
      type: Boolean,
      default: false
    },
    showSelectableLayer: {
      type: Boolean,
      default: false
    },
    canBeSelected: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    async showCamera(val) {
      if (val && this.printer?.id) {
        this.$nextTick(() => {
          this.$refs.camera.initPolling(this.printer.id);
        });
      }
    }
  },

  data: () => ({
    printer: null,
    isContextMenuOpened: false
  }),

  mounted: function() {
    this.printer = this.$store.getters['printers/printerById'](this.id);
  },

  methods: {
    onFilamentTypeChange(value) {
      this.$store.dispatch('printers/updatePrinter', { id: this.printer.id, filamentType: value || '' });
    },

    goToDetailControlsTab() {
      this.$router.push({ name: 'PrinterDetail', params: { id: this.printer?.id }, query: { activeTab: 'controls' } });
    },

    goToDetailWithAction(action) {
      this.$router.push({ name: 'PrinterDetail', params: { id: this.printer?.id }, query: { action: action } });
    },

    connectPrinterToOctoprint() {
      this.$printersService.controls.octoprintConnect(this.printer.id, this.printer.name);
    },

    switchPowerOn() {
      this.$printersService.controls.switchPowerOn(this.printer.id, this.printer.name);
    },

    goToPrinterDetail() {
      this.$router.push({ name: 'PrinterDetail', params: { id: this.printer.id } });
    },

    async emergencyStop() {
      this.$printersService.controls.emergencyStop(this.printer.id, this.printer.name);
    },

    async rePrintLastPrintJob() {
      await this.$store.dispatch('printers/loadPrintHistory', { printerId: this.id });
      let history = this.$store.getters['printers/printerById'](this.id)?.printJobs?.results;
      if (history && history.length > 0 && history[0].file_exists) {
        this.$printDialogService.showPrintDialog({ printerId: this.id, gcodeId: history[0].file_id });
      } else {
        this.$store.commit('app/setNotification', ({
          title: 'Gcode deleted',
          text: 'Sorry, previous g-code was deleted and can`t be printed again.'
        }));
      }
    },

    showItemContextMenu(show) {
      this.isContextMenuOpened = show;
    },

    onSelectableLayerClick() {
      this.$emit('selectedClick');
    }
  },

  unmounted: function() {
    this.printer = null;
  }
}
</script>
