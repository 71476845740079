<template>
  <unauthorized-layout
    :headerText="$t('views.registration.header')"
    :loading="loading"
    :showError="showError"
    :errorHeaderText="errorHeaderText"
  >
    <p v-if="!sent" class="mt-2 mb-8 px-2 md:px-0 text-center text-sm text-gray-700 dark:text-gray-400 max-w">
      {{ $t('views.registration.info1') }}<br/>
      <i18n-t keypath="views.registration.info2">
        <router-link :to="{ name: 'Login' }" class="underline">{{ $t('views.passwordReset.txtHelpTextLoginHereLink') }}</router-link>
      </i18n-t>
    </p>
    <ui-form v-if="!sent" class="px-8">
      <ui-form-input
        v-model:value="email"
        :labelText="$t('views.passwordReset.inputEmailAddress')"
        id="email"
        type="email"
        :required="true"
        :messageText="$t('views.registration.privacyPolicyInfo')"
      ></ui-form-input>
      <div class="text-center">
        <ui-button @click.stop="send()" color="primary" type="submit">{{ $t('views.passwordReset.btnContinue') }}</ui-button>
      </div>
      <p class="mt-2 text-sm text-gray-700 dark:text-gray-400 max-w">{{ $t('views.registration.explanation') }}</p>
    </ui-form>
    <div v-if="sent" class="px-8">
      <p class="mt-2 text-sm text-center text-gray-700 dark:text-gray-400 max-w">
        <i18n-t keypath="views.registration.sent">
          <strong>{{ email }}</strong>
        </i18n-t>
      </p>
      <p class="mt-2 text-sm text-center text-gray-700 dark:text-gray-400 max-w">
        {{ $t('views.registration.closeWindow') }}
      </p>
      <p class="mt-2 text-sm text-center text-gray-700 dark:text-gray-400 max-w font-bold">
        {{ $t('views.registration.thanks') }}
      </p>
    </div>
  </unauthorized-layout>
</template>

<script>
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiButton from '@/components/ui/uiButton.vue';
import UnauthorizedLayout from '@/components/layouts/UnauthorizedLayout.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Registration',

  components: {
    uiForm,
    uiFormInput,
    uiButton,
    UnauthorizedLayout
  },

  data: () => ({
    email: '',
    sent: false,
    showError: false,
    loading: false,
    errorHeaderText: ''
  }),

  methods: {
    ...mapActions('account', ['sendInvitation']),

    send: function() {
      this.showError = false;
      this.loading = true;
      this.sendInvitation({email: this.email}).then(() => {
        this.sent = true;
        this.loading = false;
        this.headerText = 'Thank you.';
      }).catch((error) => {
        if (error.response.status == 400
          && error.response.data.email[0]) {

          this.showError = true;
          this.loading = false;

          if (error.response.data.email[0] == 'Enter a valid email address.'
            || error.response.data.email[0] == 'This field may not be blank.') {
            this.errorHeaderText = 'Enter a valid email address.';
          } else {
            this.errorHeaderText = 'Uknown error: ' + JSON.stringify(error.response.data);
          }
        }
      });
    }
  }
}
</script>
