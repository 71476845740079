export function getError (state) {
  return state.error;
}

export function getNotification (state) {
  return state.notification;
}


export function getAnnouncement (state) {
  return state.announcement;
}

export function getTheme (state) {
  if (state.theme) {
    return state.theme;
  } else if (localStorage.getItem('theme')) {
    return localStorage.getItem('theme');
  }
}

export function getOpenedUiModals (state) {
  return state.openedUiModals;
}

export function filesListViewTypes (state) {
  return state.filesListViewTypes;
}

export function filamentTypes (state) {
  return state.filamentTypes;
}
