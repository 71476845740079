<template>
  <div :class="color" class="rounded-sm p-4">
    <div
      class="flex"
      :class="{'justify-center': center}"
    >
      <div v-if="icon" class="flex-shrink-0">
        <ui-icon :name="icon" class="h-6 w-6" />
      </div>
      <div :class="{'ml-3': icon}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.default {
  @apply bg-gray-100 dark:bg-gray-900;
}
.primary {
  @apply bg-white-100 dark:bg-gray-800;
}
.success {
  @apply bg-green-400 text-white-100;
}
.warning {
  @apply bg-yellow-400 text-white-100;
}
.danger {
  @apply bg-red-400 text-white-100;
}
.dark {
  @apply bg-black-900 bg-opacity-25 text-white-100;
}
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiAlert',

  components: {
    uiIcon,
  },

  props: {
    icon: {
      type: String,
      default: undefined,
    },
    center: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return (
          [
            'default',
            'primary',
            'success',
            'warning',
            'danger',
            'dark',
          ].indexOf(value) !== -1
        );
      },
    },
  },
};
</script>
