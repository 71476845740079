<template>

  <authorized-layout>
    <ui-heading>
      {{ $t('views.printQueue.title') }}
    </ui-heading>

    <div class="space-y-12">
      <ui-print-queue-state-box
        :state="states.TO_BE_PRINTED"
        :items="computed_toBePrintedItems"
        :loading="loading"
        @edit="onItemClicked"
        @duplicate="duplicateItem"
        @print="openPrintDialog"
        @remove="showRemoveItemDialog"
        showDuplicateBtn
        showPrintBtn
        showEditBtn
      >
      </ui-print-queue-state-box>

      <ui-print-queue-state-box
        :state="{id: 'printing', title: $t('views.printQueue.statePrintingTitle'), desc: $t('views.printQueue.statePrintingDescription')}"
        :items="computed_printingItems"
        :loading="loading"
        @duplicate="duplicateItem"
        @remove="showRemoveItemDialog"
        @showPrinterDetail="goToPrinterDetail"
        showPrintAgainBtn
        showShowPrinterBtn
      >
      </ui-print-queue-state-box>

      <ui-print-queue-state-box
        :state="states.PRINTED"
        :items="computed_printedItems"
        :loading="loading"
        @duplicate="duplicateItem"
        @archivate="archivateItem"
        @remove="showRemoveItemDialog"
        showPrintAgainBtn
        showArchivateBtn
      >
      </ui-print-queue-state-box>

      <ui-print-queue-state-box
        :state="states.DONE"
        :items="computed_doneItems"
        :loading="loading"
        showPrintAgainBtn
        :showDetails="false"
        @duplicate="duplicateItem"
        @remove="showRemoveItemDialog"
      >
      </ui-print-queue-state-box>
    </div>

  </authorized-layout>

  <print-queue-item-dialog
    :show="showPrintqueueItemDialog"
    @canceled="itemDialogCanceled()"
    @saved="itemDialogSaved()"
    :printQueueItem="selectedItem"
    :gcodeId="gcodeIdToDuplicate"
    :preselectedPrinterId="printerIdToDuplicate"
    :showAfterSaveStatus="false" />

  <ui-confirm-dialog
    ref="confirmRemoveDialog"
    :headerText="$t('views.printQueue.removeDialogTitle')"
    :confirmBtnText="$t('components.modal.yesContinue')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>{{ $t('views.printQueue.removeDialogText') }}</p>
  </ui-confirm-dialog>

</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import PrintQueueItemDialog from '@/components/PrintQueueItemDialog.vue';
import uiPrintQueueStateBox from '@/components/ui/uiPrintQueueStateBox.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';

export default {
  name: 'PrintQueue',

  components: {
    AuthorizedLayout,
    uiHeading,
    PrintQueueItemDialog,
    uiPrintQueueStateBox,
    uiConfirmDialog
  },

  computed: {
    computed_toBePrintedItems () {
      return this.printQueueItems.filter((item) => item.state == this.states.TO_BE_PRINTED.id);
    },
    computed_printingItems () {
      return this.printQueueItems.filter((item) => {
        if (item.state == this.states.PRINTED.id) {
          if (['RECORDING', 'ANALYZING', null].indexOf(item.print_job?.video_state) >= 0) {
            return true;
          }
        }
      });
    },
    computed_printedItems () {
      return this.printQueueItems.filter((item) => {
        if (item.state == this.states.PRINTED.id) {
          if (['RECORDING', 'ANALYZING', null].indexOf(item.print_job?.video_state) == -1) {
            return true;
          }
        }
      });
    },
    computed_doneItems () {
      return this.printQueueItems.filter((item) => item.state == this.states.DONE.id).reverse();
    },
  },

  data: () => ({
    printQueueItems: [],
    showPrintqueueItemDialog: false,
    selectedItem: null,
    gcodeIdToDuplicate: null,
    printerIdToDuplicate: null,
    states: [],
    loading: false
  }),

  created: function () {
    this.printQueueItems = [];
    this.states = this.$store.getters['printQueue/printQueueStates'];
    this.reloadItems();

    // reload automatically items when user go back to browser tab to keep data up-to-date
    document.addEventListener('visibilitychange', () => {
      if (document.body.contains(this.$el)) {
        if (document.visibilityState === 'visible') {
          this.reloadItems();
        }
      }
    });
  },

  methods: {
    async reloadItems() {
      if (! this.loading) {
        this.loading = true;
        await this.$store.dispatch('printQueue/loadPrintQueue');
        this.printQueueItems = structuredClone(this.$store.getters['printQueue/printQueue']);
        this.loading = false;
      }
    },

    async setItemState(item, state) {
      this.loading = true;
      await this.$store.dispatch('printQueue/patchItem', {id: item.id, state: state});
      this.loading = false;
      await this.reloadItems();
    },

    /**
     * In archive should be only items with unique files. When archiving item, it will be moved to archive
     * only in case, that it's not there already. If it's there, this item to archive will be just deleted. */
    archivateItem(item) {
      let itemsWithSameFileInArchive = this.printQueueItems.filter((i) => {
        if (i.state == this.states.DONE.id) {
          if (i.file.id == item.file.id) {
            return true;
          }
        }
      });
      if (itemsWithSameFileInArchive.length == 0) {
        this.setItemState(item, this.states.DONE.id);
      } else {
        this.deleteItem(item);
      }
    },

    duplicateItem(item) {
      this.gcodeIdToDuplicate = item.file.id;
      this.printerIdToDuplicate = item.printer?.id;
      this.selectedItem = null;
      this.showItemDialog();
    },

    onItemClicked(item) {
      this.selectedItem = item;
      this.showItemDialog();
    },

    showRemoveItemDialog(item) {
      this.$refs.confirmRemoveDialog.showDialog().then((result) => {
        if (result) {
          this.deleteItem(item);
        }
      });
    },

    async deleteItem(item) {
      this.loading = true;
      await this.$store.dispatch('printQueue/deleteItem', item.id);
      this.loading = false;
      this.reloadItems();
    },

    showItemDialog() {
      this.showPrintqueueItemDialog = true;
    },

    closeItemDialog() {
      this.showPrintqueueItemDialog = false;
    },

    itemDialogCanceled() {
      this.closeItemDialog();
    },

    itemDialogSaved() {
      this.closeItemDialog();
      this.reloadItems();
    },

    openPrintDialog(item) {
      this.$printDialogService.showPrintDialog({ gcodeId: item?.file?.id, printerId: item.printer?.id, singlePrinterMode: true });
      this.$printDialogService.setOnDialogEventCallback((e, data) => {
        if (e == 'started') {
          this.printJobStartedManually(item, data);
        }
      });
    },

    goToPrinterDetail(item) {
      if (item?.printer?.id) {
        this.$router.push({ name: 'PrinterDetail', params: { id: item.printer.id } });
      }
    },

    async printJobStartedManually(item, data) {
      this.loading = true;
      // assign printjob to print queue item
      await this.$store.dispatch('printQueue/patchItem', {id: item.id, printJob: data.printjobId, printerId: data.printerId});      
      this.setItemState(item, this.states.PRINTED.id);
    }
  }
}
</script>
