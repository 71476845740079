class Printer {
  DATETIME = Date.now(); // date, when class was updated

  id = null; // printer ID

  // Base data
  enabled = false; // is printer enabled
  name = null; // printer name
  note = null; // printer description
  cameraEnabled = false; // is camera enabled
  cameraRotation = null; // camera rotation
  joboxEnabled = false; // is Jobox enabled
  instances = null; // list of printer instances with similar printer device key
  filamentType = null; // filament type currently in printer (PLA, PETG...)
  deviceType = null; // octoprint or moonraker?

  // Extended data
  isLightOn = false; // is karmen light on
  klipperMacros = null; // list of klipper macros
  klipperTerminalHistory = null; // history/list of gcode commands from terminal
  klipperPrinterInfo = null; // info about klipper printer
  printerLocalFiles = null; // list of local files on printer

  // Printer state data
  isDeviceConnectedToInternet = false; // is device online?
  isDevicePairedWithPrinter = false; // is printer paired with octoprint?
  printerState = 'STATE_UNKNOWN'; // current printer state (printing, ready...)
  temperatures = null; // current printer temperatures
  hasKarmenLight = false; // is karmen light available
  hasSpectodaLight = false; // is spectoda lights available (PWS printers)
  hasPowerRelay = false; // PWS printers has power relay to turn on/off

  // Device is busy by downloading
  isDeviceDownloading = false; // indicates, that device is downloading print file
  downloadProgress = -1; // progress in percents, how much is downloaded; -1 means we don't have data/not downloading

  // indicating whether printer is in process of change state
  // this value is calculated and is invoked by user's change state actions (turn-on, connect...)
  IS_CHANGING_STATE = false;
  CHANGING_STATED_STARTED_ON = Date.now();

  // Printjob data
  currentJob = null; // current printjob

  // printjobs related to this printer
  printJobs = {
    results: [],
    count: 0,
    next: null
  };

  constructor(id) {
    this.id = id;
    this.onUpdated();
  }

  // set base values of the printer
  setBaseData(enabled, name, note, cameraEnabled, cameraRotation,
    joboxEnabled, instances, filamentType, deviceType
  ) {
    this.enabled = enabled;
    this.name = name;
    this.note = note;
    this.cameraEnabled = cameraEnabled;
    this.cameraRotation = cameraRotation;
    this.joboxEnabled = joboxEnabled;
    this.instances = instances;
    this.filamentType = filamentType;
    this.deviceType = deviceType;

    this.onUpdated();
  }

  setStateData(isDeviceConnectedToInternet, isDevicePairedWithPrinter, printerState, temperatures,
    hasKarmenLight, hasSpectodaLight, hasPowerRelay, isLightOn
  ) {

    // when printerState has changed since last or last change was >15 seconds, then IS_CHANGING_STATE should be false,
    // because the changing action should be finished already or there was some action problem
    if (this.printerState != printerState || (Date.now() - this.CHANGING_STATED_STARTED_ON) > 15000) {
      this.IS_CHANGING_STATE = false;
    }

    this.isDeviceConnectedToInternet = isDeviceConnectedToInternet;
    this.isDevicePairedWithPrinter = isDevicePairedWithPrinter;
    this.printerState = printerState;
    this.temperatures = temperatures;
    if (hasKarmenLight != null) {
      this.hasKarmenLight = hasKarmenLight;
    }
    this.hasSpectodaLight = hasSpectodaLight;
    this.hasPowerRelay = hasPowerRelay;
    this.isLightOn = isLightOn;

    this.onUpdated();
  }

  setJobData(currentJob) {
    this.currentJob = {
      completion: parseFloat(currentJob?.completion) || null,
      name: currentJob?.name || null,
      printTime: parseInt(currentJob?.printTime),
      printTimeLeft: parseInt(currentJob?.printTimeLeft)
    };

    this.onUpdated();
  }

  setLocalIpAddressData(localIpAddress) {
    this.localIpAddress = localIpAddress;
  }

  setKlipperMacrosData(klipperMacros) {
    this.klipperMacros = klipperMacros;
  }

  setKlipperTerminalHistoryData(klipperTerminalHistory) {
    if (Array.isArray(klipperTerminalHistory)) {
      this.klipperTerminalHistory = klipperTerminalHistory.reverse();
    }
  }

  setKlipperPrinterInfoData(klipperPrinterInfo) {
    this.klipperPrinterInfo = klipperPrinterInfo;
  }

  setOnStateChangingData(state=true) {
    this.IS_CHANGING_STATE = state;
    this.CHANGING_STATED_STARTED_ON = Date.now();
  }

  setPrinterLocalFilesData(localFiles) {
    this.printerLocalFiles = localFiles;
  }

  setPrintJobsData(data, append, count, next) {
    // append indicates, that we are loading next page for same query, so data
    // will be only added to previous dataset instead of starting with new empty dataset
    if (append) {
      this.printJobs.results = this.printJobs.results.concat(data);
    } else {
      this.printJobs.results = data;
    }
    this.printJobs.count = count;
    this.printJobs.next = next;
  }

  setDownloadProgress(progress) {
    if (progress) {
      this.isDeviceDownloading = true;
      this.downloadProgress = progress;
      this.printerState = 'DOWNLOADING_PRINT_FILE';
      this.IS_CHANGING_STATE = false;
      this.onUpdated();
    } else {
      this.isDeviceDownloading = false;
      this.downloadProgress = -1;
    }
  }

  canTurnOff() {
    return this.isDevicePairedWithPrinter == true
      && this.hasPowerRelay == true
      && this.printerState == 'READY_TO_PRINT';
  }

  canTurnOn() {
    return this.hasPowerRelay == true
      && this.isDeviceConnectedToInternet == true
      && !this.isDevicePairedWithPrinter
      && this.printerState == 'PRINTER_NOT_PAIRED';
  }

  onUpdated() {
    this.DATETIME = Date.now();
  }
}

// This function will set all basic static information about printers in workspace.
export function setPrinters(state, newValues) {
  if (! Array.isArray(newValues) || ! Array.isArray(state.printers)) {
    state.printers = [];
    return;
  }

  // Find in existing printers those, that are not in received data.
  // Those printers was deleted or are printers from another workspace.
  [...state.printers].forEach((item) => {
    let exists = newValues.findIndex((e) => {
      return e.id == item.id;
    });
    if (exists == -1) {
      state.printers.splice(state.printers.findIndex((x) => x.id == item.id), 1);
    }
  })

  // Iterate through newValues and add/update printers as needed.
  newValues.forEach((x) => {

    let printer = state.printers.find((e) => e.id == x.id);

    // printer not yet exists, let's create it (probably new printer or workspace was switched)
    if (! printer) {
      printer = new Printer(x.id);
      state.printers.push(printer);
    }

    printer.setBaseData(
      x?.enabled === undefined ? true : x?.enabled,
      x.name,
      x.note,
      x.camera_enabled,
      x.camera_rotation,
      x.jobox_enabled,
      x?.instances,
      x?.filament_type,
      x?.device_type
    );
  });
}

export function setPrintersStatesData(state, val) {
  val.forEach((e) => {
    let _printer = state.printers.find((p) => p.id == e.id);

    if (_printer) {
      // printer is downloading print file
      if (e.state?.error?.code == 'download-in-progress') {
        _printer.setDownloadProgress(e.state?.error?.detail);
      // skip devices with moved-to-background error
      } else if (e.state?.error?.code != 'moved-to-background') {

        let _isDeviceConnectedToInternet = !(e.state?.error?.code == 'device-not-connected-to-proxy-server');
        let _isDevicePairedWithPrinter = false;
        let _printerState = null;
        let _temperatures = e.state?.temperature || null;
        let _hasKarmenLight = null;
        let _hasSpectodaLight = null;
        let _hasPowerRelay = null;
        let _isLightOn = e.lights == 'on' ? true : false;

        // is device paired?
        if (_isDeviceConnectedToInternet) {
          if (e.state?.state?.text == 'Offline') { // this is moonraker device - device is connected to internet, but state is offline
            _isDevicePairedWithPrinter = false;
          } else if (e.state?.state) { // if device has state, it's paired
            _isDevicePairedWithPrinter = true;
          } else { // has no state, it's not paired
            _isDevicePairedWithPrinter = false;
          }
        }

        // parse plugins info
        if (e?.plugins && !e.plugins.error) {
          _hasKarmenLight = e.plugins.includes('awesome_karmen_led');
          _hasSpectodaLight = e.plugins.includes('spectoda');
          _hasPowerRelay = e.plugins.includes('power_relay'); // || val?.client?.octoprint?.error?.code == 'printer-turned-off';
        }

        // set printer state
        if (e.state?.error?.code == 'permission-denied') {
          _printerState = 'OCTOPRINT_PERMISSION_DENIED';
        } else if (!_isDeviceConnectedToInternet) {
          _printerState = 'NOT_CONNECTED_TO_PROXY_SERVER';
        } else if (!_isDevicePairedWithPrinter) {
          _printerState = 'PRINTER_NOT_PAIRED';
        } else if (e.state?.state?.flags?.operational) {
          if (e.state?.state?.flags?.cancelling) {
            _printerState = 'CANCELLING';
          } else if (e.state?.state?.flags?.printing) {
            _printerState = 'PRINTING';
          } else if (e.state?.state?.flags?.paused) {
            _printerState = 'PAUSED';
          } else if (e.state?.state?.flags?.ready) {
            _printerState = 'READY_TO_PRINT';
          } else {
            _printerState = 'STATE_UNKNOWN';
          }
        } else if (e.state?.state?.flags?.error) {
          _printerState = 'ERROR';
        } else {
          _printerState = 'STATE_UNKNOWN';
        }

        // update printer state on printer object
        _printer.setStateData(
          _isDeviceConnectedToInternet, _isDevicePairedWithPrinter, _printerState, _temperatures,
          _hasKarmenLight, _hasSpectodaLight, _hasPowerRelay, _isLightOn);

        // printer is not downloading print file
        if (_printer.isDeviceDownloading) {
          _printer.setDownloadProgress(null);
        }
      }

      // set printjob info
      if (e?.job && !e.job.error) {
        _printer.setJobData(e.job);
      }

      // set local ip address info
      if (e?.local_ip_address && !e.local_ip_address.error) {
        _printer.setLocalIpAddressData(e.local_ip_address);
      }
    } else {
      // TODO / FIXME: Why printer doesn't exists???
    }
  });
}

export function deletePrinter(state, printerId) {
  let printerIndex = state.printers.findIndex((item) => {
    return item.id == printerId;
  });
  if (printerIndex >= 0) {
    state.printers.splice(printerIndex, 1);
  }
}

export function setPrinterBaseData(state, val) {
  let printer = state.printers.find((e) => e.id == val.id);
  printer.setBaseData(
    val?.enabled === undefined ? true : val?.enabled,
    val.name,
    val.note,
    val.camera_enabled,
    val.camera_rotation,
    val.jobox_enabled,
    val?.instances,
    val?.filament_type,
    val.device_type
  );
}

export function setPrinterKlipperMacrosData(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setKlipperMacrosData(val.data);
}

export function setPrinterKlipperTerminalHistoryData(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setKlipperTerminalHistoryData(val.data);
}

export function setPrinterKlipperPrinterInfo(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setKlipperPrinterInfoData(val.data);
}

export function setPrinterOnStateChanging(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setOnStateChangingData(val.state);
}

export function setPrinterLocalFiles(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setPrinterLocalFilesData(val.data);
}

export function setPrinterPrintJobs(state, val) {
  let printer = state.printers.find((e) => e.id == val.printerId);
  printer.setPrintJobsData(
    Array.isArray(val?.data?.results) ? val.data.results : [],
    (val.append === true),
    val?.data?.count,
    val?.data?.next
  );
}
