export class AsyncTask {
  #STATE_RUNNING = 'running';
  #STATE_DONE = 'done';
  #STATE;
  #name = 'unknown';
  #promiseTask;
  #clickCallback;

  constructor(name, promiseTask, onClickCallback) {
    this.#name = name;
    this.#STATE = this.#STATE_RUNNING;

    this.#promiseTask = promiseTask;
    this.#promiseTask.then(() => {
      this.#STATE = this.#STATE_DONE;
    });

    this.#clickCallback = onClickCallback;
  }

  get state() {
    return this.#STATE;
  }

  get name() {
    return this.#name;
  }

  get onClick() {
    return this.#clickCallback;
  }

  get promise() {
    return this.#promiseTask;
  }
}

let TASKS = [];

export default {
  install: (app) => {
    // inject a globally available $asyncTasksService() method
    app.config.globalProperties.$asyncTasksService = {

      callback_onChange: null,

      addTask: function (task) {
        if (task.promise) {
          task.promise.then(() => {
            this.notifyChange();
          });
        }
        TASKS.unshift(task);
        this.notifyChange();
      },

      notifyChange: function () {
        if (this.callback_onChange) {
          this.callback_onChange(TASKS);
        }
      },

      clearTasks: function () {
        TASKS = [];
      },

      onChangeCallback: function (callback) {
        this.callback_onChange = callback;
      }
    }
  }
}
