<template>
  <ui-modal
    ref="dialog"
    :show="show"
    data-title="PrinterGroupDialog"
    @close="cancel()"
    :title="isCreating ? $t('components.printerGroupDialog.titleCreateText') : $t('components.printerGroupDialog.titleUpdateText')"
  >
    <!-- LOADING -->
    <div v-if="loading" class="flex flex-col justify-center w-full h-60 my-8 dark:bg-gray-900">
      <ui-loader />
    </div>

    <!-- DATA LOADED -->
    <div v-else>

      <!-- CREATING/EDITING ITEM -->
      <div class="space-y-8">
        <ui-form-input
          v-model:value="name"
          :labelText="$t('components.printerGroupDialog.formInputNameText')"
          id="printer-group-name"
          :maxlength="100"
        />
      </div>

      <!-- CANCEL and SAVE buttons -->
      <div class="mt-8 space-x-2 text-center">
        <ui-button @click="cancel()" color="default">
          {{ $t('components.modal.cancel') }}
        </ui-button>
        <ui-button @click="save()" :disabled="!(name?.length > 0)" :loading="loading" color="primary">
          {{ $t('components.modal.save') }}
        </ui-button>
      </div>

    </div>

  </ui-modal>
</template>

<script>
import uiModal from '@/components/ui/uiModal.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'PrinterGroupDialog',

  components: {
    uiModal,
    uiButton,
    uiFormInput,
    uiLoader
  },

  emits: [
    'canceled',
    'saved'
  ],

  props: {
    show: {
      type: Boolean,
      default: false
    },
    printerGroupId: {
      type: String,
      default: null,
      required: false
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.init();
      }
    }
  },

  data: () => ({
    loading: false,
    isCreating: false,  // indicates, whether new item is being created or updating existing one
    name: null,
    printerGroup: null
  }),

  methods: {
    // reset values and load fresh data when dialog is opened
    async init() {
      this.loading = true;
      this.name = null;

      if (this.printerGroupId) {
        this.isCreating = false;
        this.printerGroup = this.$store.getters['printerGroups/printerGroupById'](this.printerGroupId);
        this.name = this.printerGroup.name;
      } else {
        this.isCreating = true;
      }

      this.loading = false;
    },

    // call this when data not to be saved
    cancel() {
      this.$emit('canceled');
    },

    // save button clicked
    async save() {
      if (this.name?.length > 0) {
        this.loading = true;
        if (this.isCreating) {
          await this.$store.dispatch('printerGroups/createItem', { name: this.name });
        } else {
          await this.$store.dispatch('printerGroups/patchItem', { id: this.printerGroupId, name: this.name });
        }
        await this.$store.dispatch('printerGroups/loadPrinterGroups');
        this.loading = false;
        this.$emit('saved');
      }
    }
  }
}
</script>
