<template>

  <div v-if="loading" class="mt-8">
    <ui-loader class="h-12" />
  </div>

  <!-- Klipper console & macros -->
  <div v-else class="mt-2">
    <div v-if="printer?.klipperTerminalHistory" class="m-2">
      <div class="py-2 border-b border-b-1 border-gray-200 dark:border-gray-700 text-sm font-bold flex"><ui-icon name="console" class="h-5 mr-1" />Console</div>
      <div class="border-b border-b-1 border-gray-200 dark:border-gray-700 flex">
        <div class="grow"><ui-form-input v-model:value="textInputSendCode" @keyup.enter="sendCustomCommand()" id="textInputSendCode" placeholder="Send code..." /></div>
        <div><ui-button @click="sendCustomCommand()" icon="paper-airplane" :disabled="sendingKlipperCommand" :loading="sendingKlipperCommand"></ui-button></div>
      </div>
      <div class="p-2 text-xs space-y-1 bg-gray-100 dark:bg-gray-900 font-mono text-gray-500 border border-1 border-gray-200 dark:border-gray-700 overflow-auto h-64">
        <div v-for="item, i in printer.klipperTerminalHistory" :key="i" class="flex space-x-4">
          <div>{{ $filters.simpleTimeFormat(item.time * 1000) }}</div>
          <div :class="{ 'text-red-400': item.type == 'command' }">{{ item.message }}</div>
        </div>
      </div>
    </div>
    <div v-if="printer?.klipperMacros">
      <div class="mt-4 pl-2 text-sm font-bold flex"><ui-icon name="code-bracket-square" class="h-5 mr-1" />Macros</div>
      <div v-for="item in printer?.klipperMacros?.groups" :key="item.name" class="m-2 pt-2 px-2 bg-gray-100 dark:bg-gray-900 border border-1 border-gray-200 dark:border-gray-700">
        <div class="text-sm font-bold mb-2">{{ item.name }}</div>
        <div v-for="macro in item.macros" :key="macro.name" class="inline-block mr-2 mb-2">
          <ui-button @click.stop.prevent="sendCommand(macro.name)" small :title="printer?.klipperMacros?.macros[macro.name]?.description">{{ macro.name.replaceAll('_', ' ') }}</ui-button>
        </div>
      </div>

      <div class="m-2 pt-2 px-2 bg-gray-100 dark:bg-gray-900 border border-1 border-gray-200 dark:border-gray-700">
        <div class="text-sm font-bold mb-2">All macros</div>
        <div v-for="item in macrosAll" :key="item" class="inline-block mr-2 mb-2">
          <ui-button @click.stop.prevent="sendCommand(item)" small :title="printer?.klipperMacros?.macros[item]?.description">{{ item.replaceAll('_', ' ') }}</ui-button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'PrinterKlipper',

  components: {
    uiButton,
    uiIcon,
    uiFormInput,
    uiLoader
  },

  props: {
    printerId: {
      type: String,
      default: null,
      required: true
    }
  },

  data: () => ({
    textInputSendCode: null,
    sendingKlipperCommand: false,

    printer: null,
    filesList: [],

    macrosAll: [],

    loading: true
  }),

  created: async function() {
    this.loading = true;
    this.printer = this.$store.getters['printers/printerById'](this.printerId);
    await this.$store.dispatch('printers/loadKlipperMacros', { printerId: this.printerId });
    await this.pollTerminalHistory();
    this.loading = false;

    // sort macros alphabetically
    if (this.printer.klipperMacros?.macros) {
      for (const property in this.printer.klipperMacros.macros) {
        this.macrosAll.push(property.replaceAll('_', ' '));
      }
      this.macrosAll.sort();
      this.macrosAll.forEach((value, index) => {
        this.macrosAll[index] = value.replaceAll(' ', '_');
      });
    }
  },

  methods: {
    async sendCommand(cmd) {
      if (!this.sendingKlipperCommand) {
        this.sendingKlipperCommand = true;
        try {
          await this.$store.dispatch('printers/executeControlCommand', { printerId: this.printer?.id, action: 'send_command', obj: {commands: [cmd]} });
        } catch (e) {
          if (e.response.status != 408) {
            throw e;
          } else {
            console.log(`HTTP 408 - Request timeout while sending command ${cmd}.`);
          }
        }
        setTimeout(() => {
          this.sendingKlipperCommand = false;
        }, 1000);
      }
    },

    async pollTerminalHistory() {
      if (document.body.contains(this.$el)) {
        await this.$store.dispatch('printers/loadKlipperTerminalHistory', { printerId: this.printerId });
        setTimeout(() => {
          this.pollTerminalHistory();
        }, 1000);
      }
    },

    sendCustomCommand() {
      if (this.textInputSendCode && !this.sendingKlipperCommand) {
        this.sendCommand(this.textInputSendCode);
        this.textInputSendCode = null;
      }
    }
  }
}
</script>
