<template>

  <!-- Show this info when there are no gcodes in workspace -->
  <template
    v-if="
      $store.getters['account/currentGroup']?.gcodes_count === 0
      && $store.getters['account/hasPermission'](this.$PERMISSIONS.UPLOAD_FILE)
    "
  >
    <ui-page-section-box :tight="this.$isMobile()">
      <empty-state
        icon="directory"
        :title="$t('components.emptyState.gcodesTitle')"
        :subtitle="$t('components.emptyState.gcodesSubtitle')"
        :ctaTitle="$t('components.emptyState.gcodesCtaTitle')"
        :ctaSubtitle="$t('components.emptyState.gcodesCtaSubtitle')"
      >
        <template v-slot:cta>
          <ui-button @click.stop="openUploadDialog(true)" color="primary">
            {{ $t('components.fileList.emptyCta') }}
          </ui-button>
        </template>
      </empty-state>
    </ui-page-section-box>
  </template>

  <!-- Files list -->
  <div
    v-else
    class="sm:grid"
    :class="{
      'sm:grid-cols-12 gap-4': showLabels
    }"
    @dragenter="selectableMode === true ? '' : openUploadDialog(true)"
  >

    <div v-if="showLabels && isShowedLabels" class="sm:col-span-3">
      <file-labels-list />
    </div>

    <div
      class="sm:space-y-6 space-y-2"
      :class="{
        'sm:col-span-9': showLabels
      }"
    >

      <div
        v-if="!selectableMode && !loading && $isMobile() && showLabels"
        class="flex items-center absolute left-2"
        :class="{ 'py-4 pb-24': isShowedLabels }"
      >
        <ui-button
          @click.stop="toggleShowLabels()"
          color="secondary"
          icon="bars-4"
          data-title="Show labels menu"
          small
        >{{ $t('views.gcodes.labels') }}</ui-button>
      </div>

      <!-- Refresh & upload button -->
      <div
        v-if="
          (showButtons && !loading)
          || ($isMobile() && showButtons && !loading && !isShowedLabels)
        "
        :class="{ 'sticky sm:top-24 top-20 z-30': selectableMode }"
      >
        <div class="flex justify-end sm:mr-0 mr-2">
          <div
            class="flex space-x-2"
            :class="{ 'mr-4': selectableMode }"
          >

            <!-- refresh button -->
            <ui-button
              v-if="!selectableMode"
              @click.stop="reloadFiles()"
              color="clear"
              icon="refresh"
              data-title="Refresh"
              :small="$isMobile()"
            />

            <!-- change labels gcodes button -->
            <ui-button
              v-if="selectableMode && Object.keys(selectedItems).length > 0"
              @click.stop="editLabelsForSelectedGcodes()"
              :data-title="$t('components.fileList.changeLabels')"
              color="primary"
              :small="$isMobile()"
              :class="{ 'drop-shadow-lg': selectableMode }"
            >
              {{ $t('components.fileList.changeLabels') }}
            </ui-button>

            <!-- delete gcodes button -->
            <ui-button
              v-if="selectableMode && Object.keys(selectedItems).length > 0"
              @click.stop="deleteSelectedGcodes()"
              :data-title="$t('views.settings.delete')"
              color="primary"
              :small="$isMobile()"
              :class="{ 'drop-shadow-lg': selectableMode }"
            >
              {{ $t('views.settings.delete') }}
            </ui-button>

            <!-- selectable mode button -->
            <ui-button
              @click.stop="toggleSelectableMode()"
              :data-title="$t('views.gcodes.btnSelect')"
              :small="$isMobile()"
              :class="{ 'drop-shadow-lg': selectableMode }"
            >
              <span v-if="!selectableMode">{{ $t('views.gcodes.btnSelect') }}</span>
              <span v-else>{{ $t('views.settings.cancel') }}</span>
            </ui-button>

            <!-- upload button -->
            <ui-button
              v-if="!selectableMode"
              @click.stop="openUploadDialog(true)"
              color="primary"
              :data-title="$t('views.gcodes.btnUpload')"
              :small="$isMobile()"
            >{{ $t('views.gcodes.btnUpload') }}</ui-button>
          </div>
        </div>
      </div>

      <div v-if="!isShowedLabels && !loading && showLabels" class="ml-2" @click.stop="toggleShowLabels()">
        <ui-badge color="default" class="bg-white-100 dark:bg-gray-900">
          <div class="space-x-1 pr-1 pt-0.5">
            <ui-icon name="chevron-right" class="h-3 w-3 inline-block" />
            <span v-if="$route?.query?.labels === undefined">{{ $t('views.gcodes.all') }}</span>
            <span v-else-if="$route?.query?.labels[0] === 'UNCATEGORIZED'">{{ $t('views.gcodes.noLabel') }}</span>
            <span v-else>{{ $store.getters['gcodes/fileLabelById']($route?.query?.labels)?.name }}</span>
          </div>
        </ui-badge>
      </div>

      <template v-if="enableSearch">
        <ui-page-section-box>
          <div class="flex grow">
            <div class="grow">
              <ui-form @submit="$router.replace({query: {labels: labelsFilterIds, search: searchFilter}});">
                <ui-form-input id="searchFilter" v-model:value="searchFilter" :maxlength="100" />
              </ui-form>
            </div>
            <div>
              <ui-button color="primary" @click.stop="$router.replace({query: {labels: labelsFilterIds, search: searchFilter}});" icon="search"></ui-button>
            </div>
          </div>
        </ui-page-section-box>

        <div v-if="gridItems?.count > 0 || searchFilter != '' || labelsFilterIds?.length > 0" class="text-xs text-right italic mr-2 md:mr-0">
          <span v-html="$t('components.fileList.searchFilesCount', { count: gridItems?.count > 0 ? gridItems.count : 0 })"></span>
          <span v-if="labelsFilterIds?.length > 0 && labelsFilterIds[0] != 'UNCATEGORIZED'">
            | {{ $t('components.fileList.searchLabel') }}: <strong>{{ $store.getters['gcodes/fileLabelById'](labelsFilterIds[0])?.name }}</strong>
            <ui-button @click.stop="$router.replace({query: {search: searchFilterSubmitted}});" class="ml-1 w-4 h-4" tiny>x</ui-button>
          </span>
          <span v-if="searchFilterSubmitted">
            | {{ $t('components.fileList.searchFilter') }}: "<strong>{{ searchFilterSubmitted }}</strong>"
            <ui-button @click.stop="$router.replace({query: {labels: labelsFilterIds}});" class="ml-1 w-4 h-4" tiny>x</ui-button>
          </span>
        </div>
      </template>

      <ui-page-section-box v-if="!isShowedLabels || !$isMobile()" tight>

        <!-- No gcodes available in current list -->
        <div v-if="!loading && gridItems?.results?.length == 0" class="px-6 py-4">
          <ui-alert>
            <div class="text-sm font-semibold dark:text-white-100">
              {{ $t('components.fileList.empty') }}
              <template v-if="labelsFilterIds?.length > 0">
                <span v-for="id in labelsFilterIds" :key="id">
                  "{{ $store.getters['gcodes/fileLabelById'](id)?.name }}"
                </span>
              </template>
            </div>

            <template v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.UPLOAD_FILE)">
              <p class="text-sm font-regular">{{ $t('components.fileList.emptySubtitle') }}</p>

              <ui-button
                @click="openUploadDialog(true)"
                color="primary"
                class="mt-2"
                small
              >
                {{ $t('components.fileList.emptyCta') }}
              </ui-button>
            </template>
          </ui-alert>
        </div>

        <!-- Grid with list of gcodes -->
        <ui-grid v-if="!loading">

          <router-link
            :to="{ name: 'GcodeDetail', params: { id: item.id }}"
            v-for="item of gridItems.results"
            :key="item.id"
            @mouseenter="onItemHover(true, item)"
            @mouseleave="onItemHover(false, item)"
          >
            <ui-grid-item class="relative">

              <!-- item selected (to delete) "checkbox" -->
              <div
                v-if="selectableMode"
                class="absolute top-0 left-0 right-0 bottom-0 z-10"
                :class="{ 'opacity-25 bg-red-300 dark:bg-gray-100': selectedItems[item.id] }"
                @click.stop.prevent="selectItemClicked(item)"
              >
              </div>
              <div
                v-if="selectableMode"
                class="absolute right-2 bottom-2 p-1 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 z-20"
                :class="{ 'bg-red-700 text-red-100 dark:bg-white-100 dark:text-red-700 border-0': selectedItems[item.id] === true }"
              >
                <ui-icon name="check" class="h-3 w-3 inline-block" />
              </div>

              <!-- preview image -->
              <ui-grid-item-col>
                <ui-grid-item-col-content class="pr-4">
                  <div class="w-32 h-32 border border-1 border-gray-400 dark:border-0 rounded-md">
                    <gcode-preview-image
                      :gcodeId="item.id"
                      :previewExists="$filters.previewImageExists(item.previewImages)"
                      :previewImageId="$filters.primaryPreviewImage(item.previewImages)?.id"
                      :images="item.previewImages"
                      :color="item.filament_color"
                    ></gcode-preview-image>
                  </div>
                </ui-grid-item-col-content>
              </ui-grid-item-col>

              <!-- name and gcode metadata -->
              <ui-grid-item-col type="main">
                <ui-grid-item-col>
                  <ui-grid-item-col-content type="heading">{{ item.name }}</ui-grid-item-col-content>

                  <ui-grid-item-col-content type="text" v-if="item.printing_time_sec > 0 || item.layer_height_mm > 0 || item.filament_used_mm > 0">
                    <ui-collapsible-box>
                      <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 text-xs font-normal">
                        <div class="infoBox" v-if="item.printing_time_sec > 0">
                          <div class="infoTitle">{{ $t('views.gcodes.titlePrintingTime') }}:</div>
                          <div>{{ $filters.secondsToPrintTime(item.printing_time_sec) }}</div>
                        </div>
                        <div class="infoBox" v-if="item.layer_height_mm > 0">
                          <div class="infoTitle">{{ $t('views.gcodes.titleLayerHeight') }}:</div>
                          <div>{{ item.layer_height_mm }}mm</div>
                        </div>
                        <div class="infoBox" v-if="item.filament_used_mm > 0">
                          <div class="infoTitle">{{ $t('views.gcodes.titleFilamentUsed') }}:</div>
                          <div>{{ $filters.milimitersToHumanReadable(item.filament_used_mm) }} / {{ $filters.gramsToHumanReadable(item.filament_used_g) }}</div>
                        </div>
                      </div>
                    </ui-collapsible-box>
                  </ui-grid-item-col-content>

                  <!-- file labels -->
                  <ui-grid-item-col-content class="sm:space-x-1 mr-1" v-if="item.fileLabels">
                    <ui-badge
                      class="mt-1"
                      v-for="item of item.fileLabels"
                      :key="item.id"
                    >{{ item.name }}</ui-badge>
                  </ui-grid-item-col-content>

                  <!-- is_public ? -->
                  <ui-grid-item-col-content class="space-x-1 mt-1" v-if="item.is_public">
                    <ui-badge color="success">{{ $t('views.gcodes.isPublic') }}</ui-badge>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>

                <!-- date and size and filament type and username -->
                <ui-grid-item-col type="right">
                  <ui-grid-item-col>
                    <!-- filament type -->
                    <ui-grid-item-col-content
                      class="sm:justify-end"
                      type="text"
                    >
                      <filament-type
                        enableColors
                        :name="item.filament_type"
                        :color="item.filament_color"
                        @typeChange="onFilamentTypeChange($event, item)"
                        @colorChange="onFilamentColorChange($event, item)"
                        :hasPermission="
                          $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_FILES)
                          || (
                            // user can change own files
                            $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_UPLOADED_FILES)
                            && $store.getters['account/user'].username == item.uploadedBy.username
                          )
                        "
                      />
                    </ui-grid-item-col-content>

                    <ui-grid-item-col-content type="text" class="justify-start sm:justify-end">
                      <time :datetime="item.uploadedOn">{{ $filters.humanDateFormat(item.uploadedOn) }}</time>
                    </ui-grid-item-col-content>
                    <ui-grid-item-col-content type="text" class="justify-start sm:justify-end">{{ $filters.humanFileSize(item.size) }}</ui-grid-item-col-content>
                    <ui-grid-item-col-content type="text">{{ item.uploadedBy.username }}</ui-grid-item-col-content>
                    <ui-grid-item-col-content type="text" class="relative justify-end space-x-1 pt-2">
                      <ui-button
                        v-if="$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
                        @click.stop.prevent="openPrintDialog(item)"
                        small
                      >{{ $t('components.fileList.actionPrint') }}</ui-button>

                      <ui-button
                        @click.stop.prevent="showItemContextMenu(item)"
                        icon="dots-vertical"
                        data-title="File list item context menu"
                        small
                      ></ui-button>
                      <ui-context-menu
                        :mobileTitle="item.name"
                        :show="selectedContextMenuItem?.id == item?.id"
                        @close="showItemContextMenu(null)"
                        data-title="File list item"
                      >
                        <!-- Print context menu -->
                        <ui-context-menu-item
                          @click.stop.prevent="openPrintDialog(item)"
                          v-if="$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
                        >{{ $t('components.fileList.actionPrint') }}</ui-context-menu-item>

                        <!-- Print queue context menu -->
                        <ui-context-menu-item
                          v-if="
                            $store.getters['account/currentGroup']?.settings?.print_queue_enabled
                            && $store.getters['account/hasPermission']($PERMISSIONS.OPERATE_PRINTERS)
                          "
                          @click.stop.prevent="showPrintQueueItemDialog(item)"
                        >{{ $t('components.fileList.actionAddToPrintQueue') }}</ui-context-menu-item>

                        <!-- edit labels context menu -->
                        <ui-context-menu-item
                          v-if="$store.getters['account/hasPermission']($PERMISSIONS.CHANGE_FILES)"
                          @click.stop.prevent="showEditLabelsDialog(item)"
                        >{{ $t('components.fileList.actionLabels') }}</ui-context-menu-item>

                        <!-- download context menu -->
                        <ui-context-menu-item @click.stop.prevent="downloadGcode(item)">{{ $t('components.fileList.actionDownload') }}</ui-context-menu-item>

                        <!-- delete context menu -->
                        <ui-context-menu-item
                          v-if="
                            $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_FILES)
                            || (
                              // user can change own files
                              $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_UPLOADED_FILES)
                              && $store.getters['account/user'].username == item.uploadedBy.username
                            )
                          "
                          @click.stop.prevent="deleteGcode(item)"
                          divider
                        >{{ $t('components.fileList.actionDelete') }}</ui-context-menu-item>
                      </ui-context-menu>

                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item-col>
              </ui-grid-item-col>

            </ui-grid-item>
          </router-link>

        </ui-grid>

        <!-- Load next observer - load more items when scroll to the end of the grid -->
        <div v-if="enableLoadNext && this.$store.getters['gcodes/gcodes'].next && !this.loading" class="text-center py-8">
          <intersection-observer
            sentinal-name="end-of-grid-reached"
            @on-intersection-element="loadNext()"
          ></intersection-observer>
          <ui-button
            @click="loadNext()"
            color="primary"
            class="mt-2"
            small
            :loading="loadingNext"
          >
            {{ $t('components.fileList.more') }}
          </ui-button>
        </div>

        <!-- Loading skeleton -->
        <ui-grid v-if="loading">
          <ui-grid-item v-for="index in 10" v-bind:key="index" :loading="true">
            <ui-grid-item-col type="main">
              <ui-grid-item-col>
                <ui-grid-item-col-content type="heading">
                  <ui-skeleton class="h-5 w-72" />
                </ui-grid-item-col-content>
                <ui-grid-item-col-content>
                  <ui-skeleton class="h-3.5 w-20 mt-1" />
                </ui-grid-item-col-content>
              </ui-grid-item-col>
              <ui-grid-item-col type="right">
                <ui-grid-item-col>
                  <ui-grid-item-col-content>
                    <ui-skeleton class="h-3 w-20 mb-1" />
                  </ui-grid-item-col-content>
                  <ui-grid-item-col-content>
                    <ui-skeleton class="h-3 w-12 mb-1 sm:float-right" />
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
              </ui-grid-item-col>
            </ui-grid-item-col>
            <ui-grid-item-col type="right-action" class="block self-start">
              <ui-grid-item-col>
                <ui-skeleton class="h-9 w-9 block" />
              </ui-grid-item-col>
            </ui-grid-item-col>
          </ui-grid-item>
        </ui-grid>
      </ui-page-section-box>

    </div>

  </div>

  <upload-gcode-dialog :show="isShowedUploadDialog" @canceled="openUploadDialog(false)" @uploaded="onFileUploaded" />
  <print-queue-item-dialog :show="isShowedPrintQueueDialog" @canceled="showPrintQueueItemDialog(null)" @saved="showPrintQueueItemDialog(null)" :gcodeId="selectedContextMenuItem?.id" />
  <file-labels-modal :files="selectedItemsObjectsArray" :show="isShowedLabelsDialog" @close="showEditLabelsDialog(null)" @save="reloadFiles()" />
  <delete-files-confirm-dialog :gcodesToDelete="selectedItemsObjectsArray" :show="isShowedDeleteConfirmDialog" @canceled="handleDeleteConfirmDialogEvent('canceled')" @deleting="handleDeleteConfirmDialogEvent('deleting')" @finished="handleDeleteConfirmDialogEvent('finished')" />

</template>

<style scoped>
.infoBox { @apply mr-2 mb-1; }
.infoTitle { @apply text-gray-400 dark:text-gray-500; }
</style>

<script>
import uiBadge from '@/components/ui/uiBadge.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiContextMenu from '@/components/ui/uiContextMenu.vue';
import uiContextMenuItem from '@/components/ui/uiContextMenuItem.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiCollapsibleBox from '@/components/ui/uiCollapsibleBox.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import FileLabelsList from '@/components/FileLabelsList.vue';
import UploadGcodeDialog from '@/components/UploadGcodeDialog.vue';
import PrintQueueItemDialog from '@/components/PrintQueueItemDialog.vue';
import FileLabelsModal from '@/components/FileLabelsModal.vue';
import DeleteFilesConfirmDialog from '@/components/DeleteFilesConfirmDialog.vue';
import EmptyState from '@/components/EmptyState.vue';
import FilamentType from '@/components/FilamentType.vue';

export default {
  name: 'FilesList',

  components: {
    uiBadge,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiSkeleton,
    uiButton,
    uiAlert,
    uiPageSectionBox,
    uiContextMenu,
    uiContextMenuItem,
    uiIcon,
    uiCollapsibleBox,
    uiForm,
    uiFormInput,
    GcodePreviewImage,
    IntersectionObserver,
    FileLabelsList,
    UploadGcodeDialog,
    PrintQueueItemDialog,
    FileLabelsModal,
    DeleteFilesConfirmDialog,
    EmptyState,
    FilamentType
  },

  props: {
    showLabels: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    enableLoadNext: {
      type: Boolean,
      default: true
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
  },

  watch:{
    $route (){
      this.processURLQueryParams();
    }
  },

  data: () => ({
    loading: false,
    loadingNext: false,
    gridItems: null,
    labelsFilterIds: [],
    labels: [],

    searchFilter: null,
    searchFilterSubmitted: null,

    selectedContextMenuItem: null,
    hoveredItem: null,

    selectableMode: false,
    selectedItems: {},

    selectedItemsObjectsArray: [],

    isShowedUploadDialog: false,
    isShowedPrintQueueDialog: false,
    isShowedLabelsDialog: false,
    isShowedDeleteConfirmDialog: false,
    isShowedLabels: true
  }),

  created: function() {
    this.processURLQueryParams();
  },

  methods: {
    async reloadFiles(searchString) {
      // on mobile are labels hidden by default
      this.isShowedLabels = !this.$isMobile();

      this.selectedItems = {};
      this.selectableMode = false;
      this.selectedItemsObjectsArray = [];

      // reset this.searchFilter when this fnc is called without search string
      if (!searchString || searchString == '') {
        this.searchFilter = null;
      }
      this.searchFilterSubmitted = this.searchFilter;

      if (! this.loading) {
        window.scrollTo(0,0);
        this.loading = true;
        this.loadingNext = true;
        this.gridItems = null;
        await this.$store.dispatch('gcodes/loadGcodes', { labelsFilterIds: this.labelsFilterIds, searchFilter: searchString });
        this.gridItems = this.$store.getters['gcodes/gcodes'];
        this.loading = false;

        window.scrollTo(0,0);

        // prevent loading next before regular gcodes are visible to not fire observer event
        setTimeout(() => {
          this.loadingNext = false;
        }, 300);
      }
    },

    processURLQueryParams() {
      let labels = [];

      if (this.$route.name == 'Gcodes' && this.$route?.query['labels']) {
        if (typeof this.$route.query['labels'] == 'string') {
          labels.push(this.$route.query['labels']);
        } else {
          labels = Object.values(this.$route.query['labels']);
        }
      }

      this.searchFilter = this.$route?.query['search'] ? this.$route?.query['search'] : null;
      this.labelsFilterIds = labels;
      this.reloadFiles(this.searchFilter);
    },

    async loadNext() {
      if (! this.loadingNext && ! this.loading) {
        this.loadingNext = true;
        if (this.$store.getters['gcodes/gcodes'].next) {
          await this.$store.dispatch('gcodes/loadGcodesNext', { url: this.$store.getters['gcodes/gcodes'].next });
          this.gridItems = this.$store.getters['gcodes/gcodes'];
        }

        // prevent to fire observer event multiple times in one case/scenario
        setTimeout(() => {
          this.loadingNext = false;
        }, 300);
      }
    },

    openUploadDialog(val) {
      if (! val) {
        this.reloadFiles();
      }
      this.isShowedUploadDialog = val;
    },

    onFileUploaded() {
      this.openUploadDialog(false);
      this.reloadFiles();
    },

    showItemContextMenu(item) {
      if (!item || this.selectedContextMenuItem?.id == item?.id) {
        this.selectedContextMenuItem = null;
      } else {
        this.selectedContextMenuItem = item;
      }
    },

    openPrintDialog(item) {
      this.showItemContextMenu(null);  // hide opened context menu
      this.$printDialogService.showPrintDialog({ gcodeId: item.id });
    },

    showPrintQueueItemDialog(item) {
      if (item) {
        this.isShowedPrintQueueDialog = true;
      } else {
        this.isShowedPrintQueueDialog = false;
        this.showItemContextMenu(null);
      }
    },

    showEditLabelsDialog(item) {
      if (item) {
        this.selectedItemsObjectsArray = [item,];
        this.isShowedLabelsDialog = true;
      } else {
        this.isShowedLabelsDialog = false;
        this.showItemContextMenu(null);
      }
    },

    editLabelsForSelectedGcodes() {
      this.selectedItemsObjectsArray = this.gridItems.results.filter(item => this.selectedItems[item.id]);
      if (this.selectedItemsObjectsArray.length > 0) {
        this.isShowedLabelsDialog = true;
      }
    },

    downloadGcode(item) {
      window.open(this.$store.getters['gcodes/gcodeDownloadLink'](item.id));
      this.showItemContextMenu(null);
    },

    deleteGcode(item) {
      this.isShowedDeleteConfirmDialog = true;
      this.selectedItemsObjectsArray = [item,];
    },

    deleteSelectedGcodes() {
      this.isShowedDeleteConfirmDialog = true;
      this.selectedItemsObjectsArray = this.gridItems.results.filter(item => this.selectedItems[item.id]);
    },

    handleDeleteConfirmDialogEvent(event) {
      this.isShowedDeleteConfirmDialog = false;
      this.showItemContextMenu(null);
      if (event == 'deleting') {
        window.scrollTo(0,0);
        this.loading = true;
      } else if (event == 'finished') {
        this.loading = false;
        this.reloadFiles();
      }
      // Not implemented event: if (event == 'canceled') {}
    },

    onItemHover(hover, item) {
      this.hoveredItem = hover ? item : null;
    },

    toggleSelectableMode() {
      this.selectedItems = {};
      this.selectableMode = !this.selectableMode;
    },

    selectItemClicked(item) {
      if (this.selectedItems[item.id]) {
        delete this.selectedItems[item.id];
      } else {
        this.selectedItems[item.id] = true;
      }
    },

    toggleShowLabels() {
      this.isShowedLabels = !this.isShowedLabels;
      window.scrollTo(0,0);
    },

    async onFilamentTypeChange(filamentType, item) {
      await this.$store.dispatch('gcodes/updateGcode', { id: item.id, filament_type: filamentType });
    },

    async onFilamentColorChange(filamentColor, item) {
      await this.$store.dispatch('gcodes/updateGcode', { id: item.id, filament_color: filamentColor });
    }
  }
}
</script>
