export function printQueue (state) {
  return state.printQueue;
}

export function printQueueStates (state) {
  return state.printQueueStates;
}

export function printQueueStateById (state) {
  return (id) => {
    for (const property in state.printQueueStates) {
      let pq_state = state.printQueueStates[property];
      if (pq_state.id == id) {
        return pq_state;
      }
    }
  }
}
