<template>
  <ui-modal :show="isVisible" @close="_dismiss()" data-title="uiConfirmDialog" :title="headerText">
    <div class="sm:flex sm:items-start">
      <div class="space-y-5 text-center sm:text-left w-full">
        <div class="">
          <div v-if="loading" class="my-10">
            <ui-loader :text="loadingText" />
          </div>
          <div v-else class="text-sm text-gray-700 dark:text-gray-400">
            <slot>{{ bodyText }}</slot>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 space-x-2 text-center">
      <ui-button @click.stop="_dismiss()" color="default">{{ dismissBtnText }}</ui-button>
      <ui-button @click.stop="_confirm()" :color="confirmBtnType" :disabled="disableConfirmButton || loading">{{ confirmBtnText }}</ui-button>
    </div>
  </ui-modal>
</template>

<script>
import uiModal from '@/components/ui/uiModal.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'uiConfirmDialog',

  components: {
    uiModal,
    uiButton,
    uiLoader
  },

  emits: [
    'onDismiss',
    'onConfirm'
  ],

  props: {
    headerText: {
      type: String,
      default: 'Confirm dialog'
    },
    bodyText: {
      type: String,
      default: 'Are you sure you want to continue?'
    },
    confirmBtnText: {
      type: String,
      default: 'Confirm'
    },
    confirmBtnType: {
      type: String,
      default: 'primary'
    },
    dismissBtnText: {
      type: String,
      default: 'Dismiss'
    },
    autoCloseOnConfirm: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableConfirmButton: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: null
    }
  },

  data: () => ({
    isVisible: false
  }),

  methods: {
    showDialog() {
      this.show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      });
    },

    close() {
      this.isVisible = false;
    },

    show() {
      this.isVisible = true;
    },

    _confirm() {
      if (this.autoCloseOnConfirm) {
        this.close();
      }
      this.resolvePromise(true);
      this.$emit('onConfirm');
    },

    _dismiss() {
      this.close();
      this.resolvePromise(false);
      this.$emit('onDismiss');
    }
  }
}
</script>
