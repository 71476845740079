<template>
  <div
    class="relative bg-white-100 dark:bg-gray-900 md:bg-inherit md:dark:bg-inherit p-1 md:p-0 border border-1 border-gray-600 md:border-0 overflow-hidden"
    :class="{
      'h-10 md:h-auto': isCollapsed
    }"
    @click.stop.prevent="switchCollapse($event)"
  >
    <slot></slot>
    <div class="absolute bottom-1 right-1 md:hidden">
      <ui-icon v-if="!isCollapsed" name="chevron-up" class="w-4 h-4" />
      <ui-icon v-if="isCollapsed" name="chevron-down" class="w-4 h-4" />
    </div>
  </div>
</template>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiCollapsibleBox',

  components: {
    uiIcon
  },

  data() {
    return {
      isCollapsed: true
    };
  },

  methods: {
    switchCollapse(event) {
      this.isCollapsed = !this.isCollapsed;
      if (window.innerWidth < 640) {
        event.preventDefault();
      }
    }
  }
}
</script>
