<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>{{ $t('views.settings.apiTokenCreate') }}</ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box>
        <ui-loader v-if="loading" />

        <ui-form v-if="!loading && !created" @submit="save()" class="space-y-0">
          <ui-form-input
            v-model:value="keyName"
            :labelText="$t('views.settings.apiTokenName')"
            id="keyName"
            :required="true"
          ></ui-form-input>

          <div
            class="text-sm font-medium text-gray-700 dark:text-gray-400 cursor-pointer select-none pt-6 pb-2"
            @click.stop="showAdvanced = !showAdvanced"
          >
          {{ $t('views.settings.apiTokenAddAdvanced') }} <ui-icon :name="showAdvanced ? 'chevron-down' : 'chevron-right'" class="inline h-4 w-4" />
          </div>
          <div v-if="showAdvanced" class="p-2 border border-1 border-gray-200">
            <div class="block text-sm font-medium text-gray-700 dark:text-gray-400">{{ $t('views.settings.apiTokenAdvancedScopesTitle') }}</div>
            <ui-grid :loading="false" class="mx-1 my-1">
              <ui-grid-item
                v-for="item in $store.getters['account/apiKeyScopes']"
                :key="item.id"
              >
                <ui-grid-item-col>
                  <ui-grid-item-col-content type="checkbox">
                    <input
                      v-model="scopeId"
                      :value="item.id"
                      :id="item.id"
                      type="radio"
                      class="h-4 w-4 text-gray-700 focus:ring-red-400 border-gray-200">
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
                <ui-grid-item-col type="main" @click.stop="scopeId = item.id">
                  <ui-grid-item-col>
                    <ui-grid-item-col-content type="text">
                      <div>
                        <div class="font-bold">{{ item.title }}</div>
                        <div class="mt-2 italic">{{ item.description }}</div>
                      </div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item-col>
              </ui-grid-item>
            </ui-grid>
          </div>

          <div class="pt-8">
            <ui-button @click.stop="close()" color="default" class="mr-4">
              {{ $t('views.settings.cancel') }}
            </ui-button>
            <ui-button @click.stop="save()" color="primary">
              {{ $t('views.settings.save') }}
            </ui-button>
          </div>
        </ui-form>

        <!-- TODO: better help/description after new token created -->
        <!-- TODO: add "copy button"? -->
        <div v-if="!loading && created">
          <div class="px-4" v-html="$t('views.settings.apiTokenCreatedInfo')"></div>
          <div class="my-8 p-2 bg-gray-100 dark:bg-gray-900 dark:text-white-100 text-center">{{ newKey }}</div>
          <ui-button @click.stop="close()" color="primary" class="mt-4">
            {{ $t('views.settings.apiTokenCreatedClose') }}
          </ui-button>
        </div>
      </ui-page-section-box>
    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SettingsApiTokensAdd',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiForm,
    uiFormInput,
    uiButton,
    uiLoader,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiIcon,
    SettingsNavigation
  },

  data: () => ({
    keyName: '',
    newKey: '',
    loading: false,
    created: false,
    scopeId: 'PRINT_TO_GROUP',
    showAdvanced: false
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('account', { createApiKey: 'createApiKey' }),

    init() {
      this.loading = false;
    },

    save() {
      this.loading = true;

      // if name for key is not provided, generate name based on current date and time
      if (!this.keyName || this.keyName?.length < 1) {
        this.keyName = new Date().toLocaleString();
      }

      this.createApiKey({ name: this.keyName, scope: this.scopeId }).then((response) => {
        this.newKey = response.data.key;
        this.loading = false;
        this.created = true;
      });
    },

    close() {
      this.$router.push({ name: 'SettingsApiTokens' })
    }
  }
}
</script>
