<template>
  <template v-if="showAnnouncement">
    <div class="px-6 py-3 text-center font-semibold text-white-100 bg-red-400">
      {{ announcement.text }}
      <div class="pt-3">
        <ui-button color="secondary" small @click.stop="announcement.btnClickCallback ? announcement.btnClickCallback() : showAnnouncement = false;">
          {{ announcement.btnText ? announcement.btnText : 'Ok' }}
        </ui-button>
      </div>
    </div>
  </template>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'uiAnnouncementBar',

  components: {
    uiButton,
  },

  watch: {
    '$store.state.app.announcement': function(newVal) {
      this.announcement = newVal;
      this.showAnnouncement = true;
    }
  },

  data: () => ({
    showAnnouncement: false,
    announcement: null
  })
};
</script>
