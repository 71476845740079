<template>
  <div>
    <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none">
      {{ labelText }}
    </label>
    <div class="mt-1">
      <textarea
        v-bind:value="value"
        v-on:input="$emit('update:value', $event.target.value)"
        :id="id"
        :name="id"
        :required="required"
        :disabled="disabled"
        :maxlength="maxlength"
        :rows="rows"
        class="
          appearance-none
          block w-full h-full
          px-3 py-2
          border border-gray-200 dark:border-gray-700 rounded-sm
          bg-white-100 dark:bg-gray-900
          dark:text-gray-300
          placeholder-gray-400
          focus:outline-none focus:ring-red-400 focus:border-transparent
          sm:text-sm
        "
        :class="{
          'bg-gray-100 text-gray-700 dark:bg-gray-900 dark:text-gray-500 italic': disabled,
        }"
      >
      </textarea>
      <div v-if="maxlength && maxlength > 0" class="text-xs text-right text-gray-400">
        {{ value ? value.length : 0 }} / {{ maxlength }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uiFormTextarea',

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    labelText: {
      type: String,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    rows: {
      type: Number,
      default: 4
    }
  },

  emits: ['update:value']
}
</script>
