<template>
  <ui-modal :show="show" @close="close()" data-title="FileLabelsModal" :title="$t('components.fileList.modalSetLabels')">
    <div>
      <div>
        <div class="pb-2">
          <p>
            <span v-if="files.length == 1">{{ files[0].name }}</span>
            <span v-else>
              {{ $t('components.fileList.modalSelectedFiles') }}
              <span class="text-white-100">{{ files.length }}</span>
            </span>
          </p>
        </div>

        <div class="mt-4">
          <div>
            <div v-if="loading" class="mt-10 mb-20">
              <ui-loader />
              <div class="text-sm text-center mt-4">Saving {{ filesSaved }} / {{ files.length }}</div>
            </div>
            <div v-if="!loading" class="space-y-2">
              <label
                v-for="( item ) in labels"
                :key="item.id"
                class="block cursor-pointer"
              >
                <input
                  type="checkbox"
                  v-model="item.isSelected"
                  class="
                    h-4 w-4
                    mr-2
                    border border-gray-200 dark:border-gray-700 rounded-sm
                    text-gray-700
                    bg-white-100 dark:bg-gray-900
                    focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400
                    cursor-pointer
                  "
                />
                <slot v-if="item.nestedCount == 0"></slot>
                <slot v-if="item.nestedCount == 1"><span class="inline ml-4">- </span></slot>
                <slot v-if="item.nestedCount == 2"><span class="inline ml-8">- </span></slot>
                <slot v-if="item.nestedCount == 3"><span class="inline ml-12">- </span></slot>
                {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 space-x-2 text-center">
      <ui-button @click="close()" color="default" :disabled="loading">
        {{ $t('components.modal.close') }}
      </ui-button>
      <ui-button @click="save()" color="primary" :disabled="loading">
        {{ $t('components.modal.save') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiModal from '@/components/ui/uiModal.vue';
import { KarmenError } from '@/errors';
import { mapActions } from 'vuex';

export default {
  name: 'FileLabelsModal',

  emits: ['close', 'save'],

  components: {
    uiButton,
    uiLoader,
    uiModal
  },

  props: {
    files: {
      type: Array,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.reload();
      }
    }
  },

  data: () => ({
    loading: true,
    labels: null,
    filesSaved: 0
  }),

  methods: {
    ...mapActions('gcodes', { loadFileLabelsAction: 'loadFileLabels' }),
    ...mapActions('gcodes', { updateGcodeLabelsAction: 'updateGcode' }),

    async reload() {
      this.loading = true;
      await this.loadFileLabelsAction();
      this.labels = this.$store.getters['gcodes/fileLabels'];

      this.labels.forEach(element => {
        element.isSelected = this.isLabelAssigned(element.id);
      });

      this.loading = false;
    },

    isLabelAssigned(labelId) {
      for (let k = 0; k < this.files.length; k++) {
        let file = this.files[k];
        for (let i = 0; i < file.fileLabels.length; i++) {
          if (file.fileLabels[i].id == labelId) {
            return true;
          }
        }
      }
      return false;
    },

    async save() {
      this.loading = true;
      this.filesSaved = 0;
      let selectedIds = [];
      this.labels.forEach(element => {
        if (element.isSelected) {
          selectedIds.push(element.id);
        }
      });
      for (let i = 0; i < this.files.length; i++) {
        this.filesSaved++;
        try {
          await this.updateGcodeLabelsAction({ id: this.files[i].id, labelIDs: selectedIds});
        } catch(e) {
          if (e?.response?.status == 400) {
            this.close();
            throw new KarmenError(
              'Label assign problem',
              e?.response?.data,
              e
            );
          }
        }
      }
      this.$emit('save');
      this.close();
    },

    close() {
      this.$emit('close');
    }
  }
}
</script>
