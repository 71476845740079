<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>
        <span v-if="action=='create'">
          {{ $t('views.settings.printerCreate') }}
        </span>
        <span v-if="action=='edit'">
          {{ $t('views.settings.printerEdit') }}
        </span>
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box>

        <ui-loader v-if="loading" />

        <ui-form
          v-if="!loading"
          @submit="save()"
          :showError="!isValid"
          :errorText="invalidReason"
        >

          <ui-button-switch
            v-if="action=='edit'"
            v-model:value="item.enabled"
            :text="$t('views.settings.printerEnabled')"
            id="enabled"
          ></ui-button-switch>

          <ui-form-input
            v-model:value="item.name"
            :labelText="$t('views.settings.printerName')"
            id="name"
            :required="true"
          ></ui-form-input>

          <slot v-if="item.enabled">

            <ui-form-textarea
              v-if="action == 'edit'"
              v-model:value="item.note"
              :labelText="$t('views.settings.printerNote')"
              id="note"
              :required="true"
              :disabled="!item.enabled"
            ></ui-form-textarea>

            <div :class="{'hidden': editApiKey}">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none">
                {{$t('views.settings.printerApiKey')}}
              </label>
              <ui-button @click.stop="editApiKey = !editApiKey;" color="default" small class="mb-4">
                {{ $t('views.settings.printerApiKeyChange') }}
              </ui-button>
            </div>

            <ui-form-input
              v-model:value="item.apiKey"
              :labelText="$t('views.settings.printerApiKey')"
              id="apiKey"
              :required="true"
              :class="{ 'hidden': !editApiKey }"
            ></ui-form-input>

            <div :class="{'hidden': editDeviceKey}">
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none">
                {{$t('views.settings.printerDeviceKey')}}
              </label>
              <ui-button @click.stop="editDeviceKey = !editDeviceKey;" color="default" small class="mb-4">
                {{ $t('views.settings.printerDeviceKeyChange') }}
              </ui-button>
            </div>

            <ui-form-input
              v-model:value="item.token"
              :labelText="$t('views.settings.printerDeviceKey')"
              id="deviceKey"
              :required="true"
              :class="{ 'hidden': !editDeviceKey }"
            ></ui-form-input>

            <ui-form-select
              v-model:value="item.cameraRotation"
              :labelText="$t('views.settings.printerCameraRotation')"
              :values="rotationOptions"
              id="cameraRotation"
            />
            <ui-button-switch
              v-model:value="item.cameraEnabled"
              :text="$t('views.settings.printerCameraEnabled')"
              id="cameraEnabled"
            ></ui-button-switch>

            <ui-button-switch
              v-if="$store.getters['account/currentGroup']?.settings?.print_queue_enabled"
              v-model:value="item.joboxEnabled"
              :text="$t('views.settings.printerJoboxEnabled')"
              id="joboxEnabled"
            ></ui-button-switch>
          </slot>

          <!-- Action buttons -->
          <div class="flex justify-between">
            <div>
              <ui-button @click.stop="close()" color="default" class="mr-4">
                {{ $t('views.settings.cancel') }}
              </ui-button>
              <ui-button @click.stop="save()" color="primary">
                {{ $t('views.settings.save') }}
              </ui-button>
            </div>

            <ui-button
              v-if="action == 'edit'"
              @click.stop="confirmItemDelete(item, group)"
              color="clear"
            >
              {{ $t('views.settings.delete') }}
            </ui-button>
          </div>

        </ui-form>
      </ui-page-section-box>
    </template>
  </authorized-col-layout>

  <ui-confirm-dialog
    ref="confirmDeleteDialog"
    :headerText="$t('views.settings.printerDeleteTitle')"
    :confirmBtnText="$t('views.settings.deleteConfirm')"
    :dismissBtnText="$t('views.settings.deleteCancel')"
  >
    <p>{{ $t('views.settings.printerDeleteInfo1') }}</p>

    <p>
      <i18n-t keypath="views.settings.printerDeleteInfo2">
        <strong>"{{ deleteConfirmPrinter.name }}"</strong>
        <strong>"{{ deleteConfirmGroup.name }}"</strong>
      </i18n-t>
    </p>
  </ui-confirm-dialog>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiFormTextarea from '@/components/ui/uiFormTextarea.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiButtonSwitch from '@/components/ui/uiButtonSwitch.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';

import uiLoader from '@/components/ui/uiLoader.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SettingsPrinterAddOrEdit',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiForm,
    uiFormInput,
    uiFormSelect,
    uiFormTextarea,
    uiButton,
    uiButtonSwitch,
    uiConfirmDialog,
    uiLoader,
    SettingsNavigation
  },

  props: {
    id: {
      type: String,
      default: null,
      required: false
    },
    groupId: {
      type: String,
      default: null,
      required: false
    },
    action: {
      type: String,
      required: true,
      validator: function (value) {
        return ['edit', 'create'].indexOf(value) !== -1
      }
    }
  },

  data: () => ({
    item : {
      id: '',
      name: '',
      token: '',
      note: '',
      apiKey: '',
      cameraEnabled: true,
      cameraRotation: '',
      enabled: true
    },
    deleteConfirmPrinter: null,
    deleteConfirmGroup: null,
    group: null,
    loading: true,
    isValid: true,
    invalidReason: '',
    rotationOptions: [
      {id: '0', name: '0°'},
      {id: '90', name: '90°'},
      {id: '180', name: '180°'},
      {id: '270', name: '270°'},
    ],
    editApiKey: false,
    editDeviceKey: false,
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('printers', { addPrinter: 'addPrinter' }),
    ...mapActions('printers', { updatePrinter: 'updatePrinter' }),
    ...mapActions('printers', { loadPrintersByGroupId: 'loadPrintersByGroupId' }),
    ...mapGetters('printers', { getPrinterById: 'printerById' }),
    ...mapActions('printers', { deletePrinterAction: 'deletePrinter' }),
    ...mapGetters('account', { getGroupById: 'groupById' }),

    async init() {
      this.group = this.getGroupById()(this.groupId);
      await this.loadPrintersByGroupId({ id: this.groupId });

      if (this.action == 'edit') {
        this.item = structuredClone(this.getPrinterById()(this.id));
        this.item.cameraRotation = this.item.cameraRotation.toString();
      } else {
        this.item.cameraRotation = this.rotationOptions[0].id;
        this.editApiKey = true;
        this.editDeviceKey = true;
      }

      this.loading = false;
    },

    validateForm: function() {
      this.isValid = true;

      if (!this.item.name || this.item.name.length <= 0) {
        this.isValid = false;
        this.invalidReason = 'Printer name can`t be empty.';
      } else if (this.action == 'create' && (!this.item.token || this.item.token.length <= 0)) {
        this.isValid = false;
        this.invalidReason = 'Code can`t be empty.';
      } else if (!this.groupId || this.groupId.length <= 0) {
        this.isValid = false;
        this.invalidReason = 'Workspace not selected.';
      }

      return this.isValid;
    },

    async save() {
      if (this.validateForm()) {
        this.loading = true;

        let autoClose = true;

        if (this.action == 'edit') {
          await this.updatePrinter({
            id: this.item.id,
            name: this.item.name,
            note: this.item.note,
            apiKey: this.item.apiKey,
            token: this.item.token,
            cameraEnabled: this.item.cameraEnabled,
            cameraRotation: this.item.cameraRotation,
            joboxEnabled: this.item.joboxEnabled,
            enabled: this.item.enabled
          }).catch((e) => {
            if (e?.response?.status == 400 && e?.response?.data?.token && Array.isArray(e?.response?.data?.token)) {
              this.$store.commit('app/setNotification', ({
                title: this.$t('views.settings.printerDeviceKeyErrorTitle'),
                text: this.$t('views.settings.printerDeviceKeyErrorText'),
                onClose: () => {
                  this.loading = false;
                }
              }));
              autoClose = false;
            } else {
              throw e;
            }
          });
        } else {
          await this.addPrinter({
            name: this.item.name,
            token: this.item.token,
            groupId: this.groupId,
            apiKey: this.item.apiKey,
            cameraEnabled: this.item.cameraEnabled,
            cameraRotation: this.item.cameraRotation,
            joboxEnabled: this.item.joboxEnabled,
            enabled: true
          });
          await this.loadPrintersByGroupId({ id: this.groupId });
        }

        // need to reload groups as we get printers count from group
        await this.$store.dispatch('account/groups');

        if (autoClose) {
          this.close();
        }
      }
    },

    confirmItemDelete: function(item, group) {
      this.deleteConfirmPrinter = item;
      this.deleteConfirmGroup = group;
      this.$refs.confirmDeleteDialog.showDialog().then((result) => {
        if (result) {
          this.loading = true;
          this.deletePrinterAction({ id: item.id }).then(() => {
            // reload printers groups and redirect to printers settings
            this.$store.dispatch('printerGroups/loadPrinterGroups').then(() => {
              this.$router.push({ path: '/settings/printers' });
            });
          });
        }
      });
    },

    close() {
      this.$router.go(-1);
    }
  }
}
</script>
