<template>
  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'uiDescriptionListItem',

  components: {},

  props: {}
}
</script>
