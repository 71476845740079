import { createI18n } from 'vue-i18n';


// set locale - default cs
const appLanguage = localStorage.getItem('locale') ? localStorage.getItem('locale') : navigator.language;

export const i18n = createI18n({
  locale: appLanguage.includes('cs') ? 'cs' : 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('./locales/en.json'),
    cs: require('./locales/cs.json')
  },
  warnHtmlInMessage: 'off' // disable warning HTML in message 
});
