import httpClient from '@/httpClient';
import { throwNetworkUnhandledError } from '@/errors';

export function loadPrinterGroups({ commit, rootGetters }) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setPrinterGroups', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function createItem({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/';
    let obj = {
      name: payload.name
    };
    return httpClient.post(url, obj, { authorizeRequest: true });
  }
}

export function patchItem({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/' + payload.id + '/';
    let obj = {
      name: payload.name
    };
    return httpClient.patch(url, obj, { authorizeRequest: true });
  }
}

export function addPrinterToPrinterGroup({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/' + payload.printerGroupId + '/printer/';
    let obj = {
      printer_id: payload.printerId
    };
    return httpClient.put(url, obj, { authorizeRequest: true });
  }
}

export function deletePrinterFromPrinterGroup({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/' + payload.printerGroupId + '/printer/';
    return httpClient.delete(url, { data: { printer_id: payload.printerId }, authorizeRequest: true });
  }
}

export function deletePrinterGroup({ rootGetters }, printerGroupId) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/printer-groups/' +  printerGroupId + '/';
    return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}
