<template>
  <section
    class="bg-white-100 dark:bg-gray-800 rounded-sm"
    :class="tight ? '' : 'px-4 py-6 sm:px-6'"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'uiPageSectionBox',
  props: {
    tight: {
      type: Boolean,
      default: false
    }
  }
}
</script>
