<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back class="relative">
        {{ $t('views.settings.workspaces') }}

        <template v-slot:actionButton>
          <div :class="{'absolute right-0 -top-1.5': $isMobile()}">
            <ui-button
              v-if="$isMobile()"
              @click="this.$router.push({ name: 'SettingsWorkspaceAdd' });"
              color="clear"
              :data-title="$t('views.settings.workspaceAdd')"
            >
              <ui-icon name="plus" class="h-5 w-5 inline-block text-red-400" />
            </ui-button>

            <ui-button
              v-else
              @click="this.$router.push({ name: 'SettingsWorkspaceAdd' });"
              color="primary"
            >
              {{ $t('views.settings.workspaceAdd') }}
            </ui-button>
          </div>
        </template>
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box tight>
        <ui-grid v-if="!itemId" :loading="loading">
          <ui-grid-item
            v-for="( item ) in items"
            :key="item.id"
          >

            <!-- item name & date created -->
            <ui-grid-item-col type="main" @click.stop="editItem(item)">
              <ui-grid-item-col>
                <ui-grid-item-col-content type="heading">{{ item.name }}</ui-grid-item-col-content>
                <ui-grid-item-col-content type="text">
                  {{ $t('views.settings.workspaceRole') }}: {{ item.me.role }}
                  <div v-if="item.users.length > 1" class="italic">({{ item.users.length-1 }} more user<span v-if="item.users.length > 2">s</span>)</div>
                </ui-grid-item-col-content>
              </ui-grid-item-col>
            </ui-grid-item-col>

            <!-- action button - edit -->
            <ui-grid-item-col type="right-action" v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP, item.id)">
              <ui-grid-item-col-content>
                <ui-button @click.stop="editItem(item)" small>
                  {{ $t('views.settings.edit') }}
                </ui-button>
              </ui-grid-item-col-content>
            </ui-grid-item-col>

          </ui-grid-item>
        </ui-grid>

      </ui-page-section-box>
    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SettingsWorkspaces',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton,
    uiIcon,
    SettingsNavigation
  },

  data: () => ({
    items: null,
    itemId: null,
    loading: true,
    contextMenuItemId: null
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('account', { loadGroups: 'groups' }),
    ...mapGetters('account', { getGroups: 'groups' }),

    async init() {
      this.loading = true;
      this.itemId = null;
      this.items = null;
      await this.loadGroups();
      this.items = this.getGroups();
      this.loading = false;
    },

    editItem(item) {
      if (this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP, item.id)) {
        this.$router.push({ name: 'SettingsWorkspaceEdit', params: { id: item.id } });
      }
    }
  }
}
</script>
