<template>
  <ui-modal :show="show" @close="reloadApp();" data-title="ErrorModal">

    <template v-if="error?.constructor?.name == 'ServerMaintenanceError'">
      <h3 class="text-lg leading-6 font-medium text-gray-700 dark:text-gray-300" id="modal-headline">{{ $t('components.errorModal.serverMaintenanceTitle') }}</h3>
      <div class="mt-5">{{ $t('components.errorModal.serverMaintenanceDescription') }}</div>
      <div class="mt-5 sm:mt-10 flex justify-end"><ui-button @click.stop="reloadApp();" color="primary">{{ $t('components.errorModal.btnReload') }}</ui-button></div>
    </template>

    <!-- NetworkOfflineError -->
    <template v-else-if="error?.constructor?.name == 'NetworkOfflineError'">
      <h3 class="text-lg leading-6 font-medium text-gray-700 dark:text-gray-300" id="modal-headline">{{ $t('components.errorModal.noInternetTitle') }}</h3>
      <div class="mt-5">{{ $t('components.errorModal.noInternetDescription') }}</div>
      <div class="mt-5 sm:mt-10 flex justify-end"><ui-button @click.stop="reloadApp();" color="primary">{{ $t('components.errorModal.btnRetry') }}</ui-button></div>
    </template>

    <!-- ForbiddenError -->
    <template v-else-if="error?.constructor?.name == 'ForbiddenError'">
      <h3 class="text-lg leading-6 font-medium text-gray-700 dark:text-gray-300" id="modal-headline">{{ $t('components.errorModal.forbiddenTitle') }}</h3>
      <div class="mt-5">{{ $t('components.errorModal.forbiddenDescription') }}</div>
      <div v-if="$VUE_APP_SHOW_DEV_FEATURES && error?.cause?.config" class="mt-4 text-xs space-y-2 text-gray-700 dark:text-gray-400 border-2 p-2 h-48 overflow-auto">
        <div>
          <div class="font-bold">Base URL:</div>
          <div class="break-all">{{ error?.cause?.config?.baseURL }}</div>
        </div>
        <div>
          <div class="font-bold">URL:</div>
          <div class="break-all">{{ error?.cause?.config?.url }}</div>
        </div>
        <div>
          <div class="font-bold">Method:</div>
          <div class="break-all">{{ error?.cause?.config?.method }}</div>
        </div>
        <div>
          <div class="font-bold">Response status:</div>
          <div class="break-all">{{ error?.cause?.response?.status }}</div>
        </div>
        <div>
          <div class="font-bold">Response data:</div>
          <div class="break-all">{{ error?.cause?.response?.data }}</div>
        </div>
        <div>
          <div class="font-bold">Response config data:</div>
          <div class="break-all">{{ error?.cause?.response?.config?.data }}</div>
        </div>
      </div>
      <div class="mt-5 sm:mt-10 flex justify-end"><ui-button @click.stop="reloadApp();" color="primary">{{ $t('components.errorModal.btnContinue') }}</ui-button></div>
    </template>

    <!-- KarmenError -->
    <template v-else-if="error?.reason?.constructor?.name == 'KarmenError'">
      <h3 class="text-lg leading-6 font-medium text-gray-700 dark:text-gray-300" id="modal-headline">{{ error.reason.title }}</h3>
      <div class="mt-5">{{ error.reason.description }}</div>
      <div class="mt-5 sm:mt-10 flex justify-end"><ui-button @click.stop="reloadApp();" color="primary">{{ $t('components.errorModal.btnContinue') }}</ui-button></div>
    </template>

    <!-- Unknown error -->
    <template v-else>
      <h3 class="text-lg leading-6 font-medium text-gray-700 dark:text-gray-300" id="modal-headline">{{ $t('components.errorModal.unknownErrorTitle') }}</h3>
      <div class="mt-2 text-sm text-gray-700 dark:text-gray-400">
        {{ $t('components.errorModal.unknownErrorDescription') }}
      </div>
      <div class="mt-8 text-xs text-gray-700 dark:text-gray-400">
        <div v-if="error.title">{{ error.title }}</div>
        <div v-else>{{ $t('components.errorModal.unknownErrorTitle2') }}</div>
        
        <div v-if="error.description">{{ error.description }}</div>
        <div v-else>{{ error }}</div>
      </div>
      <div class="mt-5 sm:mt-10 flex justify-center">
        <ui-button @click.stop="reloadApp();" color="primary">{{ $t('components.errorModal.btnReload') }}</ui-button>
      </div>
    </template>

  </ui-modal>
</template>

<script>
import uiModal from '@/components/ui/uiModal.vue';
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'ErrorModal',

  components: {
    uiModal,
    uiButton
  },

  emits: ['close'],

  props: {
    error: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    reloadApp() {
      window.location.assign('/');
    },

    hide() {
      this.$emit('close');
    }
  }
}
</script>
