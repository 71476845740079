<template>
  <!-- file is deleted -->
  <ui-skeleton v-if="isDeleted" class="w-full h-full">
    <div class="deleted">
      {{$t('components.gcodePreviewImage.isDeleted')}}
    </div>
  </ui-skeleton>

  <!-- preview not exists -->
  <ui-skeleton v-else-if="!previewExists" class="w-full h-full">
    <div class="deleted">
      {{$t('components.gcodePreviewImage.previewNotAvailable')}}</div>
  </ui-skeleton>

  <div v-else class="relative" @mouseover="hovered=true" @mouseleave="hovered=false">
    <img
      :class="{
        'cursor-zoom-in': allowInteractions,
        'image-color-gray': color == 'GRAY' || color == '',
        'image-color-blue': color == 'BLUE',
        'image-color-red': color == 'RED',
        'image-color-green': color == 'GREEN',
        'image-color-pink': color == 'PINK',
        'image-color-orange': color == 'ORANGE',
      }"
      ref="img_ref"
      @click.stop.prevent="allowInteractions ? showFullscreen() : '';"
    />
    <template v-if="allowInteractions">
      <div
        v-if="hovered && images?.length > 1"
        @mouseover="hovered=true"
        @mouseleave="hovered=false"
        class="absolute left-1 lg:-left-5 top-1/2 -translate-y-1/2 border border-2 border-white-100 rounded-full hover:cursor-pointer"
        @click.stop.prevent="showPreviousImage()"
      >
        <div class="bg-red-400 hover:bg-red-300 my-auto p-1 rounded-full">
          <ui-icon name="chevron-left" class="h-4 w-4 mt-0.5 text-white-100" />
        </div>
      </div>
      <div
        v-if="allowInteractions && hovered && images?.length > 1"
        @mouseover="hovered=true"
        @mouseleave="hovered=false"
        class="absolute right-1 lg:-right-5 top-1/2 -translate-y-1/2 border border-2 border-white-100 rounded-full hover:cursor-pointer"
        @click.stop.prevent="showNextImage()"
      >
        <div class="bg-red-400 hover:bg-red-300 my-auto p-1 rounded-full">
          <ui-icon name="chevron-right" class="h-4 w-4 mt-0.5 text-white-100" />
        </div>
      </div>
      <div v-if="allowInteractions && !loading && images?.length > 1" class="absolute bottom-0 right-0 left-0 pt-1">
        <div class="flex flex-row">
          <div class="grow flex flex-row pt-1">
            <div class="grow"></div>
            <div
              v-for="img of images"
              :key="img.id"
              class="w-3 h-3 mx-0.5 rounded-md border border-2 border-white-100 hover:cursor-pointer"
              @click.stop.prevent="loadImage(img.id)"
            >
              <div
                class="w-full h-full rounded-md"
                :class="{
                  'bg-red-400': img.id == selectedImageId,
                  'bg-gray-900': img.id != selectedImageId
                }"
              ></div>
            </div>
            <div class="grow"></div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="loading" class="absolute left-0 right-0 top-0 bottom-0 z-50 backdrop-blur-sm bg-white/30"></div>
  </div>

  <!-- Fullscreen -->
  <div v-if="allowInteractions">
    <ui-fullscreen-image
      :image="imgData"
      :visible="fullscreenImageVisible"
      :closeCallback="hideFullscreen"
    />
    <div class="z-[100] fixed bottom-12 left-0 right-0 flex justify-center space-x-8">
      <div
        v-if="fullscreenImageVisible"
        class="border border-2 border-white-100 rounded-full hover:cursor-pointer"
        @click.stop.prevent="showPreviousImage()"
      >
        <div class="bg-red-400 hover:bg-red-300 my-auto p-1 rounded-full">
          <ui-icon name="chevron-left" class="h-4 w-4 mt-0.5 text-white-100" />
        </div>
      </div>
      <div
        v-if="fullscreenImageVisible"
        class="border border-2 border-white-100 rounded-full hover:cursor-pointer"
        @click.stop.prevent="showNextImage()"
      >
        <div class="bg-red-400 hover:bg-red-300 my-auto p-1 rounded-full">
          <ui-icon name="chevron-right" class="h-4 w-4 mt-0.5 text-white-100" />
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.deleted {
  @apply
    absolute top-0 left-0 z-10 px-2 w-full h-full
    flex items-center justify-center
    text-gray-400 dark:text-gray-400
    uppercase text-2xs text-center overflow-hidden;
}

.image-color-gray { @apply grayscale-[95%] hue-rotate-[220deg] brightness-[3]; }
.image-color-blue { @apply grayscale-[5%] hue-rotate-[220deg] brightness-[3]; }
.image-color-red { @apply grayscale-[5%] brightness-[3]; }
.image-color-green { @apply grayscale-[5%] hue-rotate-[120deg] brightness-[3]; }
.image-color-pink { @apply grayscale-[5%] hue-rotate-[330deg] brightness-[3]; }
.image-color-orange { @apply grayscale-[5%] hue-rotate-[40deg] brightness-[3]; }
</style>

<script>
import { useKeypress } from 'vue3-keypress';
import uiFullscreenImage from '@/components/ui/uiFullscreenImage.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'GcodePreviewImage',

  components: {
    uiFullscreenImage,
    uiSkeleton,
    uiIcon
  },

  props: {
    gcodeId: {
      type: String,
      default: null,
      required: false
    },
    images: {
      type: Array,
      default: null,
      required: false
    },
    previewImageId: {
      type: String,
      default: null,
      required: false
    },
    previewExists: {
      type: Boolean,
      default: false,
      required: true
    },
    isDeleted: {
      type: Boolean,
      default: false,
      required: false
    },
    allowInteractions: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'GRAY',
      required: false
    },
  },

  data: () => ({
    fullscreenImageVisible: false,
    selectedImageId: null,
    imgData: null,
    loading: true,
    hovered: false
  }),

  mounted: function() {
    this.$nextTick(() => {
      this.selectedImageId = this.previewImageId;
      this.loadImage(this.selectedImageId); // load main image when ready
    });
  },

  created: function() {
    useKeypress({
      keyEvent: 'keydown',
      keyBinds: [{
          keyCode: 27, // 27 == ESC
          success: this.hideFullscreen,
          preventDefault: false
        }, {
          keyCode: 'left', // 37 == LEFT
          success: this.showPrevByKey,
          preventDefault: false
        }, {
          keyCode: 'right', // 39 == RIGHT
          success: this.showNextByKey,
          preventDefault: false
        }]
    });
  },

  methods: {
    loadImage(id) {
      this.loading = true;

      this.selectedImageId = id;

      if (this.gcodeId && this.selectedImageId && this.previewExists) {
        return this.$store.dispatch('gcodes/downloadGcodePreviewImageAsObjectURL', {gcodeId: this.gcodeId, previewImageId: this.selectedImageId}).then((data) => {
          this.imgData = data;
          this.loading = false;
          // sometimes value in $refs may not exists, for example in situation, when user moved on another page before video image was downloaded
          if (this.$refs['img_ref']) {
            this.$refs['img_ref'].src = this.imgData;
          }
        }).catch((error) => {
          this.loading = false;
          // suppress error when image is not available on the server
          if (error?.request?.status != 404) {
            throw error;
          }
        });
      }
    },

    showPrevByKey() {
      this.fullscreenImageVisible ? this.showPreviousImage() : null;
    },

    showNextByKey() {
      this.fullscreenImageVisible ? this.showNextImage() : null;
    },

    showNextImage() {
      let currentImageIndex = this.images.findIndex((e) => {
        return e.id == this.selectedImageId;
      });
      if (currentImageIndex < this.images.length-1) {
        this.loadImage(this.images[currentImageIndex+1].id);
      } else {
        this.loadImage(this.images[0].id);
      }
    },

    showPreviousImage() {
      let currentImageIndex = this.images.findIndex((e) => {
        return e.id == this.selectedImageId;
      });
      if (currentImageIndex > 0) {
        this.loadImage(this.images[currentImageIndex-1].id);
      } else {
        this.loadImage(this.images[this.images.length-1].id);
      }
    },

    showFullscreen() {
      this.fullscreenImageVisible = true;
    },

    hideFullscreen() {
      this.fullscreenImageVisible = false
    }
  }
}
</script>
