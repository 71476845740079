<template>

  <ui-page-section-box
    tight
    class="border-l-2 border-red-400 relative"
    :class="{ 'border-2 border-dashed opacity-25': isDraggingOverThis }"
  >
    <div
      @dragover.prevent="isDraggingOverThis = true"
      @dragenter.prevent="isDraggingOverThis = true"
      @dragleave.prevent="isDraggingOverThis = false"
      @drop="onDropPrinter($event)"
    >
      <div class="px-4 py-4 flex cursor-pointer" @click.stop="toggleExpanded()">
        <div class="grow">
          <h3 v-if="!simpleView" class="text-base text-gray-900 dark:text-gray-300">
            <div class="font-semibold leading-6">{{ group.name }}</div>
            <div class="text-xs">{{ group.printers.length }} printers</div>
          </h3>
        </div>
        <div class="flex space-x-3" v-if="settings.isExpanded">
          <div v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)" class="flex space-x-0" @click.stop.prevent="null">
            <div v-if="bulkActionName != ''"><ui-button @click.stop.prevent="bulkActionName = ''" icon="x-circle" small class="h-7"></ui-button></div>
            <div v-if="bulkActionName != ''"><ui-button @click.stop.prevent="doBulkAction()" icon="paper-airplane" small color="primary" class="h-7"></ui-button></div>
            <div class="w-36">
              <select
                @change="validateBulkAction()"
                id="bulkActionName"
                name="bulkActionName"
                v-model="bulkActionName"
                class="
                  appearance-none
                  block w-full
                  h-7 py-0 px-2
                  border border-1
                  border-gray-200 dark:border-gray-700 focus:border-gray-400 focus:dark:border-gray-700
                  focus:ring-0
                  bg-white-100 dark:bg-gray-900
                  text-sm font-normal dark:text-gray-300
                "
              >
                <option value="">---</option>
                <option value="TURN_OFF">{{ $t('components.printer.actionPowerOff') }}</option>
                <option value="TURN_ON">{{ $t('components.printer.actionPowerOn') }}</option>
              </select>
            </div>
          </div>
          <div><ui-button :icon="settings.showCameraStream ? 'camera-off' : 'camera-on'" @click.stop.prevent="switchCameraStream()" small class="h-7" /></div>
          <div v-if="enableDelete && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)">
            <ui-button icon="dots-vertical" @click.stop.prevent="isContextMenuOpened = true" small class="h-7" />
            <ui-context-menu
              mobileTitle="Printers group"
              :show="isContextMenuOpened"
              @close="isContextMenuOpened = false"
              data-title="Printers group context menu"
            >
              <ui-context-menu-item @click.stop.prevent="showPrinterGroupDialog = true; isContextMenuOpened = false;" icon="pencil">{{ $t('views.settings.edit') }}</ui-context-menu-item>
              <ui-context-menu-item @click.stop.prevent="openConfirmDeletePrintersGroupDialog(); isContextMenuOpened = false;" icon="trash">{{ $t('views.settings.delete') }}</ui-context-menu-item>
            </ui-context-menu>
          </div>
        </div>
      </div>

      <transition name="sectionTransition" appear>
        <div v-if="settings.isExpanded" class="p-4">
          <div v-if="group.printers.length <= 0" class="flex justify-center">
            <div v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)" class="text-center italic p-2 w-[75%] border border-1 border-dashed border-red-400">{{ $t('components.printerGroup.noPrinters') }}</div>
          </div>
          <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div v-for="printer in group.printers" :key="printer.id">
              <printer-in-list
                :id="printer.id"
                :showCamera="settings.showCameraStream"
                :draggable="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP) && bulkActionName == ''"
                @dragover.prevent
                @dragenter.prevent
                @dragstart="startDragPrinter($event, printer)"
                :ref="'printer_ref_' + printer.id"
                :showSelectableLayer="bulkActionName != ''"
                :canBeSelected="printersExtras[printer.id].canBeSelected"
                :isSelected="printersExtras[printer.id].isSelected"
                @selectedClick="printersExtras[printer.id].isSelected=!printersExtras[printer.id].isSelected"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- LOADING SPINNER -->
    <div v-if="loading" class="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm bg-white/10 place-content-center"><ui-loader /></div>
  </ui-page-section-box>

  <ui-confirm-dialog
    v-if="enableDelete"
    ref="confirmDeletePrinterGroupDialog"
    :headerText="$t('components.printerGroup.deletePrinterGroupModalTitleText') + ': ' + group.name"
    :confirmBtnText="$t('components.modal.delete')"
    :dismissBtnText="$t('components.modal.cancel')"
  >
    <p>{{ $t('components.printerGroup.deletePrinterGroupModalText') }}</p>
  </ui-confirm-dialog>

  <printer-group-dialog :printerGroupId="group.id" :show="showPrinterGroupDialog" @canceled="showPrinterGroupDialog = false;" @saved="showPrinterGroupDialog = false;" />
</template>

<style scoped>
.sectionTransition-enter-active,
.sectionTransition-leave-active { @apply transform transition ease-out duration-200; }
.sectionTransition-enter-from,
.sectionTransition-leave-to { @apply opacity-0; }
.sectionTransition-enter-to,
.sectionTransition-leave-from { @apply opacity-100; }
</style>

<script>
import uiLoader from '@/components/ui/uiLoader.vue';
import PrinterInList from '@/components/PrinterInList.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import PrinterGroupDialog from '@/components/PrinterGroupDialog.vue';
import uiContextMenu from '@/components/ui/uiContextMenu.vue';
import uiContextMenuItem from '@/components/ui/uiContextMenuItem.vue';

export default {
  name: 'PrinterGroup',

  components: {
    uiLoader,
    PrinterInList,
    uiPageSectionBox,
    uiButton,
    uiConfirmDialog,
    PrinterGroupDialog,
    uiContextMenu,
    uiContextMenuItem
  },

  emits: [
    'deleted',
    'printerDropped'
  ],

  props: {
    group: {
      type: Object,
      required: true
    },
    simpleView: {
      type: Boolean,
      required: true
    },
    enableDelete: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  watch: {
    'group.printers': function() {
      this.updateExtras();
    }
  },

  data: () => ({
    isDraggingOverThis: false,

    settings: {
      id: null,
      showCameraStream: false,
      isExpanded: true
    },

    showPrinterGroupDialog: false,
    isContextMenuOpened: false,

    bulkActionName: '',
    printersExtras: {}
  }),

  created: async function() {
    this.settings.id = 'printerGroupSettings_' + this.group.id;
    this.getLocalStorageSettings();

    if (this.simpleView) {
      this.settings.isExpanded = true;
    }

    this.updateExtras();
  },

  methods: {
    updateExtras() {
      this.bulkActionName = '';
      this.printersExtras = {};
      for (let p of this.group.printers) {
        this.printersExtras[p.id] = {
          isSelected: false,
          canBeSelected: false
        };
      }
    },

    switchCameraStream() {
      this.settings.showCameraStream = !this.settings.showCameraStream;
      this.saveLocalStorageSettings();
    },

    toggleExpanded() {
      if (!this.simpleView) {
        this.settings.isExpanded = !this.settings.isExpanded;
        this.saveLocalStorageSettings();
      }
    },

    openConfirmDeletePrintersGroupDialog() {
      this.$refs.confirmDeletePrinterGroupDialog.showDialog().then((result) => {
        if (result) {
          this.$store.dispatch('printerGroups/deletePrinterGroup', this.group.id).then(() => {
            this.$emit('deleted');
          });
        }
      });
    },

    startDragPrinter(evt, printer) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('printerId', printer.id);
      evt.dataTransfer.setData('printerGroupId', this.group.id);
    },

    onDropPrinter(evt) {
      let printerId = evt.dataTransfer.getData('printerId');
      let printerGroupId = evt.dataTransfer.getData('printerGroupId');

      this.isDraggingOverThis = false;

      if (printerId?.length > 0 && printerGroupId?.length > 0) {
        this.$emit('printerDropped', { printerId: printerId, sourcePrinterGroupId: printerGroupId });
      }
    },

    getLocalStorageSettings() {
      if (localStorage.getItem(this.settings.id)) {
        this.settings = JSON.parse(localStorage.getItem(this.settings.id));
      }
    },

    saveLocalStorageSettings() {
      localStorage.setItem(this.settings.id, JSON.stringify(this.settings));
    },

    validateBulkAction(revalidateOnly=false) {
      // revalidateOnly: means, that we only want to revalidate such item, that are selected and
      // let not selected items unselected; this is used when examining bulk action, just to make
      // sure printer state didn't changed in time before bulk action is executed
      this.group.printers.forEach((item) => {
        if (revalidateOnly && this.printersExtras[item.id].isSelected == false) {
          // prevent automatic selection of printers that are not selected by user on purpose
          return;
        }
        if (this.bulkActionName == 'TURN_OFF') {
          this.printersExtras[item.id].isSelected = this.$store.getters['printers/printerById'](item.id).canTurnOff();
        } else if (this.bulkActionName == 'TURN_ON') {
          this.printersExtras[item.id].isSelected = this.$store.getters['printers/printerById'](item.id).canTurnOn();
        } else {
          this.printersExtras[item.id].isSelected = false;
        }

        this.printersExtras[item.id].canBeSelected = this.printersExtras[item.id].isSelected;
      });
    },

    async doBulkAction() {
      this.validateBulkAction(true);

      for (let p of this.group.printers) {
        if (this.printersExtras[p.id].isSelected) {
          if (this.bulkActionName == 'TURN_OFF') {
            await this.$printersService.controls.switchPowerOff(p.id, p.name);
          } else if (this.bulkActionName == 'TURN_ON') {
            await this.$printersService.controls.switchPowerOn(p.id, p.name);
          }
          this.printersExtras[p.id].isSelected = false;
          this.printersExtras[p.id].canBeSelected = false;
        }
      }

      this.bulkActionName = '';
    }
  }
}
</script>
