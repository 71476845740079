<template>
  <div class="relative z-10">
    <div
      class="cursor-pointer px-1 flex border-b border-b-1 border-gray-500"
      :class="{ 'border-green-400 text-green-400': item }"
      @click="isShowed ? hide() : show()"
      :title="item?.name"
    >
      <div class="truncate">{{ item ? item.name : '-----' }}</div>
    </div>
    <div
      v-if="isShowed"
      class="absolute top-2 right-0"
      @mouseleave="hide()"
    >
      <transition name="menuTransition">
        <div class="border border-1 border-gray-200 shadow-md cursor-pointer bg-white-100 rounded max-w-[10rem]">
          <div class="hover:bg-gray-100 dark:hover:bg-gray-750 px-4 py-2 truncate" @click.stop="onItemClick(val)">-----</div>
          <div
            v-for="val in values"
            :key="val.id"
            class="hover:bg-gray-100 dark:hover:bg-gray-750 px-4 py-2 truncate"
            @click.stop="onItemClick(val)"
            :title="val.name"
          >{{ val.name }}</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.menuTransition-enter-active { @apply transition ease-out duration-200; }
.menuTransition-leave-active { @apply transition ease-in duration-75; }
.menuTransition-enter-from,
.menuTransition-leave-to { @apply transform opacity-0 scale-95; }
.menuTransition-enter-to,
.menuTransition-leave-from { @apply transform opacity-100 scale-100; }
</style>

<script>
export default {
  name: 'uiSimpleDropdown',

  emits: ['clicked'],

  components: {
  },

  props: {
    values: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    isShowed: false,
  }),

  methods: {
    show() {
      this.isShowed = true;
    },

    hide() {
      this.isShowed = false;
    },

    onItemClick(item) {
      this.hide();
      this.$emit('clicked', item)
    }
  }
}
</script>
