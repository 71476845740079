<template>
  <div v-if="!img" class="skeleton" :class="color">
    <slot></slot>
  </div>
  <template v-else>
    <div>
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABKADAAQAAAABAAAAAwAAAAB3TCISAAAAFklEQVQIHWOcPHmyFAMSYEJig5kYAgBZ+wHZQ7s/PwAAAABJRU5ErkJggg==" width="640" height="480" />
      <slot></slot>
    </div>
  </template>
</template>

<style scoped>
.skeleton {
  @apply relative overflow-hidden;
}

.skeleton.default {
  @apply bg-gray-400 bg-opacity-10;
}

.skeleton.white {
  @apply bg-white-100 dark:bg-gray-400 dark:bg-opacity-10;
}
</style>

<script>
export default {
  name: 'uiSkeleton',
  props: {
    img: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return (
          [
            'default',
            'white',
          ].indexOf(value) !== -1
        );
      },
    },
  }
};
</script>
