<template>
  <div v-if="$isMobile()" class="mb-24">
  </div>
  <footer v-else className="mt-20 mb-8 mx-4">
    <p class="text-sm text-gray-400 pt-5 text-center">
      &copy; 2024 <a href="https://karmen.tech/" class="text-gray-400">Karmen.tech</a> by <a href="https://fragaria.cz/" class="text-gray-400">Fragaria.cz</a>
    </p>
    <p class="text-sm text-gray-400 text-opacity-20 italic pt-5 text-center">
      environment: {{ env }}, version: {{ version }}, rev: {{ git_rev }}
    </p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',

  data: () => ({
    env: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    git_rev: process.env.VUE_APP_LAST_COMMIT_REV
  })
}
</script>
