export function printers (state) {
  return state.printers;
}

export function printerById (state) {
  return (id) => {
    for (let i = 0; i < state.printers.length; i++) {
      if (state.printers[i].id == id) {
        return state.printers[i];
      }
    }
    return null;
  }
}

export function printerHistoryById (state) {
  return (id) => {
    for (let i = 0; i < state.printers.length; i++) {
      if (state.printers[i].id == id) {
        return state.printers[i].printHistory;
      }
    }
    return null;
  }
}

export function allPrintersPrintHistory (state) {
  let allHistoryArray = [];
  for (let i = 0; i < state.printers.length; i++) {
    allHistoryArray = allHistoryArray.concat(state.printers[i].printHistory);
  }
  allHistoryArray = allHistoryArray.sort((a, b) => b.started_on > a.started_on ? 1: -1);
  return allHistoryArray;
}

export function printJobs (state) {
  return state.printJobs;
}
