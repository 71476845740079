/* eslint-disable no-console */

import { register } from 'register-service-worker';

import store from './store';
import { i18n } from '@/i18n.js';


if (process.env.NODE_ENV !== 'development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')

      store.commit('app/setAnnouncement', ({
        text: i18n.global.t('components.newVersionAnnouncement.text'),
        btnText: i18n.global.t('components.newVersionAnnouncement.btnText'),
        btnClickCallback: () => { window.location.reload(); }
      }));
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
