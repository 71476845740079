<template>
  <router-link
    :to="{ name: routeName }"
    class="block text-sm text-gray-700 dark:text-gray-400 font-semibold hover:underline"
    @click="trackClick"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'uiPageSectionBoxFollowLink',
  props: {
    routeName: {
      type: String,
      required: true
    },
    dataTitle: {
      type: String
    }
  },
  methods: {
    trackClick() {
      this.$gtag.event('click', {
        'event_category': 'Navigation',
        'event_label': 'Show More Link',
        'value': this.dataTitle,
      })
    },
  }
}
</script>
