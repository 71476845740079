<template>

  <!-- PrintJobs list -->
  <ui-page-section-box tight>

    <!-- No printjobs available -->
    <div v-if="!loading && gridItems.length == 0" class="px-6 py-4">
      <ui-alert>
        <div class="text-sm font-semibold dark:text-white-100">
          {{ $t('components.fileList.empty') }}
        </div>
      </ui-alert>
    </div>

    <!-- Grid with list -->
    <ui-grid v-if="!loading">

      <router-link
        :to="item.file_id != null ? { name: 'GcodeDetail', params: { id: item.file_id }} : ''"
        v-for="item of gridItems"
        :key="item.file_id"
      >
        <ui-grid-item>
          <ui-grid-item-col v-if="showPreviewImage">
            <ui-grid-item-col-content class="pr-4">
              <div class="w-24 h-24 border border-1 border-gray-400 dark:border-0 rounded-md">
                <gcode-preview-image
                  :gcodeId="item.file_id"
                  :previewExists="$filters.previewImageExists(item.previewImages)"
                  :previewImageId="$filters.primaryPreviewImage(item.previewImages)?.id"
                  :images="item.previewImages"
                  :isDeleted="item.file_id == null"
                  :allowInteractions="false"
                ></gcode-preview-image>
              </div>
            </ui-grid-item-col-content>
          </ui-grid-item-col>
          <ui-grid-item-col type="main">
            <ui-grid-item-col>
              <ui-grid-item-col-content>{{ $filters.dateTimeFormat(item.started_on) }}</ui-grid-item-col-content>
              <ui-grid-item-col-content type="text">
                <div class="flex flex-wrap gap-x-3">
                  <div><ui-icon name="user" class="h-4 w-4 inline mr-1" /> {{ item.username }}</div>
                  <div><ui-icon name="printer" class="h-4 w-4 inline mr-1" /> {{ item.printer_name }}</div>
                </div>
              </ui-grid-item-col-content>
              <ui-grid-item-col-content v-if="showFileName" type="heading" class="pt-2">{{ item.file_name }}</ui-grid-item-col-content>
            </ui-grid-item-col>
          </ui-grid-item-col>

          <!-- action context menu -->
          <ui-grid-item-col v-if="item.file_id && showContextMenu" type="right-action" class="block self-start">
            <ui-grid-item-col-content>
              <ui-button
                @click.stop.prevent="openPrintDialog(item)"
                small
                icon="refresh"
                data-title="Print job - print again button"
              >{{ $t('views.printQueue.btnPrintAgain') }}</ui-button>
            </ui-grid-item-col-content>
          </ui-grid-item-col>

        </ui-grid-item>
      </router-link>

    </ui-grid>

    <!-- Load next observer - load more items when scroll to the end of the grid -->
    <div
      v-if="
        enableLoadNext
        && (
          (this.printerId && this.$store.getters['printers/printerById'](this.printerId).printJobs.next)
          || (this.fileId && this.$store.getters['gcodes/printJobs'].next)
          || (!this.printerId && !this.fileId && this.$store.getters['gcodes/printJobs'].next)
        )
        && !this.loading"
      class="text-center py-8"
    >
      <intersection-observer
        sentinal-name="end-of-grid-reached"
        @on-intersection-element="loadNext()"
      ></intersection-observer>
      <ui-button
        @click="loadNext()"
        color="primary"
        class="mt-2"
        small
        :loading="loadingNext"
      >
        {{ $t('components.fileList.more') }}
      </ui-button>
    </div>

    <!-- Loading skeleton -->
    <ui-grid v-if="loading">
      <ui-grid-item v-for="index in 10" v-bind:key="index" :loading="true">
        <ui-grid-item-col type="main">
          <ui-grid-item-col>
            <ui-grid-item-col-content type="heading">
              <ui-skeleton class="h-5 w-72" />
            </ui-grid-item-col-content>
            <ui-grid-item-col-content>
              <ui-skeleton class="h-3.5 w-20 mt-1" />
            </ui-grid-item-col-content>
          </ui-grid-item-col>
          <ui-grid-item-col type="right">
            <ui-grid-item-col>
              <ui-grid-item-col-content>
                <ui-skeleton class="h-3 w-20 mb-1" />
              </ui-grid-item-col-content>
              <ui-grid-item-col-content>
                <ui-skeleton class="h-3 w-12 mb-1 sm:float-right" />
              </ui-grid-item-col-content>
            </ui-grid-item-col>
          </ui-grid-item-col>
        </ui-grid-item-col>
        <ui-grid-item-col type="right-action" class="block self-start">
          <ui-grid-item-col>
            <ui-skeleton class="h-9 w-9 block" />
          </ui-grid-item-col>
        </ui-grid-item-col>
      </ui-grid-item>
    </ui-grid>
  </ui-page-section-box>

</template>

<script>
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';

export default {
  name: 'PrintJobsList',

  components: {
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiSkeleton,
    uiButton,
    uiIcon,
    uiAlert,
    uiPageSectionBox,
    GcodePreviewImage,
    IntersectionObserver
  },

  props: {
    printerId: {
      type: String,
      default: null
    },
    fileId: {
      type: String,
      default: null
    },
    showPreviewImage: {
      type: Boolean,
      default: false
    },
    showFileName: {
      type: Boolean,
      default: true
    },
    showContextMenu: {
      type: Boolean,
      default: true
    },
    enableLoadNext: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    loading: false,
    loadingNext: false,
    gridItems: [],
    selectedContextMenuItem: null
  }),

  created: function() {
    this.reload();
  },

  methods: {
    async reload() {
      if (! this.loading) {
        this.loading = true;
        this.loadingNext = true;
        this.gridItems = [];

        if (this.printerId) {
          await this.$store.dispatch('printers/loadPrintHistory', { printerId: this.printerId });
          this.gridItems = this.$store.getters['printers/printerById'](this.printerId).printJobs.results;
        } else if (this.fileId) {
          await this.$store.dispatch('gcodes/loadPrintJobsByFileId', { fileId: this.fileId });
          this.gridItems = this.$store.getters['gcodes/printJobs'].results;          
        } else {
          await this.$store.dispatch('gcodes/loadPrintJobs');
          this.gridItems = this.$store.getters['gcodes/printJobs'].results;          
        }

        this.loading = false;

        // prevent loading next before regular items are visible to not fire observer event
        setTimeout(() => {
          this.loadingNext = false;
        }, 300);
      }
    },

    async loadNext() {
      if (! this.loadingNext && ! this.loading) {
        this.loadingNext = true;

        // Currently is "load next" implemented only for printer printjob history
        if (this.printerId) {
          if (this.$store.getters['printers/printerById'](this.printerId).printJobs.next) {
            await this.$store.dispatch('printers/loadPrintHistoryNext', { url: this.$store.getters['printers/printerById'](this.printerId).printJobs.next, printerId: this.printerId });
            this.gridItems = this.$store.getters['printers/printerById'](this.printerId).printJobs.results;
          }
        }

        // prevent to fire observer event multiple times in one case/scenario
        setTimeout(() => {
          this.loadingNext = false;
        }, 300);
      }
    },

    showItemContextMenu(item) {
      if (!item || this.selectedContextMenuItem?.id == item?.id) {
        this.selectedContextMenuItem = null;
      } else {
        this.selectedContextMenuItem = item;
      }
    },

    openPrintDialog(item) {
      this.showItemContextMenu(null);  // hide opened context menu
      this.$printDialogService.showPrintDialog({ gcodeId: item.file_id, printerId: item.printer_id });
      this.$printDialogService.setOnDialogEventCallback((e) => {
        if (e == 'started') {
          this.reload();
        } else if (e == 'queued-up') {
          this.$router.push({ name: 'Dashboard' });
        }
      });
    }
  }
}
</script>
