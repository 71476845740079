<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <div class="sm:block">
      <nav class="ml-1 space-x-1 flex">
        <slot></slot>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uiNavigationUnderlineTabs'
}
</script>
