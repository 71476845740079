<template>
  <transition name="layoutTransition" persisted>
    <div v-show="true">
      <ui-alert color="danger" center>{{ text }}</ui-alert>
    </div>
  </transition>
</template>

<style scoped>
.layoutTransition-enter-active,
.layoutTransition-leave-active { @apply transform transition ease-in duration-300; }
.layoutTransition-enter-from,
.layoutTransition-leave-to { @apply opacity-0; }
.layoutTransition-enter-to,
.layoutTransition-leave-from { @apply opacity-100; }
</style>

<script>
import uiAlert from '@/components/ui/uiAlert.vue';

export default {
  name: 'uiFormError',

  components: {
    uiAlert
  },

  props: {
    show: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    }
  }
}
</script>
