<template>
  <div :class="type">
    <slot></slot>
  </div>
</template>

<style scoped>
  .checkbox {
    @apply pr-4;
  }
  .heading {
    @apply flex font-medium break-all text-gray-900 dark:text-gray-300;
  }
  .text {
    @apply mt-0.5 flex font-normal text-xs text-gray-700 dark:text-gray-400;
  }
</style>

<script>
export default {
  name: 'uiGridItemColContent',

  props: {
    type: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'checkbox', 'heading', 'text'].indexOf(value) !== -1;
      }
    }
  }
}
</script>
