<template>
  <add-printer-selector-modal
    :show="showAddPrinterDialog"
    :groupId="selectedGroupId"
    @close="showAddPrinterDialog=false"
    >
  </add-printer-selector-modal>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>
        {{ $t('views.settings.printers') }}
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>

      <ui-page-section-box v-if="loading">
        <ui-loader />
      </ui-page-section-box>

      <div v-if="!loading" class="space-y-8">
        <ui-page-section-box
          tight
          v-for="section in groups"
          :key="section.id"
        >
          <ui-heading secondary class="pl-4 sm:px-6">
            {{ section.name }}
            <template v-slot:actionButton v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP, section.id)">
              <div>
                <ui-button
                  v-if="!loading && !this.$isMobile()"
                  @click="this.openAddSelector(section.id)"
                  color="primary"
                  small
                >
                  {{ $t('views.settings.printerAdd') }}
                </ui-button>

                <ui-button
                  v-if="!loading && this.$isMobile()"
                  color="clear"
                  @click="this.openAddSelector(section.id)"
                  :data-title="$t('views.settings.printerAdd')"
                >
                  <ui-icon name="plus" class="h-5 w-5 inline-block text-red-400" />
                </ui-button>
              </div>
            </template>
          </ui-heading>
          <ui-grid>
            <ui-alert
              v-if="section.printers?.length == 0"
              :headerText="$t('views.settings.printerNone')"
            ></ui-alert>

            <ui-grid-item
              v-for="( item ) in section.printers"
              :key="item.id"
            >

              <!-- item name -->
              <ui-grid-item-col type="main" @click.stop="editItem(item, section)">
                <ui-grid-item-col>
                  <ui-grid-item-col-content type="heading">{{ item.name }}</ui-grid-item-col-content>
                  <ui-grid-item-col-content type="text">{{ item.note }}</ui-grid-item-col-content>
                </ui-grid-item-col>
              </ui-grid-item-col>

              <!-- action button - edit -->
              <ui-grid-item-col type="right-action" v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP, section.id)">
                <ui-grid-item-col-content>
                  <ui-button @click.stop="editItem(item, section)" small>
                    {{ $t('views.settings.edit') }}
                  </ui-button>
                </ui-grid-item-col-content>
              </ui-grid-item-col>

            </ui-grid-item>
          </ui-grid>
        </ui-page-section-box>
      </div>
    </template>
  </authorized-col-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import AddPrinterSelectorModal from '@/components/AddPrinterSelectorModal.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'SettingsPrinters',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton,
    uiLoader,
    uiAlert,
    AddPrinterSelectorModal,
    SettingsNavigation,
    uiIcon
  },

  data: () => ({
    items: [],
    itemId: null,
    loading: true,
    showGroupsFilter: false,
    groups: [],
    showAddPrinterDialog: false,
    selectedGroupId: null,
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('account', { loadGroups: 'groups' }),
    ...mapGetters('account', { getGroups: 'groups' }),

    async init() {
      this.loading = true;
      await this.loadGroups();
      this.groups = this.getGroups();
      this.loading = false;
    },

    editItem(item, group) {
      if (this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP, group.id)) {
        this.$router.push({ name: 'SettingsPrinterEdit', params: { id: item.id, groupId: group.id } });
      }
    },

    openAddSelector(groupId) {
      this.selectedGroupId = groupId;
      this.showAddPrinterDialog = true;
    },
  }
}
</script>
