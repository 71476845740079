<template>
  <ui-modal
    ref="dialog"
    :show="show"
    data-title="PrintQueueItemDialog"
    @close="cancel()"
    :title="isCreating ? $t('views.printQueue.dialogAddGcodeTitle') : fileName"
  >
    <!-- SAVING / LOADING -->
    <div v-if="current_dialog_state == DIALOG_STATES.SAVING || current_dialog_state == DIALOG_STATES.LOADING" class="flex flex-col justify-center w-full h-60 my-8 dark:bg-gray-900">
      <ui-loader />
      <div v-if="current_dialog_state == DIALOG_STATES.SAVING" class="pt-2 text-center text-xs">{{ $t('views.printQueue.dialogSavingDataText') }}</div>
    </div>

    <!-- DATA LOADED -->
    <div v-if="current_dialog_state == DIALOG_STATES.OPENED">
      <div class="">

        <!-- EDITING EXISTING ITEM -->
        <ui-description-list v-if="!isCreating">
          <ui-description-list-item>
            <ui-description-list-term>{{ $t('views.printQueue.dialogAddedOnTitle') }}</ui-description-list-term>
            <ui-description-list-definition>{{ $filters.dateTimeFormat(item.added_on) }}</ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>{{ $t('views.printQueue.dialogAddedByTitle') }}</ui-description-list-term>
            <ui-description-list-definition>{{ item.added_by.username }}</ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>{{ $t('views.printQueue.dialogPrinterTitle') }}</ui-description-list-term>
            <ui-description-list-definition>
              <ui-form-select
                v-model:value="printerId"
                :values="printersList"
                id="printer"
                :allowEmpty="true"
                :disabled="item.state != 'to-be-printed'"
              />
              <div v-if="showJoboxSettings">
                <ui-button-switch
                  v-if="item.state == 'to-be-printed'"
                  v-model:value="joboxAutostart"
                  text="Start printjob automatically"
                  id="joboxAutostart"
                ></ui-button-switch>
                <div v-else-if="item.jobox_autostart" class="mt-1">
                  <ui-badge color="success" class="mb-1">{{ $t('views.printQueue.dialogStartPrintjobAutomaticallyTitle') }}</ui-badge>
                </div>
              </div>
            </ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>{{ $t('views.printQueue.dialogStateTitle') }}</ui-description-list-term>
            <ui-description-list-definition>{{ displayStateName.title }}</ui-description-list-definition>
          </ui-description-list-item>
        </ui-description-list>

        <!-- CREATING NEW ITEM -->
        <div v-else class="space-y-4">
          <ui-form-input
            disabled
            v-model:value="fileName"
            labelText="Gcode"
            id="info-file-name"
          />
          <ui-form-select
            v-model:value="printerId"
            :values="printersList"
            labelText="Printer"
            id="printer"
            :allowEmpty="true"
          />
          <ui-button-switch
            v-if="showJoboxSettings"
            v-model:value="joboxAutostart"
            text="Start printjob automatically"
            id="joboxAutostart"
          ></ui-button-switch>
        </div>

      </div>

      <div class="mt-8 space-x-2 text-center">
        <ui-button @click="cancel()" color="default">
          {{ $t('views.printQueue.dialogBtnCancel') }}
        </ui-button>
        <ui-button @click="save()" color="primary">
          {{ $t('views.printQueue.dialogBtnSave') }}
        </ui-button>
      </div>
    </div>

    <!-- SAVED -->
    <div v-if="current_dialog_state == DIALOG_STATES.SAVED && showAfterSaveStatus">
      <div class="mt-8 my-3">
        {{ $t('views.printQueue.dialogItemAddedText') }}
      </div>

      <div class="mt-8 space-x-2 text-center">
        <ui-button @click="goToPrintQueue()" color="default">
          {{ $t('views.printQueue.dialogBtnSeePrintQueue') }}
        </ui-button>
        <ui-button @click="close()" color="primary">
          {{ $t('views.printQueue.dialogBtnOk') }}
        </ui-button>
      </div>
    </div>

  </ui-modal>
</template>

<script>
import uiModal from '@/components/ui/uiModal.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiButtonSwitch from '@/components/ui/uiButtonSwitch.vue';
import uiDescriptionList from '@/components/ui/uiDescriptionList.vue';
import uiDescriptionListItem from '@/components/ui/uiDescriptionListItem.vue';
import uiDescriptionListTerm from '@/components/ui/uiDescriptionListTerm.vue';
import uiDescriptionListDefinition from '@/components/ui/uiDescriptionListDefinition.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import { KarmenError } from '@/errors';

export default {
  name: 'PrintQueueItemDialog',

  components: {
    uiModal,
    uiButton,
    uiFormSelect,
    uiFormInput,
    uiLoader,
    uiButtonSwitch,
    uiDescriptionList,
    uiDescriptionListItem,
    uiDescriptionListTerm,
    uiDescriptionListDefinition,
    uiBadge
  },

  emits: [
    'canceled',
    'saved'
  ],

  props: {
    show: {
      type: Boolean,
      default: false
    },
    printQueueItem: {
      type: Object,
      default: null,
      required: false
    },
    gcodeId: {
      type: String,
      default: null,
      required: false
    },
    preselectedPrinterId: {
      type: String,
      default: null,
      required: false
    },
    showAfterSaveStatus: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.init();
      }
    },
    printerId(val) {
      let printer = this.$store.getters['printers/printerById'](val);
      if (printer && printer.joboxEnabled) {
        this.showJoboxSettings = true;
      } else {
        this.showJoboxSettings = false;
        this.joboxAutostart = false;
      }
    }
  },

  data: () => ({
    printersList: [],
    item: null,
    stateId: null,
    printerId: null,
    fileName: null,
    joboxAutostart: false,
    displayStateName: null,

    isCreating: false,  // indicates, whether new item is being created or updating existing one

    DIALOG_STATES: {
      LOADING: 'loading',
      OPENED: 'opened',
      SAVING: 'saving',
      SAVED: 'saved'
    },
    current_dialog_state: null,

    showJoboxSettings: true
  }),

  methods: {
    async init() {
      // reset values in case of repeatedly opened dialog
      this.current_dialog_state = this.DIALOG_STATES.LOADING;
      this.item = {};
      this.printersList = [];
      this.stateId = null;
      this.printerId = null;
      this.showJoboxSettings = false;
      this.joboxAutostart = false;

      // load and create priters list for form select
      await this.$store.dispatch('printers/loadPrinters');
      let printers = await this.$store.getters['printers/printers'];
      for (let printer of printers) {
        if (printer.enabled) {
          this.printersList.push({id: printer.id, name: printer.name});
        }
      }

      // when existing item provided
      if (this.printQueueItem) {
        this.isCreating = false;
        this.item = this.printQueueItem;
        this.stateId = this.printQueueItem.state;
        this.printerId = this.printQueueItem.printer?.id;
        this.fileName = this.printQueueItem.file.name;
        this.joboxAutostart = this.printQueueItem.jobox_autostart;
        this.showJoboxSettings = this.printQueueItem.printer?.jobox_enabled;
        this.displayStateName = this.$store.getters['printQueue/printQueueStateById'](this.stateId);

      // when creating new print queue item
      } else if (this.gcodeId) {
        this.isCreating = true;
        this.stateId = 'to-be-printed';
        // load file detail from provided gcodeId - we want to show file detail to user
        await this.$store.dispatch('gcodes/loadGcodeDetail', {id: this.gcodeId});
        this.item.file = this.$store.getters['gcodes/gcodeDetail'];
        this.fileName = this.item.file.name;
        this.printerId = this.preselectedPrinterId;

      } else {
        this.cancel();
        throw new KarmenError(
          'Data error',
          'Invalid data were provided when openning PrintQueueItemDialog.'
        );
      }

      this.current_dialog_state = this.DIALOG_STATES.OPENED;
    },

    // call this when data not to be saved
    cancel() {
      this.$emit('canceled');
    },

    // call this after data are saved
    close() {
      this.$emit('saved');
    },

    goToPrintQueue() {
      this.$router.push({ name: 'PrintQueue' });
    },

    async save() {
      this.current_dialog_state = this.DIALOG_STATES.SAVING;
      if (this.item.id) {  // updating
        await this.$store.dispatch('printQueue/patchItem', {id: this.item.id, state: this.stateId, printerId: this.printerId, joboxAutostart: this.joboxAutostart});
      } else {  // creating
        await this.$store.dispatch('printQueue/createItem', {gcodeId: this.item.file.id, printerId: this.printerId, joboxAutostart: this.joboxAutostart});
      }
      this.current_dialog_state = this.DIALOG_STATES.SAVED;

      if (! this.showAfterSaveStatus) {
        this.close();
      }
    }
  }
}
</script>
