<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>
        Bulk update users in workspace: {{ group?.name }}
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>

      <ui-page-section-box v-if="loading">
        <ui-loader />

        <div v-if="sendingInvitations">
          <p v-html="sendingLog" class="text-xs m-2 p-2 border border-gray-200 dark:border-gray-500"></p>
        </div>
      </ui-page-section-box>

      <div v-else>

        <!-- textarea for emails -->
        <ui-page-section-box v-if="PHASE == 0">

          <div class="text-sm">
            Use this feature to perform a bulk update of users in your workspace. Please enter your users' email addresses in the text field below, placing each email address on a new line. In the next step, you will see the differences between the data you've supplied and the data in Karmen.
          </div>

          <div class="flex mt-8">
            <div class="flex-none py-2 w-auto text-right text-sm leading-5 text-white-100 font-bold font-mono">
              <div v-for="(row, i) in importParsedData" :key="i">
                <div v-if="row.state" class="w-full bg-green-400 px-2">{{ i+1 }}</div>
                <div v-else class="w-full bg-red-400 px-2">{{ i+1 }}</div>
              </div>
            </div>
            <textarea
              v-model="importSource"
              id="importSource"
              :rows="importParsedData.length <= 5 ? 5 : importParsedData.length"
              class="flex-1 bg-white-100 dark:bg-gray-900 dark:text-gray-300 focus:outline-none focus:ring-gray-400 focus:border-transparent leading-5 text-sm overflow-hidden resize-none font-mono"
              placeholder="user-one@example-email.com&#10;user-two@example-email.com&#10;user-three@example-email.com&#10;..."
            ></textarea>
          </div>
          <ui-button
            @click.stop="importSource = '';"
            color="default"
            small
            class="float-right mt-2"
          >
            Clear data
          </ui-button>

          <ui-alert
            v-if="importInvalidRows.length > 0"
            color="danger"
            class="-mx-6 mt-8 -mb-1 px-6 py-2 rounded-none text-sm"
          >
            There are {{ importInvalidRows.length }} errors in provided data. Please fix the data before continuing.
          </ui-alert>

          <div class="pt-8">
            <ui-button @click.stop="analyzeData()" color="primary" :disabled="importInvalidRows.length > 0 || importParsedData.length == 0">
              Continue
            </ui-button>
          </div>

        </ui-page-section-box>

        <!-- summary and list of actions -->
        <div v-else-if="PHASE == 1">

          <div class="pt-8">
            <ui-button @click.stop="PHASE = 0" color="primary" :disabled="importInvalidRows.length > 0 || importParsedData.length == 0">
              Go back
            </ui-button>
          </div>

          <!-- summary -->
          <ui-page-section-box class="border-l-2 border-red-400 relative mt-8">
            <div class="border-b border-gray-200 dark:border-gray-500 px-4 py-5 sm:px-6">
              <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-2">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">Summary report</h3>
                  <p class="mt-1 text-sm text-gray-500">We have analyzed the list of email addresses you provided with records of users in Karmen.</p>
                </div>
              </div>
            </div>
            <div class="p-6 mt-1 text-sm text-gray-500">
              <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">Results:</h3>
              <ul class="list-disc ml-10 mt-4">
                <li>{{ importUniqueItems.length }} unique email addresses provided</li>
                <li>{{ usersExisting.length }} users already registered in your workspace</li>
                <li>{{ usersNew.length }} new email addresses in provided data ready for invitation</li>
                <li>{{ usersInvited.length }} users already invited, but registration is not finished</li>
                <li>{{ usersDeleted.length }} users registered in your workspace are not in data you provided</li>
              </ul>
            </div>
          </ui-page-section-box>

          <!-- users to be invited -->
          <ui-page-section-box class="border-l-2 border-red-400 relative mt-8">
            <div class="border-b border-gray-200 dark:border-gray-500 px-4 py-5 sm:px-6">
              <ui-button @click.stop="inviteAllUsers()" color="default" class="float-right" :disabled="usersNew.length == 0">Invite all</ui-button>
              <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-2">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">Non-existing users / to be invited</h3>
                  <p class="mt-1 text-sm text-gray-500">These users have been in list you provided, but are not part of your workspace in Karmen yet. You can invite these users now.</p>
                </div>
              </div>
            </div>
            <div class="p-6 mt-1 text-sm text-gray-500">
              <div v-if="usersNew.length == 0">--- no users to be invited ---</div>
              <div v-else>
                <div class="flex flex-wrap">
                  <div
                    v-for="u in usersNew"
                    :key="u.email"
                    class="border border-1 ml-2 mt-2 p-2 rounded-md"
                  >
                    {{ u.email }}
                    <ui-button @click.stop="inviteUser(u.email)" color="default" small class="ml-2">Invite</ui-button>
                  </div>
                </div>
              </div>

              <div v-if="usersInvited.length > 0" class="mt-16 border-t border-t-1 pt-4">
                <h3 class="text-sm font-semibold leading-7 text-gray-900 dark:text-gray-300">Already invited:</h3>
                <div class="italic">These users are already invited and can't be invited again until their invitation will expire.</div>
                <div class="flex flex-wrap mt-2">
                  <div
                    v-for="u in usersInvited"
                    :key="u.email"
                    class="p-2 ml-2 mt-2 text-xs border border-1 italic rounded-md"
                  >
                    {{ u.email }}
                  </div>
                </div>
              </div>
            </div>
          </ui-page-section-box>

          <!-- users to be deleted -->
          <ui-page-section-box class="border-l-2 border-red-400 relative mt-8">
            <div class="border-b border-gray-200 dark:border-gray-500 px-4 py-5 sm:px-6">
              <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-2">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">Missing users / to be deleted</h3>
                  <p class="mt-1 text-sm text-gray-500">These users are in Karmen, but was not provided in your data. You should consider whether these users should be removed from your workspace.</p>
                </div>
              </div>
            </div>
            <div class="p-6 mt-1 text-sm text-gray-500">
              <div v-if="usersDeleted.length == 0">--- no missing users for delete ---</div>
              <div v-else class="flex flex-wrap">
                <div
                  v-for="u in usersDeleted"
                  :key="u.email"
                  class="border border-1 p-2 ml-2 mt-2 rounded-md"
                >
                  {{ u.email }} <span class="italic text-xs">({{ u.role }})</span>
                </div>
              </div>
            </div>
          </ui-page-section-box>

        </div>

      </div>

      <!-- invite all users dialog -->
      <ui-confirm-dialog
        ref="confirmInviteAllUsersDialog"
        headerText="Confirm users invitation"
        confirmBtnText="Yes, invite"
        dismissBtnText="Cancel"
        :autoCloseOnConfirm="true"
      >
        <p class="mb-8">
          <strong>Do you really want to invite all new users to your workspace?</strong>
        </p>
      </ui-confirm-dialog>


    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';

export default {
  name: 'SettingsWorkspaceInviteUsers',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiButton,
    uiLoader,
    uiAlert,
    uiConfirmDialog,
    SettingsNavigation
},

  props: {
    id: {
      type: String,
      default: null,
      required: false
    }
  },

  watch: {
    importSource(newVal) {
      let lines = newVal.split('\n');
      this.importParsedData = [];
      this.importInvalidRows = [];

      for (const [i, line] of lines.entries()) {
        let str = String(line)
          .toLowerCase() // lowercase
          .trimStart() // remove whitespaces from start
          .trimEnd(); // remove whitespaces from end
        
        // check whether value is valid email string
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/
        let isValid = !(str.match(regex) == null);

        this.importParsedData.push({ email: str, state: isValid });

        // store invalid rows for reference and UX/UI usage
        if (!isValid) {
          this.importInvalidRows.push(i);
        }
      }

      if (this.importParsedData.length == 1 && this.importParsedData[0].email == '') {
        this.importParsedData = [];
        this.importInvalidRows = [];
      }
    }
  },

  data: () => ({
    PHASE: 0,

    group: null,
    loading: true,

    textAreaLines: [],
    importSource: null,
    importParsedData: [],
    importInvalidRows: [],
    importUniqueItems: [],

    usersExisting: [],
    usersNew: [],
    usersInvited: [],
    usersDeleted: [],

    sendingInvitations: false,
    sendingLog: null
  }),

  created: function() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      this.sendingInvitations = false;
      this.sendingLog = null;

      await this.$store.dispatch('account/groups');
      this.group = this.$store.getters['account/groupById'](this.id)

      // group does not exists (did user modified ID in URL?)
      if (this.group && this.group.id) {
        this.loading = false;
      } else {
        this.$store.commit('app/setNotification', ({
          title: 'Workspace does not exists',
          text: 'Can\'t find requested workspace with ID: ' + this.id,
          onClose: () => {
            this.$router.push({ name: 'SettingsWorkspaces' })
          }
        }));
      }
    },

    async analyzeData() {

      this.loading = true;

      // show loading circle for a while, just for better user experience to realise something is happening
      await new Promise(resolve => setTimeout(resolve, 1000));

      // reset data
      this.usersExisting = [];
      this.usersNew = [];
      this.usersInvited = [];
      this.usersDeleted = [];
      this.importUniqueItems = [];

      // let's make sure, we work only with unique data, so same emails are not more than once in the list
      for (let item of this.importParsedData) {
        if (! this.importUniqueItems.find((e) => { return e.email == item.email; })) {
          this.importUniqueItems.push(item);
        }
      }

      // only continue if there are no invalid rows (should not be because continue button would be disabled otherwise)
      if (this.importInvalidRows.length == 0) {

        // check whether importing users are already in karmen somehow
        for (const row of this.importUniqueItems) {
          let exists = this.group.users.find((e) => {
            return (e.email == row.email);
          })
          if (exists) {
            this.usersExisting.push(exists);
          } else {
            // check whether user is already invited
            if (this.group.pendingInvitations.find((e) => { return (e.email == row.email); })) {
              this.usersInvited.push(row);
            } else {
              this.usersNew.push(row);
            }
          }
        }

        // check for existing users, that are not in the import list
        for (const row of this.group.users) {
          let exists = this.importUniqueItems.find((e) => {
            return (e.email == row.email);
          })
          if (exists) {
            // ok
          } else {
            this.usersDeleted.push(row);
          }
        }

        // scroll page to top, so window is not scrolled bottom when list of users is really big
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.PHASE = 1;
        this.loading = false;
      }
    },

    async inviteAllUsers() {

      let result = await this.$refs.confirmInviteAllUsersDialog.showDialog();
      if (result) {
        this.loading = true;
        this.sendingInvitations = true;

        this.pushTextToInvitationLog('Starting to send invitations...');

        for (const [i, user] of this.usersNew.entries()) {
          this.pushTextToInvitationLog(i+1 + '/' + this.usersNew.length + ' - sending invitation to ' + user.email);
          await this.sendInvitation(user.email);
          await new Promise(resolve => setTimeout(resolve, 100)); // let's slowdown a little not to overload sending API
        }

        await this.init();
        await this.analyzeData();
        this.loading = false;
        this.sendingInvitations = false;
      }
    },

    pushTextToInvitationLog(message) {
      if (!this.sendingLog) {
        this.sendingLog = '';
      }
      this.sendingLog += message + '<br/>';
    },

    async inviteUser(email) {
      this.loading = true;
      await this.sendInvitation(email);
      await this.init();
      await this.analyzeData();
      this.loading = false;
    },

    async sendInvitation(email) {
      await this.$store.dispatch('account/inviteUserToGroup', { groupId: this.group.id, email: email });
    }
  }
}
</script>
