<template>
  <div v-if="!loading && videosItems && videosItems.length === 0">
    <ui-alert color="primary">
      <div class="text-sm font-semibold dark:text-white-100">
        {{ $t('views.timelapseVideos.listEmpty') }}
      </div>
      <p class="text-sm font-regular max-w-lg">
        {{ $t('views.timelapseVideos.listEmptySubtitle') }}
      </p>
    </ui-alert>
  </div>

  <ui-confirm-dialog
    ref="confirmDeleteDialog"
    :headerText="$t('views.timelapseVideos.confimDeleteDialogTitle')"
    :confirmBtnText="$t('components.modal.delete')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>
      {{ $t('views.timelapseVideos.confimDeleteDialogMessage') }}
    </p>
  </ui-confirm-dialog>

  <div
    v-if="!loading"
    class="grid gap-4 sm:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4"
  >
    <div
      v-for="item of videosItems"
      :key="item.id"
      @click.stop="selectable ? onItemSelect(item) : goToDetail(item.id);"
      class="cursor-pointer"
    >
      <div class="w-full aspect-4/3 overflow-hidden bg-gray-400 bg-opacity-10 relative">

        <!-- item selected (to delete) "checkbox" -->
        <input
          v-if="selectable"
          v-model="item._isSelected"
          type="checkbox"
          class="
            absolute top-2 left-2 z-10
            p-2.5
            border border-gray-200 dark:border-gray-700 rounded-sm
            text-gray-700
            focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-red-400
            cursor-pointer
          "
          :class="item._isSelected ? 'bg-red-400 dark:bg-red-400 focus:bg-red-400 dark:focus:bg-red-400' : 'bg-white-100 dark:bg-gray-900'"
        >

        <video-preview-widget
          :videoId="item.id"
          :imgCount="20"
          :show="item.photoStripe"
        ></video-preview-widget>
      </div>
      <div class="mt-2 dark:text-gray-300 break-all font-semibold text-sm leading-tight">
        {{ item.name }}
      </div>
      <div class="mt-1 text-2xs">
        {{ $t('views.timelapseVideos.filename') }}: {{ item.file.name }}
      </div>
      <div class="text-2xs">
        {{ $t('views.timelapseVideos.printer') }}: {{ item.print_job.printer_name }}
      </div>
      <div class="text-2xs">
        <time :datetime="item.date">{{ $filters.humanDateFormat(item.created_on) }}</time>
      </div>
      <ui-badge v-if="item.is_public" color="success">{{ $t('views.timelapseVideos.isPublic') }}</ui-badge>
    </div>
  </div>

  <div class="grid gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4" v-else-if="loading">
    <div v-for="index in skeletonSize" v-bind:key="index">
      <ui-skeleton class="w-full aspect-4/3" />
      <ui-skeleton class="h-5 w-1/2 mt-2" />
      <ui-skeleton class="h-2 w-3/4 mt-2" />
      <ui-skeleton class="h-2 w-1/4 mt-1" />
      <ui-skeleton class="h-2 w-1/3 mt-1" />
    </div>
  </div>

</template>

<script>
import uiAlert from '@/components/ui/uiAlert.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import VideoPreviewWidget from '@/components/VideoPreviewWidget.vue';

export default {
  name: 'VideoList',

  components: {
    uiAlert,
    uiBadge,
    uiSkeleton,
    uiConfirmDialog,
    VideoPreviewWidget,
  },

  emits: ['videosDeleted', 'deletingVideos', 'isSelectedVideosChanged'],

  props: {
    videos: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },

  watch:{
    videos (){
      this.videosItems = structuredClone(this.videos);
    }
  },

  data: () => ({
    videosItems: [],
    skeletonSize: 12,
    isVideosSelected: false
  }),

  methods: {
    goToDetail(id) {
      this.$router.push({ name: 'TimelapseVideoDetail', params: { id: id } });
    },

    onItemSelect(item) {
      if (item) {
        item._isSelected = !item._isSelected;
      }
      if (this.videosItems.filter(item => item._isSelected).length > 0) {
        this.isVideosSelected = true;
      } else {
        this.isVideosSelected = false;
      }
      this.$emit('isSelectedVideosChanged', this.isVideosSelected);
    },

    async deleteSelectedVideos() {
      let result = await this.$refs.confirmDeleteDialog.showDialog();
      if (result) {
        this.$emit('deletingVideos');
        for (let i = 0; i < this.videosItems.length; i++) {
          let item = this.videosItems[i];
          if (item._isSelected) {
            await this.$store.dispatch('gcodes/deleteVideo', {id: item.id});
          }
        }
        this.$emit('videosDeleted');
        this.onItemSelect();
      }
    },

    clearSelection() {
      this.videosItems.forEach((item) => {
        if (item._isSelected) {
          this.onItemSelect(item);
        }
      })
    },
  }
}
</script>
