<template>
  <div class="md:flex">
    <div class="
      hidden md:flex items-center content-center justify-center
      h-44 lg:h-64
      aspect-4/3
      bg-gray-600/25
    ">
      <ui-icon :name="icon" class="w-20 h-20 md:w-24 md:h-24 text-gray-900" />
    </div>

    <div class="p-6 md:p-0 md:pl-8">
      <h1 class="mb-1 font-semibold text-xl md:text-2xl text-black-900 dark:text-white-100">
        {{ title }}
      </h1>

      <p class="max-w-lg text-sm">
        {{ subtitle }}
      </p>

      <div class="flex mt-8">
        <span v-if="order" class="text-big">{{ order }}</span>

        <div>
          <p class="text-black-900 dark:text-white-100 font-semibold text-sm">
            {{ ctaTitle }}
          </p>

          <p class="max-w-md text-sm pb-6">
            {{ ctaSubtitle }}
          </p>

          <slot name="cta"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.text-big {
  @apply
    mr-4 md:mr-6
    font-bold
    text-5xl md:text-7xl text-black-900 dark:text-white-100;

  line-height: .8;
}
</style>


<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'EmptyState',
  components: {
    uiIcon
  },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    order: {
      type: Number
    },
    ctaTitle: {
      type: String
    },
    ctaSubtitle: {
      type: String
    },
  }
}
</script>
