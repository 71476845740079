<template>
  <ui-modal :show="show" @close="close()" data-title="NotificationModal" :title="normalizedNotification.title">
    <div class="sm:flex sm:items-start">
      <div class="text-center sm:mt-0 sm:text-left w-full text-sm text-gray-700 dark:text-gray-400">
        {{ normalizedNotification.text }}
        <slot></slot>
      </div>
    </div>
    <div class="mt-5 sm:mt-10 flex justify-center ml-4" v-if="normalizedNotification.dismissable">
      <ui-button @click.stop="close()" color="primary" class="">{{ $t('components.modal.close') }}</ui-button>
    </div>
  </ui-modal>
</template>

<script>
import uiModal from '@/components/ui/uiModal.vue';
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'NotificationModal',

  components: {
    uiModal,
    uiButton
  },

  emits: ['close'],

  props: {
    notification: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    normalizedNotification() {
      return Object.assign({
        title: '',
        text: '',
        dismissable: true,
        onClose: null
      }, this.notification)
    }
  },

  methods: {
    close() {
      if (this.normalizedNotification.onClose) {
        this.normalizedNotification.onClose();
      }
      if (this.normalizedNotification.dismissable) {
        this.$emit('close');
      }
    }
  }
}
</script>
