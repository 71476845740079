<template>
  <unauthorized-layout
    :headerText="$t('views.login.title')"
    :loading="loading"
    :showError="showError"
    :errorHeaderText="errorHeaderText"
    class="pt-8"
  >
    <ui-form class="px-8 pb-8">
      <ui-form-input
        v-model:value="username"
        :labelText="$t('views.login.inputUsername')"
        id="username"
        type="email"
        :required="true"
      ></ui-form-input>
      <ui-form-input
        v-model:value="password"
        :labelText="$t('views.login.inputPassword')"
        id="password"
        type="password"
        :required="true"
      ></ui-form-input>
      <div class="text-center">
        <ui-button @click.stop="login()" color="primary" type="submit">{{ $t('views.login.btnSignIn') }}</ui-button>
      </div>
      <div class="text-sm text-center space-y-4">
        <div>
          <router-link :to="{ name: 'PasswordReset' }" class="underline">
            {{ $t('views.login.linkForgotYourPassword') }}
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Registration' }" class="underline">
            {{ $t('views.login.linkRegistration') }}
          </router-link>
        </div>
      </div>
    </ui-form>
  </unauthorized-layout>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import UnauthorizedLayout from '@/components/layouts/UnauthorizedLayout.vue';
import { KarmenError } from '@/errors';

export default {
  name: 'Login',

  components: {
    uiButton,
    uiForm,
    uiFormInput,
    UnauthorizedLayout,
  },

  props: {
    forceReload: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    username: null,
    password: null,
    loading: false,
    showError: false,
    errorHeaderText: ''
  }),

  mounted: function() {
    // in some cases, right after logout after some error etc, we want to force
    // reload browser to reset any app variables etc.
    if (this.$route?.query?.forceReload == 'true') {
      this.loading = true;
      this.$router.push({ name: 'Login' });

      // we want to wait a little (1 sec) before brorwser reload just to make sure
      // we are on correct url already otherwise it will stuck in reload loopp
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  },

  methods: {
    login() {
      this.loading = true;
      this.errorHeaderText = '';
      this.showError = false;

      this.$store.dispatch('account/login', {username: this.username, password: this.password}).then(() => {
        this.$router.push({ name: 'Dashboard' });

      }).catch((error) => {
        this.loading = false;
        this.showError = true;

        if (error?.response?.status == 401 || error?.response?.status == 400) {
          this.errorHeaderText = this.$t('views.login.errorInvalidCredentials');

        } else {
          throw new KarmenError(
            this.$t('views.login.errorUnknown'),
            error.message,
            error
          );
        }
      });
    }
  }
}
</script>
