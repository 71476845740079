<template>
  <!-- possible settings: https://github.com/fengyuanchen/cropperjs#cropperjs -->
  <vue-cropper
    ref="cropper"
    :src="image"
    :aspectRatio="1"
    :autoCrop="true"
    dragMode="move"
    :movable="true"
    :cropBoxResizable="false"
    :cropBoxMovable="false"
    @crop="imageCropped"
  ></vue-cropper>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'AvatarImageCrop',

  emits: ['canvasChanged'],

  components: {
    VueCropper
  },

  props: {
    image: {
      type: String,
      required: true
    }
  },

  methods: {
    imageCropped(event) {
      this.$emit('canvasChanged', event.target.cropper.getCroppedCanvas());
    }
  }
}
</script>
