import store from './store';
import { changeOctoprintConnectionState, emergencyStop, switchPowerOnOff } from './printerControls';


const UPDATE_INTERVAL = parseInt(process.env.VUE_APP_PRINTER_DETAIL_POLLING_INTERVAL);

let lastGroupId = null;

async function pollPrintersList() {
  let currentGroupId = store.getters['account/currentGroupId'];

  if (
    currentGroupId                              // only poll when user is logged in a group is selected
    && document.visibilityState == 'visible'    // only poll when browser window is visible anywher where can be seen by users
  ) {

    // when group id change, let's get fresh list of printers for current group
    if (lastGroupId != currentGroupId) {
      lastGroupId = currentGroupId;
      await store.dispatch('printers/loadPrintersByGroupId', {id: currentGroupId});
      await store.dispatch('printerGroups/loadPrinterGroups');
    }

    await store.dispatch('printers/loadPrintersStates');
  }

  setTimeout(() => {
    pollPrintersList();
  }, UPDATE_INTERVAL);
}

export default {
  install: (app) => {
    app.config.globalProperties.$printersService = {
      controls: {
        octoprintConnect: (printerId, printerName) => { return changeOctoprintConnectionState(true, printerId, printerName) },
        octoprintDisconnect: (printerId, printerName) => { return changeOctoprintConnectionState(false, printerId, printerName) },
        emergencyStop: (printerId, printerName) => { return emergencyStop(printerId, printerName) },
        switchPowerOn: (printerId, printerName) => { return switchPowerOnOff(printerId, printerName, true) },
        switchPowerOff: (printerId, printerName) => { return switchPowerOnOff(printerId, printerName, false) }
      }
    };

    // start polling printers state
    pollPrintersList();
  }
}
