<template>
  <dd class="mt-1 text-sm text-gray-700 dark:text-gray-400 sm:mt-0 sm:col-span-2">
    <slot></slot>
  </dd>
</template>

<script>
export default {
  name: 'uiDescriptionListDefinition',

  components: {},

  props: {}
}
</script>
