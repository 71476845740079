<template>
  <img
    ref="cameraImage"
    :src="image"
    class="max-w-full max-h-full h-full w-full transform object-contain"
  />
</template>

<script>
export default {
  name: 'uiImgRotable',
  props: {
    rotation: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      required: true
    },
  },

  mounted() {
    this.$nextTick(() => {
      const imageElm = this.$refs.cameraImage;
      const parentElm = imageElm.parentElement;

      if (this.rotation === 90 || this.rotation === 270) {
        // HACK: in Safari (osx & ios) it's not applied to element and is not rotated
        setTimeout(()=>{
          imageElm.style = 'transform: rotate(' + this.rotation + 'deg) scale(' + (parentElm.offsetHeight / imageElm.scrollWidth) + ')';
        }, 10);
      } else {
        imageElm.style = 'transform: rotate(' + this.rotation + 'deg)';
      }
    })
  }
}
</script>
