<template>
  <authorized-col-layout>
    <template v-slot:header>
    <ui-confirm-dialog
      ref="confirmSaveDialog"
      headerText="Device key"
      confirmBtnText="Save"
      confirmBtnType="danger"
      dismissBtnText="Cancel"
    >
      <p>{{ $t('views.settings.printerApiKeyConfirmationInfo') }}</p>

      <div class="
        my-4 p-2
        bg-gray-200 dark:bg-gray-900
        text-xs text-gray-700 dark:text-gray-400 font-mono break-all
      ">
        {{ this.item.token }}
      </div>

      <strong>{{ $t('views.settings.printerApiKeyConfirmationWarning') }}</strong>
    </ui-confirm-dialog>

      <ui-heading back>
        <span>
          {{ $t('views.settings.printerCreate') }}
        </span>
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box>

        <ui-loader v-if="loading" />

        <ui-form
          v-if="!loading"
          @submit="save()"
          :showError="!isValid"
          :errorText="invalidReason"
        >
          <ui-form-input
            v-model:value="item.name"
            id="name"
            :labelText="$t('views.settings.printerName')"
            :messageText="$t('views.settings.printerNameDescription')"
            :required="true"
          ></ui-form-input>

          <ui-form-input
            v-model:value="item.apiKey"
            :labelText="$t('views.settings.printerApiKey')"
            id="apiKey"
            :required="true"
            :messageText="$t('views.settings.printerApiKeyDescription')"
          ></ui-form-input>

          <div class="flex justify-between">
            <div>
              <ui-button @click.stop="close()" color="default" class="mr-4">
                {{ $t('views.settings.cancel') }}
              </ui-button>
              <ui-button @click.stop="next()" color="primary">
                {{ $t('views.settings.next') }}
              </ui-button>
            </div>

          </div>
        </ui-form>
      </ui-page-section-box>
    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SettingsPrinterAddOctoprint',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiForm,
    uiFormInput,
    uiButton,
    uiLoader,
    SettingsNavigation,
    uiConfirmDialog
  },

  props: {
    id: {
      type: String,
      default: null,
      required: false
    },
    groupId: {
      type: String,
      default: null,
      required: false
    },
  },

  data: () => ({
    item : {
      name: '',
      token: '',
      note: '',
      apiKey: '',
    },
    loading: true,
    isValid: true,
    invalidReason: '',
    editApiKey: false,
    editDeviceKey: false,
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('printers', { addPrinter: 'addPrinter' }),
    ...mapActions('account', ['requestNewDeviceKey']),

    async init() {
      this.loading = false;
    },

    validateForm: function() {
      this.isValid = true;
      if (!this.item.name || this.item.name.length <= 0) {
        this.isValid = false;
        this.invalidReason = 'Printer name can`t be empty.';
      } else if (!this.item.apiKey || this.item.apiKey.length <= 0) {
        this.isValid = false;
        this.invalidReason = 'API key can`t be empty.';
      }
      return this.isValid;
    },

    async generateKey() {
      return this.requestNewDeviceKey().then((response) => {
        return response.data.token;
      });
    },

    async next() {
      if (this.validateForm()) {
        this.loading = true;

        this.item.token = await this.generateKey();

        this.$refs.confirmSaveDialog.showDialog().then((result) => {
          if (result) {
            this.create();
          }
        });

        this.loading = false;
      }
    },

    async create() {
      if (this.validateForm()) {
        this.loading = true;
        await this.addPrinter({
          name: this.item.name,
          token: this.item.token,
          groupId: this.groupId,
          apiKey: this.item.apiKey,
        });
        // need to reload groups as we get printers count from group
        await this.$store.dispatch('account/groups');
        // need to reload printers to have up to date data
        await this.$store.dispatch('printers/loadPrinters');
        this.close();
      }
    },

    close() {
      this.$router.go(-1);
    }
  }
}
</script>
