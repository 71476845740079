<template>

  <transition name="modalTransition" appear>
    <div v-if="show" class="fixed -bottom-2 right-16 z-50 border border-2 border-red-400 bg-white-100 rounded-md max-h-96 overflow-auto dark:text-white-100 dark:bg-gray-700">
      <div class="sticky top-0 bg-gray-200 dark:bg-gray-750 flex z-50">
        <div class="grow ml-3 mt-3">Background activity</div>
        <div class="p-1"><ui-button @click.stop.prevent="close();" color="clear" icon="x-circle" /></div>
      </div>
      <div class="mt-1 mb-4 text-sm space-y-2">
        <div
          v-for="task, i in tasks"
          :key="i"
          class="border-b border-b-1 p-3"
          :class="{ 'cursor-pointer': task.onClick }"
          @click.stop.prevent="task.onClick ? task.onClick() : null"
        >
          <div class="flex space-x-4">
            <div class="grow">{{ task.name }}</div>
            <div>
              <ui-icon v-if="task.state=='running'" name="animate-spin-circle" class="animate-spin h-4 w-4 inline-block m-1" />
              <ui-icon v-else-if="task.state=='done'" name="check" class="h-4 w-4 inline-block m-1" />
              <div v-else>{{ task.state }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<style scoped>
.modalTransition-enter-active,
.modalTransition-leave-active { @apply transform transition ease-out duration-500; }
.modalTransition-enter-from,
.modalTransition-leave-to { @apply opacity-0; }
.modalTransition-enter-to,
.modalTransition-leave-from { @apply opacity-100; }
</style>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'AsyncTasksList',

  components: {
    uiButton,
    uiIcon
  },

  data: () => ({
    tasks: null,
    show: false,
    lastChange: Date.now()
  }),

  created: function() {
    this.$asyncTasksService.onChangeCallback((tasks) => {
      this.tasks = [];
      this.lastChange = Date.now();

      if (tasks?.length > 0) {
        for (let item of tasks) {
          this.tasks.push({
            name: item.name,
            state: item.state,
            onClick: item.onClick
          });
        }
        this.show = true;
      }
    });
  },

  mounted: function() {
    this.autoClose();
  },

  methods: {
    // Close AsyncTaskList automatically when data not changed for specified time
    autoClose() {
      if (document.body.contains(this.$el)) {
        if (Date.now() - this.lastChange > 20000) {
          this.close();
        }
        setTimeout(() => {
          this.autoClose();
        }, 15000);
      }
    },

    close() {
      this.tasks = [];
      this.show = false;
      this.$asyncTasksService.clearTasks();
    }
  }
}
</script>
