<template>
  <div
    class="aside-menu__item"
  >
    <router-link
      :to="{ name: routeName, params: routeParams, query: routerQuery }"
      aria-current="page"
      class="aside-menu__item-link flex items-center flex-grow overflow-hidden"
      @click="trackClick"
    >
      <ui-icon v-if="iconName" :name="iconName" class="flex-shrink-0 mr-2.5 h-4 w-4"></ui-icon>

      <div class="truncate flex-grow h-full dark:text-gray-300">
        <slot></slot>
      </div>

      <ui-icon v-if="this.$isMobile()" name="chevron-right" class="flex-shrink-0 h-4 w-4 opacity-50"></ui-icon>
    </router-link>

    <div v-if="contextMenu && !this.$isMobile()" class="aside-menu__item-actions relative">
      <button @click.stop="this.toggleContextMenu()" data-title="Labels listing">
        <ui-icon name="dots-vertical" class="h-5 w-5 inline-block"/>
      </button>

      <ui-context-menu
        :show="showContextMenu"
        @close="this.toggleContextMenu()"
      >
        <ui-context-menu-item
          v-for="(item, idx) in contextMenu"
          :key="idx"
          @click="this.clickAction(item)"
        >
          {{item.title}}
        </ui-context-menu-item>
      </ui-context-menu>
    </div>
  </div>
</template>

<style scoped>
.aside-menu__item {
  @apply
    flex items-center
    text-sm font-medium
    sm:rounded-sm
    border-b border-gray-200 dark:border-gray-700 last:border-b-0 sm:border-b-0;
}

.aside-menu__item .aside-menu__item-link {
  @apply px-4 py-5 sm:pr-3 sm:pl-0 sm:py-2;
}
.aside-menu__item-actions {
  @apply invisible mr-3;
}
.aside-menu__item:hover .aside-menu__item-actions {
  @apply visible;
}
.aside-menu__item--active .aside-menu__item-link {
  @apply text-red-400;
}
</style>

<script>
import uiContextMenu from '@/components/ui/uiContextMenu.vue';
import uiContextMenuItem from '@/components/ui/uiContextMenuItem.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiAsideMenuItem',

  components: {
    uiContextMenu,
    uiContextMenuItem,
    uiIcon
  },

  data: () => ({
    showContextMenu: false
  }),

  props: {
    routeName: {
      type: String,
      required: true
    },
    routeParams: {
      type: Object,
      required: false
    },
    routerQuery: {
      type: Object,
      required: false
    },
    iconName: {
      type: String,
      default: '',
      required: false
    },
    contextMenu: {
      type: Object,
      required: false
    },
    dataTitle: {
      type: String
    },
  },

  methods: {
    toggleContextMenu() {
      this.showContextMenu = !this.showContextMenu
    },

    clickAction(item) {
      this.toggleContextMenu();
      item.action();
    },

    trackClick() {
      this.$gtag.event('select', {
        'event_category': 'Navigation',
        'event_label': 'Aside Menu Item',
        'value': this.dataTitle
      })
    },
  }
}

</script>
