export default function () {
  return {
    gcodes: [],
    gcodeDetail: null,
    fileLabels: [],
    videos: [],
    videoDetail: null,
    printJobs: [],
    printDialogGcodes: []
  };
}
