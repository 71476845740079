<template>
  <div :class="{'py-4 sm:py-7' : !tight}">
    <div
      class="relative flex flex-row space-x-2"
      :class="{'text-center sm:text-left': !secondary}"
    >
      <template v-if="!secondary">
        <ui-skeleton v-if="loading" class="inline-block w-64 h-8" color="white" />

        <template v-else-if="!loading">
          <button v-if="back && $isMobile()" @click="$router.go(-1)" class="flex items-center absolute left-0 pl-3">
            <ui-icon name="chevron-left" class="h-6 w-6 mr-0.5 inline-block text-red-400" />
          </button>

          <h1 class="flex-grow sm:text-2xl font-bold text-gray-700 dark:text-gray-200 self-center items-center break-all">
            <slot></slot>
          </h1>
        </template>
      </template>

      <template v-else-if="secondary">
        <ui-skeleton v-if="loading" class="inline-block w-64 h-7" color="white" />

        <h2 v-else-if="!loading" class="flex-grow sm:text-lg font-bold text-gray-700 dark:text-gray-200 self-center break-all"><slot></slot></h2>
      </template>

      <div v-if="this.$slots.actionButton">
        <slot name="actionButton"></slot>
      </div>
    </div>

    <p v-if="this.$slots.description" class="text-sm font-medium text-gray-700 dark:text-gray-400">
      <slot name="description"></slot>
    </p>
  </div>
</template>

<script>
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiHeading',
  components: {
    uiSkeleton,
    uiIcon,
  },

  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    }
  }
}
</script>
