<template>
  <div :class="{'flex flex-row-reverse justify-end content-center': type === 'checkbox'}">
    <label :for="id" class="block text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none">
      {{ labelText }}
    </label>
    <div :class="{
      'mt-1': labelText && type !== 'checkbox',
      'mr-2': labelText && type === 'checkbox'
    }">
      <input
        v-bind:value="value"
        v-on:input="$emit('update:value', $event.target.value)"
        :id="id"
        :name="id"
        :type="type"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        :maxlength="maxlength"
        class="
          appearance-none
          block w-full
          border border-gray-200 dark:border-gray-700 rounded-sm
          bg-white-100 dark:bg-gray-900
          dark:text-gray-300
          placeholder-gray-400
          focus:outline-none focus:ring-red-400 focus:border-transparent
        "
        :class="{
          'bg-gray-100 text-gray-700 dark:bg-gray-900 dark:text-gray-500 italic': disabled,
          'px-3 py-2 sm:text-sm': !small,
          'px-2 py-1 text-xs': small
        }"
      />
    </div>
    <em
      v-if="messageText"
      class="block mt-1.5 text-sm"
      v-html="messageText"
    />
  </div>
</template>
<style scoped>
body.light {
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #1b1b1b !important;
  }
}

body.dark {
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
    -webkit-text-fill-color: #fff !important;
  }
}
</style>
<script>
export default {
  name: 'uiFormInput',

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    maxlength: {
      type: Number,
      default: 1000
    },
    placeholder: {
      type: String,
      default: null
    },
    labelText: {
      type: String,
      default: null
    },
    messageText: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        return [
          'text',
          'email',
          'password',
          'number',
          'checkbox',
          'radio'
        ].indexOf(value) !== -1
      }
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:value']
}
</script>
