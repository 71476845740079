<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading>
        <slot>{{ $t('views.settings.settings') }}</slot>
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation />
    </template>

    <template v-slot:right></template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';

export default {
  name: 'Settings',

  components: {
    AuthorizedColLayout,
    uiHeading,
    SettingsNavigation
  }
}
</script>
