<template>
  <main>
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'PublicLayout'
}
</script>

<style>
main {
  @apply max-w-7xl mx-auto sm:px-6 lg:px-8 pt-16;
}
</style>
