<template>
  <ui-aside-menu>

    <!-- All gcodes label link-->
    <ui-aside-menu-item
      routeName="Gcodes"
      :class="{'aside-menu__item--active': this.$route?.query?.labels === undefined}"
      data-title="File Labels List: All"
    >
      <div>{{ $t('views.gcodes.all') }}</div>
    </ui-aside-menu-item>

    <!-- No labels gcodes link -->
    <ui-aside-menu-item
      routeName="Gcodes"
      :routerQuery="{labels: ['UNCATEGORIZED']}"
      :class="{'aside-menu__item--active': this.$route.query.labels == 'UNCATEGORIZED'}"
      data-title='File Labels List: No Label'
    >
      <div>{{ $t('views.gcodes.noLabel') }}</div>
    </ui-aside-menu-item>

    <div>
      <ui-loader v-if="loading" class="h-10" />
    </div>

    <div v-if="!loading" class="hidden sm:flex justify-between mt-0.5 pt-5 pb-3 border-b dark:border-gray-700">
      <small class="font-light">{{ $t('views.gcodes.labels') }}</small>
      <div class="flex">
        <button @click.stop="reloadAll()" class="flex px-2 py-0.5">
          <ui-icon name="refresh" class="h-3.5 w-3.5 inline-block" />
        </button>
        <button
          v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)"
          @click.stop="editFileLabel(null)"
          class="flex px-2 py-0.5"
        >
          <ui-icon name="plus" class="h-3.5 w-3.5 inline-block" />
        </button>
      </div>
    </div>

    <div v-if="!loading">
      <template
        v-for="( item ) in items"
        :key="item.id"
      >
        <ui-aside-menu-item
          routeName="Gcodes"
          :routerQuery="{labels: item.id}"
          iconName="directory"
          :class="{
            'pl-4': item.nestedCount == 1,
            'pl-6': item.nestedCount == 2,
            'pl-8': item.nestedCount >= 3,
            'aside-menu__item--active': this.$route.query.labels == item.id,
          }"
          :contextMenu="
            // only show context menu when user has CHANGE_FILES permission
            this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES) ?
            [
              {
                title: $t('views.gcodes.actionEdit'),
                action: () => editFileLabel(item)
              },
              {
                title: $t('views.gcodes.actionDelete'),
                action: () => deleteFileLabel(item)
              },
            ] : null // don't show context menu
          "
          data-title="File Labels List: Listing Item"
          @click.stop="itemClicked(item)"
        >
          {{ item.name }}
        </ui-aside-menu-item>
      </template>
    </div>
  </ui-aside-menu>

  <ui-confirm-dialog
    ref="confirmLabelDeleteDialog"
    :headerText="$t('components.fileList.deleteLabe')"
    :confirmBtnText="$t('components.modal.delete')"
    :dismissBtnText="$t('components.modal.cancel')"
  >
    <p>{{ $t('views.gcodes.confirmDelete') }}</p>
  </ui-confirm-dialog>

  <ui-confirm-dialog
    ref="editLabelDialog"
    :headerText="editForm.name ? $t('views.gcodes.titleLabelEdit') : $t('views.gcodes.titleLabelAdd')"
    :confirmBtnText="$t('components.modal.confirm')"
    :dismissBtnText="$t('components.modal.cancel')"
  >
    <ui-form>
      <ui-form-input
        v-model:value="editForm.name"
        :labelText="$t('views.gcodes.labelName')"
        id="editFormName"
        :required="true"
      ></ui-form-input>
      <ui-form-select
        v-model:value="editForm.parentId"
        :labelText="$t('views.gcodes.labelParent')"
        id="editFormParentId"
        :required="false"
        :value="editForm.parentId"
        :values="editForm.parentsItems"
        :allow-empty="true"
      ></ui-form-select>
    </ui-form>
  </ui-confirm-dialog>
</template>

<script>
import uiAsideMenu from '@/components/ui/uiAsideMenu.vue';
import uiAsideMenuItem from '@/components/ui/uiAsideMenuItem.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'FileLabelsList',

  components: {
    uiAsideMenu,
    uiAsideMenuItem,
    uiConfirmDialog,
    uiForm,
    uiFormInput,
    uiFormSelect,
    uiIcon,
    uiLoader
  },

  emits: [
    'itemClicked'
  ],

  data: () => ({
    items: [],
    loading: true,
    editForm: null,
    contextMenuItemId: null
  }),

  created: function() {
    this.initEditForm();
    this.reloadAll();
  },

  methods: {
    async reloadAll() {
      this.loading = true;
      await this.$store.dispatch('gcodes/loadFileLabels');
      this.items = this.$store.getters['gcodes/fileLabels'];
      this.loading = false;
    },

    initEditForm(id, name, parentId) {
      this.editForm = {
        id: id,
        name: name,
        parentId: parentId,
        parentsItems: []
      };
    },

    deleteFileLabel(item) {
      this.$refs.confirmLabelDeleteDialog.showDialog().then((result) => {
        if (result) {
          this.loading = true;
          this.$store.dispatch('gcodes/deleteFileLabel', {id: item.id}).then(() => {
            this.reloadAll();
          });
        }
      });
    },

    editFileLabel(labelToEdit) {

      if (labelToEdit) {
        // edit existing label
        this.initEditForm(labelToEdit.id, labelToEdit.name, labelToEdit.parent_id);
      } else {
        // create new label - clear form
        this.initEditForm();
      }

      const isChildren = (label, parent) => {
        if (!parent) {
          return false;
        }
        if (label.parent_id == parent.id) {
          return true;
        } else if (label.parent_id == null) {
          return false;
        } else {
          return isChildren(label.parent, parent);
        }
      };

      // prepare labels tree to show in select form field
      // Don't show self and children - avoid circular reference
      this.items.filter((e) => {return !isChildren(e, labelToEdit) && e != labelToEdit}).forEach((e) => {
        let nameNestedPrefix = '';
        if (e.nestedCount > 0) {
          new Array(e.nestedCount).fill(0).forEach(() => { nameNestedPrefix += '\xa0\xa0' });
          nameNestedPrefix += '\xa0└\xa0';
        }
        this.editForm.parentsItems.push({id: e.id, name: nameNestedPrefix + e.name});
      });

      // show dialog and wait for user action (save data or cancel)
      this.$refs.editLabelDialog.showDialog().then((result) => {
        if (result) {
          this.loading = true;

          if (labelToEdit) {
            // update label
            this.$store.dispatch('gcodes/updateFileLabel', {
              id: this.editForm.id,
              name: this.editForm.name,
              parentId: this.editForm.parentId
            }).then(() => {
              this.reloadAll();
            });
          } else {
            // create new label
            this.$store.dispatch('gcodes/addFileLabel', {
              name: this.editForm.name, parentId: this.editForm.parentId
            }).then(() => {
              this.reloadAll();
            });
          }
        }
      });
    },

    showItemContextMenu(item) {
      (!item || this.contextMenuItemId == item.id)
        ? this.contextMenuItemId = null
        : this.contextMenuItemId = item.id
    },

    itemClicked(item) {
      this.$emit('itemClicked', [item.id,]);
    }
  }
}
</script>
