<template>
  <ui-modal :show="show" @close="close()" wide data-title="AddPrinterSelectorModal" :title="$t('views.settings.printerAdd')">
    <div class="text-center text-lg font-light mt-4">
        {{ $t('views.settings.printerAddText') }}
    </div>

    <div class="p-4 sm:p-8">
      <div class="grid gap-4 sm:gap-10 grid-cols-3">
        <button
          v-for="( item ) in items"
          :key="item.id"
          @click="item.action($router, groupId, $gtag)"
          class="
            flex space-between flex-col
            w-full
            p-3
            space-y-3
            bg-gray-200 dark:bg-black-900/50
            hover:bg-gray-300 dark:hover:bg-black-900/75
            text-gray-600 dark:text-gray-200
            hover:text-black-900 dark:hover:text-white-100
            rounded-sm
            cursor-pointer
            transform transition-transform transition-colors hover:scale-102
          "
        >
          <div class="
            flex items-center justify-center
            w-full h-full
            p-6 sm:px-12 sm:py-9
            bg-gray-100 dark:bg-gray-900
          ">
            <img
              class="max-h-10 max-w-10 sm:max-h-20 sm:max-w-20"
              v-if="item.img"
              :src="item.img"
            />
          </div>
          <h3 class="w-full text-center text-xs sm:text-sm">
            {{ item.description }}
          </h3>
        </button>
      </div>
    </div>

    <div class="mt-4 space-x-2 text-center">
      <ui-button @click="close()" color="clear">
        {{ $t('components.modal.close') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiModal from '@/components/ui/uiModal.vue';

export default {
  name: 'AddPrinterSelectorModal',

  emits: ['close', 'selected'],

  components: {
    uiButton,
    uiModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: String
    }
  },

  data: () => ({
    items: [
      {
        id: "octoprint",
        description: "Octoprint device",
        img: "/img/octoprint-logo.png",
        action: (router, groupId, gtag) => {
          router.push({
            name: 'SettingsPrinterAddOctoprint',
            params: { groupId: groupId }
          });

          gtag.event('select', {
            'event_category': 'Interaction',
            'event_label': 'Add Printer Modal: Choose Device',
            'value': 'Octoprint'
          });
        }
      },
      {
        id: "pill",
        description: "Karmen Pill",
        img: "/karmen-logo.svg",
        action: (router, groupId, gtag) => {
          router.push({
            name: 'SettingsPrinterAdd',
            params: { groupId: groupId }
          });

          gtag.event('select', {
            'event_category': 'Interaction',
            'event_label': 'Add Printer Modal: Choose Device',
            'value': 'Karmen'
          });
        }
      },
      {
        id: "klipper",
        description: "Klipper device",
        img: "/img/klipper-logo.png",
        action: (router, groupId, gtag) => {
          router.push({
            name: 'SettingsPrinterAdd',
            params: { groupId: groupId }
          });

          gtag.event('select', {
            'event_category': 'Interaction',
            'event_label': 'Add Printer Modal: Choose Device',
            'value': 'Klipper'
          });
        }
      }

    ]
  }),

  methods: {
    async choose() {
      this.$emit('selected');
      this.close();
    },

    close() {
      this.$emit('close');
    }
  }
}
</script>
