<template>

  <div class="relative grid sm:grid-cols-2 h-full border-b border-b-8 border-gray-100 dark:border-gray-900 pb-2 sm:pb-0 sm:border-b-0">

    <!-- printer tabs -->
    <div class="relative group order-2 sm:order-1 bg-white-100 dark:bg-gray-800">

      <!-- Skeleton loader -->
      <template v-if="!printer">
        <table class="infoTable">
          <tbody>
          <tr>
            <td colspan="2">
              <ui-skeleton class="h-6 w-16 inline-block mt-1 mr-6" />
              <ui-skeleton class="h-6 w-32 inline-block mt-1 mr-6" />
              <ui-skeleton class="h-6 w-12 inline-block mt-1" />
            </td>
          </tr>
          <template v-for="(index) of 2" v-bind:key="{index}">
            <tr>
              <th><ui-skeleton class="h-3 w-20" /></th>
              <td><ui-skeleton class="h-3 w-1/2" /></td>
            </tr>
            <tr>
              <th><ui-skeleton class="h-3 w-12" /></th>
              <td><ui-skeleton class="h-3 w-8" /></td>
            </tr>
            <tr>
              <th><ui-skeleton class="h-3 w-32" /></th>
              <td><ui-skeleton class="h-3 w-3/4" /></td>
            </tr>
          </template>
          </tbody>
        </table>
      </template>
      <!-- END Skeleton loader -->

      <template v-if="printer">
        <ui-page-section-box v-if="
            printer.isDeviceConnectedToInternet
            && !printer.isDevicePairedWithPrinter
            && printer?.printerState == 'PRINTER_NOT_PAIRED'
            && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)
          "
        >
          <div v-if="printer.hasPowerRelay">
            <div>{{ $t('views.printerDetail.txtPrinterTurnedOff') }}</div>
            <div class="mt-4">
              <ui-button @click.stop="switchPowerOn()" color="primary" :disabled="printer?.IS_CHANGING_STATE" :loading="printer?.IS_CHANGING_STATE">
                {{ $t('components.printer.actionPowerOn') }}
              </ui-button>
            </div>
          </div>
          <div v-else>
            <div>{{ $t('views.printerDetail.txtPrinterNotSyncedWithOctoprint') }}</div>
            <div class="mt-4">
              <ui-button @click.stop="connectPrinterToOctoprint()" color="primary" :disabled="printer?.IS_CHANGING_STATE" :loading="printer?.IS_CHANGING_STATE">
                {{ $t('views.printerDetail.btnPrinterNotSynced') }}
              </ui-button>
            </div>
          </div>
        </ui-page-section-box>

        <ui-navigation-underline-tabs>
          <ui-navigation-underline-tabs-item
            @click.prevent="activeDetailView = 'status'"
            :active="activeDetailView == 'status'"
            data-title="PrinterDetail: PrinterInfo"
          >
            {{ $t('views.printerDetail.txtPrinterInfo') }}
          </ui-navigation-underline-tabs-item>
          <ui-navigation-underline-tabs-item
            v-if="printer.isDevicePairedWithPrinter && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
            @click.prevent="activeDetailView = 'controls'"
            :active="activeDetailView == 'controls'"
            data-title="PrinterDetail: PrinterControls"
          >
            {{ $t('views.printerDetail.txtPrinterControls') }}
          </ui-navigation-underline-tabs-item>
          <ui-navigation-underline-tabs-item
            v-if="printer?.deviceType == 'moonraker' && printer?.isDevicePairedWithPrinter && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
            @click.prevent="activeDetailView = 'klipper'"
            :active="activeDetailView == 'klipper'"
            data-title="PrinterDetail: Klipper"
          >
            Klipper
          </ui-navigation-underline-tabs-item>
          <ui-navigation-underline-tabs-item
            v-if="printer?.isDeviceConnectedToInternet && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
            @click.prevent="activeDetailView = 'files'"
            :active="activeDetailView == 'files'"
            data-title="PrinterDetail: Files"
          >
            Files
          </ui-navigation-underline-tabs-item>
          <ui-navigation-underline-tabs-item
            v-if="printer.hasSpectodaLight"
            @click.prevent="activeDetailView = 'spectodaLights'"
            :active="activeDetailView == 'spectodaLights'"
            data-title="PrinterDetail: Spectoda Lights"
          >
            {{ $t('views.printerDetail.txtPrinterSpectodaLights') }}
          </ui-navigation-underline-tabs-item>
        </ui-navigation-underline-tabs>

        <!-- basic information -->
        <div v-if="activeDetailView == 'status'" class="mt-2">
          <table class="infoTable">
            <tbody>
              <tr>
                <th class="whitespace-nowrap">{{ $t('views.printerDetail.txtStatus') }}</th>
                <td><printer-state :printer="printer" /></td>
              </tr>
              <tr v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)">
                <th class="whitespace-nowrap">{{ $t('views.printer.filamentType') }}</th>
                <td><filament-type :name="printer?.filamentType" @typeChange="onFilamentTypeChange" allowEmptyTypeValue /></td>
              </tr>
              <tr class="" v-if="printer.note">
                <th>{{ $t('views.printerDetail.txtNote') }}</th>
                <td>{{ printer.note }}</td>
              </tr>
              <template v-if="printer.printerState == 'PRINTING'">
                <tr>
                  <th>{{ $t('views.printerDetail.txtGcode') }}</th>
                  <td class="break-all">{{ printer.currentJob?.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('views.printerDetail.txtProgress') }}</th>
                  <td>
                    <p>
                      {{ printer.currentJob?.completion?.toFixed(2) }}% done
                    </p>
                    <p v-if="printer.currentJob?.printTime && printer.currentJob?.printTime > 0">
                      Total: {{ $filters.timespan(printer.currentJob?.printTime, showSeconds=true) }}
                    </p>
                  </td>
                </tr>
              </template>
              <tr v-if="printer.isDevicePairedWithPrinter">
                <th>
                  {{ $t('views.printerDetail.txtTemperatures') }}<br>
                  ({{ $t('views.printerDetail.txtTemperaturesTool') }} / {{ $t('views.printerDetail.txtTemperaturesBed') }})
                </th>
                <td>
                  {{ printer.temperatures?.tool0?.actual?.toFixed(1) }}/{{ printer.temperatures?.tool0?.target?.toFixed(1) }} °C<br>
                  {{ printer.temperatures?.bed?.actual?.toFixed(1) }}/{{ printer.temperatures?.bed?.target?.toFixed(1) }} °C
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- controls -->
        <div  v-if="activeDetailView == 'controls' && printer.isDevicePairedWithPrinter" class="mt-2">
          <table class="infoTable infoTable--tight">
            <tbody>
              <printer-controls :printer="printer"></printer-controls>

              <tr>
                <th>{{ $t('views.printerDetail.controlsConnection') }}</th>
                <td>
                  <div v-if="printer.deviceType == 'moonraker'">
                    <ui-button @click.stop="switchPowerOff()" small class="whitespace-nowrap w-min" :disabled="printer?.IS_CHANGING_STATE" :loading="printer?.IS_CHANGING_STATE">
                      {{ $t('components.printer.actionPowerOff') }}
                    </ui-button>
                  </div>
                  <div v-else>
                    <ui-button @click.stop="disconnectPrinterFromOctoprint()" small class="whitespace-nowrap w-min" :disabled="printer?.IS_CHANGING_STATE" :loading="printer?.IS_CHANGING_STATE">
                      {{ $t('views.printerDetail.controlsConnectionDisconnect') }}
                    </ui-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Spectoda Lights -->
        <div v-if="activeDetailView == 'spectodaLights'" class="mt-2">
          <div class="p-8">
            <color-picker
              ref="picker"
              v-model="color"
              @input:end="onColorChange"
            />
          </div>
        </div>

        <!-- Klipper -->
        <div v-if="activeDetailView == 'klipper' && printer" class="mt-2">
          <printer-klipper :printerId="printer?.id" />
        </div>

        <!-- Files -->
        <div v-if="activeDetailView == 'files' && printer" class="mt-2">
          <printer-local-files :printerId="printer?.id" @printStarted="onLocalPrintStarted()" />
        </div>

      </template>
    </div>

    <!-- camera & emergency buttons -->
    <div v-if="printer?.id" class="group order-1 p-2 sm:p-3 sm:p-0 bg-white-100 dark:bg-gray-800">

      <div class="flex justify-end space-x-1 pb-1" v-if="(printer?.instances && printer?.instances.length > 1) || printer?.isDevicePairedWithPrinter">
        <!-- emergency stop button -->
        <ui-button
          v-if="printer.isDevicePairedWithPrinter"
          @click.stop="this.$printersService.controls.emergencyStop(this.printer.id, this.printer.name)"
          color="danger"
          small
        >
          <ui-icon name="exclamation" class="w-5 h-5" />
          <span class="uppercase">{{ $t('components.printer.actionEmergencyStop') }}</span>
        </ui-button>
        <!-- multiple instances button -->
        <ui-button
          v-if="printer?.instances && printer?.instances.length > 1"
          @click.stop="showMultipleInstancesModal=true"
          color="danger"
          class=""
          icon="exclamation"
          small
        ></ui-button>
      </div>

      <!-- camera -->
      <div class="relative" v-if="printer.cameraEnabled">
        <printer-camera
          :id="printer.id"
          :showFullscreenIcon="true"
        ></printer-camera>
      </div>
    </div>

  </div>

  <ui-confirm-dialog
    ref="confirmChangePrinterStateDialog"
    :headerText="$t('views.printer.changePrinterConnection')"
    :confirmBtnText="$t('components.modal.yesContinue')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>{{ $t('views.printerDetail.txtChangePrinterConnectionState') }}</p>
  </ui-confirm-dialog>

  <notification-modal
    :show="showMultipleInstancesModal"
    @close="showMultipleInstancesModal=false"
    :notification="{title: $t('views.printerDetail.multipleInstancesWarningTitle'), text: $t('views.printerDetail.multipleInstancesWarningDesc')}"
  >
    <div class="mt-4">
      <table class="divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="p-2 text-left font-bold">Printer ID</th>
            <th scope="col" class="p-2 pl-4 text-left font-bold">Printer name</th>
            <th scope="col" class="p-2 pl-4 text-left font-bold">Workspace name</th>
          </tr>
        </thead>
        <tr
          v-for="ins in getInstancesInfo()"
          :key="ins.id"
        >
          <td class="p-2">
            {{ ins.id }}
          </td>
          <td class="p-2 pl-4">
            <span v-if="ins.printerName">{{ ins.printerName }}</span>
            <span v-else><em>-hidden-</em></span>
          </td>
          <td class="p-2 pl-4">
            <span v-if="ins.workspaceName">{{ ins.workspaceName }}</span>
            <span v-else><em>-hidden-</em></span>
          </td>
        </tr>
      </table>
    </div>
  </notification-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import uiButton from '@/components/ui/uiButton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiNavigationUnderlineTabs from '@/components/ui/uiNavigationUnderlineTabs.vue';
import uiNavigationUnderlineTabsItem from '@/components/ui/uiNavigationUnderlineTabsItem.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import PrinterControls from '@/components/PrinterControls.vue';
import PrinterCamera from '@/components/PrinterCamera.vue';
import PrinterState from '@/components/PrinterState.vue';
import NotificationModal from '@/components/NotificationModal.vue';
import FilamentType from '@/components/FilamentType.vue';
import PrinterKlipper from '@/components/PrinterKlipper.vue';
import PrinterLocalFiles from '@/components/PrinterLocalFiles.vue';

import ColorPicker from '@/components/ColorPicker.vue'

export default {
  name: 'Printer',

  components: {
    uiButton,
    uiConfirmDialog,
    uiIcon,
    uiNavigationUnderlineTabs,
    uiNavigationUnderlineTabsItem,
    uiPageSectionBox,
    uiSkeleton,
    PrinterControls,
    PrinterCamera,
    PrinterState,
    NotificationModal,
    FilamentType,
    ColorPicker,
    PrinterKlipper,
    PrinterLocalFiles
  },

  props: {
    id: {
      type: String,
      default: null,
      required: true
    }
  },

  data: () => ({
    printer: null,
    oldConnectionState: null,
    activeDetailView: 'status',
    color: null,

    showMultipleInstancesModal: false
  }),

  created: function() {
    this.init();
  },

  mounted: function() {
    // try to use printer data we already have in cache before printer detail is loaded from server again
    this.printer = this.printerById()(this.id);
    
    if (this.$route?.query?.activeTab == 'controls') {
      this.activeDetailView = 'controls';
    }
  },

  methods: {
    ...mapActions('printers', ['changeSpectodaBrightness']),
    ...mapActions('printers', ['changeSpectodaColor']),
    ...mapGetters('printers', ['printerById']),
    ...mapActions('printers', ['updatePrinter']),

    init() {
      if (!this.id) {
        throw Error('Printer component: printer id not found');
      }

      this.printer = this.printerById()(this.id);
    },

    disconnectPrinterFromOctoprint() {
      this.$printersService.controls.octoprintDisconnect(this.printer.id, this.printer.name);
      this.activeDetailView = 'status';
    },

    connectPrinterToOctoprint() {
      this.$printersService.controls.octoprintConnect(this.printer.id, this.printer.name);
    },

    switchPowerOn() {
      this.$printersService.controls.switchPowerOn(this.printer.id, this.printer.name);
    },

    switchPowerOff() {
      this.$printersService.controls.switchPowerOff(this.printer.id, this.printer.name);
      this.activeDetailView = 'status';
    },

    onColorChange: function(color) {
      this.changeSpectodaColor({printerId: this.printer.id, value: color.color.hexString});
      this.changeSpectodaBrightness({printerId: this.printer.id, value: parseInt(color.color.alpha*100)});
    },

    getInstancesInfo() {
      let instances = [];
      if (this.printer?.instances?.length > 0) {
        for (let ins of this.printer.instances) {
          let workspaceName = this.$store.getters['account/groupById'](ins.group_id)?.name || null;
          let printerName = this.$store.getters['printers/printerById'](ins.id)?.name || null;
          instances.push({ id: ins.id, printerName: printerName, workspaceName: workspaceName });
        }
      }
      return instances;
    },

    onFilamentTypeChange(value) {
      this.updatePrinter({ id: this.printer.id, filamentType: value || '' });
    },

    onLocalPrintStarted() {
      this.activeDetailView = 'status';
    }
  },

  unmounted: function() {
    this.printer = null;
  }
}
</script>
