<template>
  <ui-navigation-underline-tabs class="">
    <ui-navigation-underline-tabs-item :active="isShowAllTabActive" @click.stop.prevent="setActiveTabAll()">
      {{ $t('components.printDialogFilesList.tabAllGcodes') }}
    </ui-navigation-underline-tabs-item>
    <ui-navigation-underline-tabs-item :active="isShowRecentTabActive" @click.stop.prevent="setActiveTabRecent()">
      {{ $t('components.printDialogFilesList.tabRecentlyPrinted') }}
    </ui-navigation-underline-tabs-item>
  </ui-navigation-underline-tabs>

  <ui-grid v-if="!loading" class="bg-gray-100">
    <div v-if="gridItems?.length <= 0">
      <ui-alert class="w-full px-4 sm:px-6">
        <div class="text-sm font-semibold dark:text-white-100">
          <span v-if="isShowRecentTabActive">{{ $t('components.printDialogFilesList.noRecentFiles') }}</span>
          <span v-else>{{ $t('components.printDialogFilesList.noFiles') }}</span>
        </div>
      </ui-alert>
    </div>

    <ui-grid-item
      v-for="item of gridItems"
      :key="item.id"
      class="bg-gray-100 dark:bg-gray-900"
    >
      <ui-grid-item-col
        type="main"
        @click="() => selectGcode(item)"
      >
        <ui-grid-item-col class="w-16 flex-none mr-2 pointer-events-none">
          <div class="border border-1 min-h-16">
            <gcode-preview-image
              :gcodeId="item.id"
              :previewExists="$filters.previewImageExists(item.previewImages)"
              :previewImageId="$filters.primaryPreviewImage(item.previewImages)?.id"
              :color="item.filament_color"
              :allowInteractions="false"
            ></gcode-preview-image>
          </div>
        </ui-grid-item-col>
        <ui-grid-item-col class="grow">
          <ui-grid-item-col-content type="heading">{{ item.name }}</ui-grid-item-col-content>
          <ui-grid-item-col-content type="text">
            <span class="pr-1">{{ item.uploadedBy?.username }}, </span>
            <time :datetime="item?.uploadedOn">{{ $filters.humanDateFormat(item?.uploadedOn) }}</time>
            <ui-badge v-if="item.filament_type" class="ml-1" color="warning">{{ item.filament_type }}</ui-badge>
          </ui-grid-item-col-content>
        </ui-grid-item-col>
      </ui-grid-item-col>
    </ui-grid-item>
  </ui-grid>

  <!-- Load next observer - load more items when scroll to the end of the grid -->
  <div v-if="isShowAllTabActive && this.$store.getters['gcodes/printDialogGcodes'].next && !this.loading" class="text-center py-8">
    <intersection-observer
      sentinal-name="end-of-grid-reached"
      @on-intersection-element="loadNext()"
    ></intersection-observer>
    <ui-button
      @click="loadNext()"
      color="primary"
      class="mt-2"
      small
      :loading="loadingNext"
    >
      {{ $t('components.fileList.more') }}
    </ui-button>
  </div>

  <ui-grid v-if="loading">
    <ui-grid-item
      v-for="index in 15"
      :key="index"
      :loading="true"
      class="bg-gray-100 dark:bg-gray-900"
    >
      <ui-grid-item-col type="main">
        <ui-grid-item-col>
          <ui-grid-item-col-content type="heading">
            <ui-skeleton class="h-5 w-72" />
          </ui-grid-item-col-content>
          <ui-grid-item-col-content>
            <ui-skeleton class="h-3.5 w-20 mt-1" />
          </ui-grid-item-col-content>
        </ui-grid-item-col>
      </ui-grid-item-col>
    </ui-grid-item>
  </ui-grid>
</template>

<script>
import uiAlert from '@/components/ui/uiAlert.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiSkeleton from '@/components/ui/uiSkeleton.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import uiNavigationUnderlineTabs from '@/components/ui/uiNavigationUnderlineTabs.vue';
import uiNavigationUnderlineTabsItem from '@/components/ui/uiNavigationUnderlineTabsItem.vue';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';

export default {
  name: 'PrintDialogFilesList',

  components: {
    uiAlert,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiSkeleton,
    uiButton,
    uiBadge,
    uiNavigationUnderlineTabs,
    uiNavigationUnderlineTabsItem,
    GcodePreviewImage,
    IntersectionObserver
  },

  emits: ['gcodeSelected'],

  props: {
    preselectedGcodeId: {
      type: String,
      default: null
    },
  },

  data: () => ({
    gridItems: [],
    loading: true,
    loadingNext: false,

    isShowAllTabActive: true,
    isShowRecentTabActive: false,
  }),

  created: async function() {
    if (this.preselectedGcodeId) {
      await this.$store.dispatch('gcodes/loadGcodeDetail', {id: this.preselectedGcodeId});
      this.selectGcode(this.$store.getters['gcodes/gcodeDetail']);
    }
    this.loadFiles();
  },

  methods: {
    async loadFiles() {
      this.loading = true;
      this.loadingNext = true;

      if (this.isShowAllTabActive) {
        await this.$store.dispatch('gcodes/loadGcodes', { labelsFilterIds: [], vuexStoreMethodName: 'setPrintDialogGcodes' });
      } else {
        await this.$store.dispatch('gcodes/loadLastPrintedGcodes');
      }

      this.gridItems = this.$store.getters['gcodes/printDialogGcodes'].results;
      this.loading = false;

      // prevent loading next before regular gcodes are visible to not fire observer event
      setTimeout(() => {
        this.loadingNext = false;
      }, 300);
    },

    async loadNext() {
      if (! this.loadingNext && ! this.loading) {
        this.loadingNext = true;
        if (this.$store.getters['gcodes/printDialogGcodes'].next) {
          await this.$store.dispatch('gcodes/loadGcodesNext', { url: this.$store.getters['gcodes/printDialogGcodes'].next, vuexStoreMethodName: 'setPrintDialogGcodes' });
          this.gridItems = this.$store.getters['gcodes/printDialogGcodes'].results;
        }

        // prevent to fire observer event multiple times in one case/scenario
        setTimeout(() => {
          this.loadingNext = false;
        }, 300);
      }
    },

    selectGcode(gcode) {
      this.$emit('gcodeSelected', gcode);
    },

    setActiveTabAll() {
      this.isShowAllTabActive = true;
      this.isShowRecentTabActive = false;
      this.loadFiles();
    },

    setActiveTabRecent() {
      this.isShowAllTabActive = false;
      this.isShowRecentTabActive = true;
      this.loadFiles();
    }
  }
}
</script>
