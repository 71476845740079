<template>
  <div>
    <authorized-layout>
      <ui-heading>
        {{ $t('views.news.title') }}
      </ui-heading>

      <ui-page-section-box v-if="items.length <= 0">
        <div class="text-sm">{{ $t('views.news.noMessages') }}</div>
      </ui-page-section-box>

      <ui-page-section-box v-else tight>
        <ui-grid>
          <router-link
            :to="{ name: 'NewsDetail', params: { id: item.id }}"
            v-for="item of items"
            :key="item"
          >
            <ui-grid-item>
              <ui-grid-item-col type="main" class="pl-4 pb-4">
                <ui-grid-item-col>
                  <ui-grid-item-col-content v-if="item.date" type="text" class="pt-2">
                    <time :datetime="item.date">{{ $filters.dateFormat(item.date) }}</time>
                  </ui-grid-item-col-content>
                  <ui-grid-item-col-content type="heading" class="pt-2">
                    <div v-if="!item.is_read" class="rounded-full bg-red-400 w-2 h-2 mr-2 mt-1"></div>
                    {{ item.title }}
                  </ui-grid-item-col-content>
                  <ui-grid-item-col-content type="text">
                    <div class="dark:text-white-100">{{ item.perex }}</div>
                  </ui-grid-item-col-content>
                </ui-grid-item-col>
              </ui-grid-item-col>
              <ui-grid-item-col type="right" class="content-center px-4">
                <ui-grid-item-col-content type="text">
                  <div class="grid">
                    <ui-button color="primary" small>{{ $t('views.news.btnDetail') }}</ui-button>
                  </div>
                </ui-grid-item-col-content>
              </ui-grid-item-col>
            </ui-grid-item>
          </router-link>
        </ui-grid>
      </ui-page-section-box>

    </authorized-layout>
  </div>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'News',

  components: {
    AuthorizedLayout,
    uiHeading,
    uiPageSectionBox,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiButton
  },

  data: () => ({
    items: []
  }),

  created: function() {
    this.items = this.$store.getters['account/news'];
  }
}
</script>
