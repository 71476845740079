<template>
  <div>
    <div class="block text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none">{{ labelText }}</div>
    <div class="mt-1">
      <vue-editor
        v-model="content"
        :editorToolbar="customToolbar"
        v-on:text-change="onTextChange($event)"
        class="
          appearance-none
          bg-white-100 dark:bg-gray-900
          dark:text-gray-300
          focus:outline-none focus:ring-red-400 focus:border-transparent
          sm:text-sm
        "
      />
      <!-- v-on:input="$emit('update:value', $event.target.value)" -->
    </div>
  </div>
</template>

<style>
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  @apply border border-gray-200 dark:border-gray-700;
}

.quillWrapper .ql-snow .ql-stroke {
  @apply dark:stroke-gray-300;
}
</style>

<script>
import { VueEditor } from "vue3-editor";

export default {
  name: 'uiFormHtmlEditor',
  components: {
    VueEditor
  },

  props: {
    value: {
      type: String,
      default: null
    },
    labelText: {
      type: String,
      default: null
    }
  },

  emits: ['update:value'],

  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    content: null
  }),

  created: function() {
    this.content = this.value;
  },

  methods: {
    onTextChange() {
      this.$emit('update:value', this.content)
    }
  }
}
</script>
