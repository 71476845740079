<template>
  <li class="border-b border-1 border-gray-200 dark:border-gray-700">
    <a
      :class="{'cursor-pointer hover:bg-gray-150 dark:hover:bg-gray-750': !loading && !header}"
      class="group block"
    >
      <slot name="header"></slot>
      <div class="pl-2 pr-1 py-2 sm:pr-2 flex text-sm">
        <div class="min-w-0 flex flex-1 justify-between">
          <slot></slot>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: 'uiGridItem',
  props: {
    header: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
