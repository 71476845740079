<template>
  <ui-modal :show="show" @close="close()">

    <div>
      <div v-if="loading"><ui-loader /></div>
      <div v-else>

        <!-- show select file button -->
        <div
          v-show="!showCropper"
          class="
            py-10
            text-center
            border border-dashed border-gray-200 dark:border-gray-700
            bg-gray-100 dark:bg-gray-900
          "
        >
          <input type="file" ref="fileInput" @change="onFileInputChange" class="hidden" accept=".jpg, .jpeg, .png" />
          <div>
            <ui-button @click.stop="$refs.fileInput.click()" color="dark" class="mt-5">
              {{$t('views.settings.workspaceAvatarDialogCta')}}
            </ui-button>
          </div>
        </div>

        <!-- show image crop component -->
        <div
          v-if="showCropper"
          class="
            text-center
            bg-gray-100 dark:bg-gray-900
            border border-dashed border-gray-200 dark:border-gray-700
          "
        >
          <avatar-image-crop
            :image="imageToCrop"
            @canvasChanged="canvasCropped"
          ></avatar-image-crop>
        </div>

      </div>
    </div>

    <div class="mt-8 space-x-2 text-center" v-if="!loading">
      <ui-button @click="close()" color="default">
        {{ $t('views.settings.cancel') }}
      </ui-button>
      <ui-button @click="save()" color="primary" :disabled="saveBtnDisabled">
        {{ $t('views.settings.save') }}
      </ui-button>
    </div>
  </ui-modal>

</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiModal from '@/components/ui/uiModal.vue';
import AvatarImageCrop from '@/components/AvatarImageCrop.vue';

export default {
  name: 'AvatarDialog',

  emits: ['close', 'avatarUpdated'],

  components: {
    uiButton,
    uiLoader,
    uiModal,
    AvatarImageCrop
  },

  props: {
    groupId: {
      type: String,
      default: null,
      required: false
    }
  },

  data: () => ({
    loading: true, // whether there is any loading activity
    show: false, // whether to show this dialog
    file: null, // file provided by user
    imageToCrop: null, // image provided to cropper
    croppedCanvas: null, // cropped canvas
    showCropper: false, // whether to show cropper
    saveBtnDisabled: true
  }),

  created: function() {
    this.init();
  },

  methods: {
    showDialog() {
      this.show = true;
      this.init();
    },

    init() {
      this.loading = false;
      this.file = null;
      this.imageToCrop = null;
      this.croppedCanvas = null;
      this.showCropper = false;
      this.saveBtnDisabled = true;
    },

    onFileInputChange(event) {
      let files = event?.target?.files;
      if (files && files.length == 1) {
        this.file = files[0];
        if (['image/png', 'image/jpeg'].includes(this.file.type)) {
          const fr = new FileReader();
          fr.readAsDataURL(this.file);
          fr.addEventListener('load', () => {
            this.imageToCrop = fr.result;
            this.showCropper = true;
          });
          this.saveBtnDisabled = false;
        } else {
          this.close();
          this.$store.commit('app/setNotification', ({
            title: 'Image not valid',
            text: 'Sorry, currently only PNG and JPEG images are supported. Your file: ' + this.file.name
          }));
        }
      }
    },

    canvasCropped(canv) {
      this.croppedCanvas = canv;
    },

    save() {
      this.croppedCanvas.toBlob((blob) => {
        this.loading = true;
        let file = new File([blob], 'avatar_' + this.groupId + '.png', { type: 'image/png' });
        this.$store.dispatch('account/updateGroupAvatar', {groupId: this.groupId, imgData: file}).then((response) => {
          this.onAvatarUpdated(response.data.avatar);
          this.loading = false;
        });
      });
    },

    onAvatarUpdated(newAvatarFilename) {
      this.$emit('avatarUpdated', newAvatarFilename);
      this.close();
    },

    close() {
      this.show = false;
      this.$emit('cancel');
    }
  }
}
</script>
