<template>
  <unauthorized-layout
    :headerText="headerText"
    :loading="loading"
    :showError="showError"
    :errorHeaderText="errorHeaderText"
  >
    <p v-if="!sent" class="mt-2 mb-8 text-center text-sm text-gray-700 dark:text-gray-400 max-w">
      Please choose your new password.<br/>
    </p>
    <ui-form v-if="!sent" class="px-8">
      <ui-form-input
        v-model:value="email"
        :labelText="$t('views.passwordReset.inputEmailAddress')"
        id="email"
        type="email"
        :required="true"
        :disabled="true"
      ></ui-form-input>
      <ui-form-input
        v-model:value="password"
        :labelText="$t('views.passwordReset.newPassword')"
        id="password"
        type="password"
        :required="true"
      ></ui-form-input>
      <ui-form-input
        v-model:value="passwordAgain"
        :labelText="$t('views.passwordReset.confirmPassword')"
        id="passwordAgain"
        type="password"
        :required="true"
      ></ui-form-input>
      <div>
        <ui-button @click.stop="send()" color="primary" type="submit">{{ $t('views.passwordReset.btnContinue') }}</ui-button>
      </div>
    </ui-form>
    <div v-if="sent" class="px-8">
      <p class="mt-2 text-sm text-center text-gray-700 dark:text-gray-400 max-w">Password for your account <strong>{{ this.email }}</strong> has been changed. Now you can login with your new password.</p>
      <div class="mt-8">
        <ui-button @click.stop="this.$router.push({ name: 'Login' });" color="primary">Go to login</ui-button>
      </div>
    </div>
  </unauthorized-layout>
</template>

<script>
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiButton from '@/components/ui/uiButton.vue';
import UnauthorizedLayout from '@/components/layouts/UnauthorizedLayout.vue';
import { mapActions } from 'vuex';
import jwt_decode from 'jwt-decode';

export default {
  name: 'PasswordResetConfirm',

  components: {
    uiForm,
    uiFormInput,
    uiButton,
    UnauthorizedLayout
  },

  props: {
    token: {
      type: String,
      default: null
    }
  },

  data: () => ({
    password: null,
    passwordAgain: null,
    email: null,
    sent: false,
    showError: false,
    loading: false,
    headerText: 'Password Reset',
    errorHeaderText: ''
  }),

  created: function() {
    this.email = jwt_decode(this.token).email;
  },

  methods: {
    ...mapActions('account', ['passwordResetConfirm']),

    validateForm: function() {
      this.showError = false;

      if (this.password != this.passwordAgain) {
        this.showError = true;
        this.errorHeaderText = 'Passwords do not match!';
      } else if (!this.password || this.password.length <= 0) {
        this.showError = true;
        this.errorHeaderText = 'Password can`t be empty.';
      }

      return !this.showError;
    },

    send: function() {
      if (this.validateForm()) {
        this.showError = false;
        this.loading = true;
        this.passwordResetConfirm({ password: this.password, token: this.token }).then(() => {
          this.sent = true;
          this.loading = false;
          this.headerText = 'Password changed.';
          this.$router.push({ name: 'PasswordResetConfirm' });  // remove token from URL

        }).catch((error) => {

          this.showError = true;
          this.loading = false;

          if (error.response.status == 401
            && error.response.data.code == 'token_not_valid') {
            this.errorHeaderText = 'Security token is invalid or expired. Please proceed to password reset again.';

          } else if (error.response.status == 400
            && error.response.data.password?.length>0) {
            this.errorHeaderText = 'Invalid password. ' + error.response.data.password;

          } else {
            this.errorHeaderText = 'Uknown error: ' + JSON.stringify(error.response.data);
          }
        });
      }
    }
  }
}
</script>
