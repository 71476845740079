<template>
  <div :class="color" class="indicator whitespace-nowrap">
    <slot></slot>
  </div>
</template>

<style scoped>
  .indicator {
    @apply
      p-1
      inline
      text-white-100 dark:text-white-100 text-xs leading-none;
  }
  .default {
    @apply bg-gray-500;
  }
  .primary {
    @apply bg-green-400;
  }
  .danger {
    @apply bg-red-400;
  }
  .success {
    @apply bg-blue-400;
  }
  .warning {
    @apply bg-yellow-600;
  }
</style>

<script>
export default {
  name: 'uiIndicator',

  props: {
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'primary', 'success', 'warning', 'danger'].indexOf(value) !== -1
      }
    }
  }
}
</script>
