<template>
  <div v-if="loading" class="my-32 py-1.5"><ui-loader /></div>
  <template v-else>
    <div class="grid space-y-8">
      <printer-group
        v-if="printersNotInGroup.length > 0"
        :group="{ id: ALL_PRINTERS_GROUP_ID, name: $t('components.printerGroup.allPrintersGroupDefaultName'), printers: printersNotInGroup }"
        :simpleView="printerGroups.length == 0"
        @printerDropped="onPrinterDropped($event, ALL_PRINTERS_GROUP_ID)"
        :enableDelete="false"
        :loading="printerGroupChangeInProgress"
      />

      <printer-group
        v-for="group in printerGroups"
        :key="group.id"
        :group="group"
        :simpleView="false"
        @deleted="init()"
        @printerDropped="onPrinterDropped($event, group.id)"
        :enableDelete="true"
        :loading="printerGroupChangeInProgress"
      />
    </div>
  </template>
</template>

<script>
import uiLoader from '@/components/ui/uiLoader.vue';
import PrinterGroup from '@/components/PrinterGroup.vue';

export default {
  name: 'PrintersList',

  components: {
    uiLoader,
    PrinterGroup
  },

  watch: {
    '$store.state.printerGroups.printerGroups': function() {
      this.printerGroups = this.$store.getters['printerGroups/printerGroups'];
    }
  },

  data: () => ({
    ALL_PRINTERS_GROUP_ID: 'ALL-PRINTERS',
    printers: [],
    loading: true,
    printerGroups: [],
    printersNotInGroup: [],

    printerGroupChangeInProgress: false
  }),

  created: function() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;

      await this.reloadData();

      this.loading = false;
    },

    async reloadData() {
      await this.$store.dispatch('printerGroups/loadPrinterGroups'); // reload printers groups as might be changed (for example after moving printer between groups)
      this.printers = this.$store.getters['printers/printers'];
      this.printerGroups = this.$store.getters['printerGroups/printerGroups'];
      this.selectPrintersNotInGroups();
    },

    selectPrintersNotInGroups() {
      this.printersNotInGroup = [];

      let printersInGroups = [];
      for (let pg of this.printerGroups) {
        for (let p of pg.printers) {
          printersInGroups.push(p);
        }
      }

      this.printersNotInGroup = this.printers.filter((val) => {
        return !printersInGroups.some((val2) => {
          return val.id == val2.id;
        });
      });
    },

    async onPrinterDropped(evt, destinationprinterGroupId) {
      let printerId = evt.printerId;
      let sourcePrinterGroupId = evt.sourcePrinterGroupId;

      // was moved printer from one printer group to another?
      if (sourcePrinterGroupId != destinationprinterGroupId) {
        this.printerGroupChangeInProgress = true;
        if (destinationprinterGroupId == this.ALL_PRINTERS_GROUP_ID) {
          await this.$store.dispatch('printerGroups/deletePrinterFromPrinterGroup', { printerGroupId: sourcePrinterGroupId, printerId: printerId });
        } else {
          await this.$store.dispatch('printerGroups/addPrinterToPrinterGroup', { printerGroupId: destinationprinterGroupId, printerId: printerId });
        }
        await this.reloadData();
        this.printerGroupChangeInProgress = false;
      }
    }
  }
}
</script>
