import { i18n } from '@/i18n.js';

export default function () {
  return {
    printQueue: [],
    printQueueStates: {
      TO_BE_PRINTED: {
        id: 'to-be-printed',
        title: i18n.global.t('views.printQueue.stateToBePrintedTitle'),
        desc: i18n.global.t('views.printQueue.stateToBePrintedDescription')
      },
      PRINTED: {
        id: 'printed',
        title: i18n.global.t('views.printQueue.statePrintedTitle'),
        desc: i18n.global.t('views.printQueue.statePrintedDescription')
      },
      DONE: {
        id: 'done',
        title: i18n.global.t('views.printQueue.stateDoneTitle'),
        desc: i18n.global.t('views.printQueue.stateDoneDescription')
      }
    }
  };
}
