<template>

  <ui-confirm-dialog
    ref="confirmOpenExternalLink"
    :headerText="$t('views.printerDetail.confirmOpenExternalLink')"
    :confirmBtnText="$t('components.modal.confirm')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>
      {{ $t('views.printerDetail.confirmOpenExternalLinkDescription') }}
    </p>
  </ui-confirm-dialog>

  <authorized-layout>
    <ui-heading :loading="loading" back>
      <slot>{{ printer.name }}</slot>

      <template
        v-slot:actionButton
        v-if="$store.getters['account/hasPermission']($PERMISSIONS.CHANGE_GROUP) && !loading"
      >
        <div class="flex justify-center space-x-2 sm:pr-0 pr-2">
          <ui-button
            @click.stop="
              this.$router.push({
                name: 'SettingsPrinterEdit',
                params: { id: printer?.id, groupId: currentGroupId() },
              })
            "
            small
          >
            <ui-icon name="cog" class="w-4 h-4" />
            <span class="ml-1 hidden md:block">{{ $t('views.settings.settings') }}</span>
          </ui-button>

          <ui-button
            v-if="printer?.localIpAddress"
            class="border-gray-200 dark:border-gray-600 dark:hover:border-gray-700"
            @click.stop="openExternalLinkConfirmationDialog()"
            small
          >
            <img v-if="printer.deviceType == 'moonraker'" src="/img/klipper-logo.png" class="w-6 h-6" />
            <img v-else src="/img/octoprint-logo.png" class="w-6 h-6" />
          </ui-button>
        </div>
      </template>
    </ui-heading>

    <ui-page-section-box tight>
      <printer :id="id" v-if="!loading"></printer>
    </ui-page-section-box>

    <ui-heading secondary v-if="!loading" class="pl-4 sm:pl-0">{{ $t('views.printerDetail.printHistory') }}</ui-heading>
    <ui-page-section-box tight v-if="!loading">
      <div class="pt-1">
        <print-jobs-list :printer-id="id" showPreviewImage />
      </div>
    </ui-page-section-box>
  </authorized-layout>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import Printer from '@/components/Printer.vue';
import PrintJobsList from '@/components/PrintJobsList.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PrinterDetail',

  components: {
    AuthorizedLayout,
    uiHeading,
    uiButton,
    uiIcon,
    uiPageSectionBox,
    uiConfirmDialog,
    Printer,
    PrintJobsList
  },

  props: {
    id: {
      type: String,
      default: null,
      required: true
    }
  },

  watch: {
    id(val) {
      if (val) {
        this.init();
      }
    }
  },

  data: () => ({
    printer: null,
    loading: true
  }),

  created: function () {
    this.init();
  },

  methods: {
    ...mapGetters('account', ['currentGroupId']),
    ...mapGetters('printers', ['printerById',]),
    ...mapActions('printers', ['loadPrinters']),

    async init() {

      this.loading = true;

      if (!this.id) {
        throw Error('Printer component: invalid "printer id": ' + this.id);
      }

      this.printer = this.printerById()(this.id);

      // sometimes, after reload etc, printer might not be loaded yet;
      // in this case, let's try to load printers again to be sure, that printer
      // with provided this.id really does not exists
      if (this.printer == null) {
        await this.loadPrinters();
        this.printer = this.printerById()(this.id);
      }

      if (this.printer == null) {
        this.$store.commit('app/setNotification', ({
          title: 'Printer not found',
          text: 'Printer with ID "' + this.id + '" does not exists.',
          onClose: () => {
            this.$router.push({ name: 'Printers' });
          }
        }));
      } else {
        this.loading = false;
      }
    },

    async openExternalLinkConfirmationDialog() {
      let result = await this.$refs.confirmOpenExternalLink.showDialog();
      if (result) {
        if (this.printer?.id && this.printer?.localIpAddress) {
          window.open('http://' + this.printer.localIpAddress + '/', '_blank').focus();
        }
      }
    }
  }
};
</script>
