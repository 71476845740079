<template>
  <div>
    <authorized-layout>
      <ui-heading>
        {{ $t('views.gcodes.title') }}
      </ui-heading>
      <files-list :enableSearch="true" />
    </authorized-layout>
  </div>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import FilesList from '@/components/FilesList.vue';
import uiHeading from '@/components/ui/uiHeading.vue';

export default {
  name: 'Gcodes',

  components: {
    AuthorizedLayout,
    FilesList,
    uiHeading
  }
}
</script>
