<template>
  <ui-confirm-dialog
    ref="uploadGcodeDialog"
    :headerText="$t('views.gcodes.modalFileAddTitle')"
    :confirmBtnText="$t('components.modal.save')"
    :dismissBtnText="$t('components.modal.cancel')"
    :autoCloseOnConfirm="false"
    :disableConfirmButton="isUploading || files == null || files?.length == 0 || isError"
    @onDismiss="closeDialog"
  >

    <div class="w-full h-5 border border-1" v-show="isUploading">
      <div class="relative w-full h-full bg-gray-200 dark:bg-gray-600">
        <div class="absolute bg-green-400 top-0 bottom-0" :style="'width: ' + (100 / uploadProgress.total * uploadProgress.loaded).toFixed(2) + '%'">&nbsp;</div>
        <div class="absolute left-0 right-0 top-0 bottom-0 text-center font-medium text-xs">{{ (100 / uploadProgress.total * uploadProgress.loaded).toFixed(2) }}% <em>({{ $filters.humanFileSize(uploadProgress.loaded) }} of {{ $filters.humanFileSize(uploadProgress.total) }})</em></div>
      </div>
    </div>

    <form
      ref="fileform"
      class="
        text-center bg-gray-100
        border border-2 border-dashed
        border-gray-400
        dark:bg-gray-900 dark:border-gray-700
      "
      :class="{ 'border-red-400 dark:border-red-500': isDragging, 'py-6': (!files || files?.length == 0) }"
      @dragenter="onDragOverFileform(true)"
      @dragover="onDragOverFileform(true)"
      @dragleave="onDragOverFileform(false)"
      @drop="onDragOverFileform(false)"
    >
      <div class="h-64 overflow-y-auto">
        <ui-grid v-if="files?.length > 0">
          <ui-grid-item v-for="(f, i) in files" :key="i">
            <ui-grid-item-col>
              <ui-grid-item-col-content type="text">
                <div class="grid grid-cols-1">
                  <div class="text-left">
                    {{ f.name }}
                    <div class="italic inline ml-4">({{ $filters.humanFileSize(f.size) }})</div>
                  </div>
                  <div v-if="f._isError" class="text-left text-xs text-red-400 italic">{{ f._errorMessage }}</div>
                </div>
              </ui-grid-item-col-content>
            </ui-grid-item-col>
            <ui-grid-item-col>
              <ui-grid-item-col-content type="text">
                <ui-button v-if="!f._isUploaded && !f._isUploading && !isUploading" small icon="trash" @click.stop="removeFile(i)"></ui-button>
                <ui-loader v-else-if="f._isUploading" class="w-8 h-8" />
                <ui-icon v-else-if="f._isError" name="exclamation" class="w-6 h-6 text-red-400" />
                <ui-icon v-else-if="f._isUploaded" name="check" class="w-6 h-6 text-green-400" />
              </ui-grid-item-col-content>
            </ui-grid-item-col>
          </ui-grid-item>
        </ui-grid>

        <input type="file" ref="fileInput" @change="onFileInputChange" multiple class="hidden" />
        <div class="pb-5" v-if="!isUploading && !isError">
          <p v-if="!files || files?.length == 0">
            {{ $t('views.gcodes.modalFileAddInstruction1') }}
            <br>
            {{ $t('views.gcodes.modalFileAddInstruction2') }}
          </p>
          <ui-button @click.stop="$refs.fileInput.click()" small color="primary" icon="plus" class="mt-5">
            {{ $t('views.gcodes.modalFileAddChoose') }}
          </ui-button>
          <div v-if="!files || files?.length == 0">
            <p class="mt-4 text-xs">
              {{ $t('views.gcodes.modalFileAddSupported') }}
              <code class="border border-gray-200 dark:border-gray-700 p-1">*.gcode, *.bgcode</code>
            </p>
            <p class="text-xs mt-1">
              {{ $t('views.gcodes.modalFileAddFileSize') }}
            </p>
          </div>
        </div>
      </div>
    </form>

    <!-- help with Prusa Slicer Info -->
    <div class="mt-2 flex space-x-2">
      <div><ui-icon name="information-circle" class="w-6 h-6 text-red-400" /></div>
      <span class="text-xs italic" v-html="$t('views.gcodes.modalFileAddPrusaSlicerInfo')"></span>
    </div>
  </ui-confirm-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';

export default {
  name: 'UploadGcodeDialog',

  components: {
    uiButton,
    uiIcon,
    uiLoader,
    uiConfirmDialog,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent
  },

  emits: [
    'canceled',
    'uploaded'
  ],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(val) {
      this.initDialog();

      if (val) {
        this.showDialog();
      }
    }
  },

  data: () => ({
    files: [],
    isDragging: false,
    isUploading: false,
    uploadProgress: {},
    isError: false
  }),

  methods: {
    ...mapActions('gcodes', { uploadGcode: 'uploadGcode' }),
    ...mapGetters('account', { currentGroupId: 'currentGroupId' }),
    ...mapMutations('app', { setNotification: 'setNotification' }),

    onDragOverFileform(drag) {
      this.isDragging = drag;
    },

    updateUploadProgress(event) {
      this.uploadProgress = event;
    },

    closeDialog() {
      if (this.isUploading) {
        // FIXME HACK: this is a little hack
        // when cancel dialog, but uploading file, we need to reload page to stop upload request
        // this can be done better with axios cancel request: https://masteringjs.io/tutorials/axios/cancel
        this.setNotification({ title: 'Cancelling upload', text: 'Please wait, trying to stop file upload...', dismissable: false });
        window.location.reload();
      }
      this.$emit('canceled');
    },

    async processFilesUpload() {
      this.isUploading = true;

      if (this.files?.length > 0) {
        for (let file of this.files) {
          file._isUploading = true;
          file._isUploaded = false;
          file._isError = false;
          try {
            await this.uploadGcode({
              file: file,
              name: file.name,
              groupId: this.currentGroupId(),
              onUploadProgressCallback: this.updateUploadProgress
            });
          } catch (e) {
            file._isError = true;
            file._errorMessage = e.message;
            this.isError = true;
          }
          file._isUploading = false;
          file._isUploaded = true;
        }

        // need to reload groups as we get gcodes_count from group in various places
        await this.$store.dispatch('account/groups');

        if (!this.isError) {
          this.$refs.uploadGcodeDialog.close();
          this.initDialog();
          this.$emit('uploaded');
        }

        this.isUploading = false
      }
    },

    showDialog() {
      this.$refs.uploadGcodeDialog.showDialog().then((result) => {
        if (!result) {
          this.closeDialog();
        } else if (this.files?.length == 0) {
          this.showDialog();
          throw Error('G-code not selected or invalid.');
        } else if (result && !this.isUploading) {
          this.processFilesUpload();
        } else {
          this.closeDialog();
        }
      });
    },

    onFileInputChange(event) {
      let files = event?.target?.files;
      if (files?.length > 0) {
        for (let file of files) {
          // skip small files (probably it's not file, but directory?)
          if (file?.size > 1000) {
            this.addFile(file);
          }
        }
      }
    },

    initDialog() {
      this.files = [];
      this.isUploading = false;
      this.isError = false;

      if (this.show) {
        this.$nextTick(() => {

          if (this.$refs.fileform) {  // is fileform visible?

            var self = this;
            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
              function(evt) {
                self.$refs.fileform.addEventListener(
                  evt,
                  function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                  }.bind(self),
                  false
                );
              }.bind(self)
            );

            this.$refs.fileform.addEventListener('drop', function(e) {
              e.stopPropagation();
              e.preventDefault();

              if (!this.isError) {
                for (let item of e.dataTransfer.items) {
                  // process directory with files it contains
                  if (item.webkitGetAsEntry().isDirectory) {
                    let dirReader = item.webkitGetAsEntry().createReader();
                    // process files in directory
                    dirReader.readEntries((entries) => {
                      entries.forEach((e) => {
                        if (e.file) {
                          e.file((file) => {
                            this.addFile(file);
                          });
                        }
                      });
                    });

                  // process file
                  } else if (item.webkitGetAsEntry().isFile) {
                    this.addFile(item.getAsFile());
                  }
                }
              }
            }.bind(this));
          }

        });
      }
    },

    addFile(file) {
      // skip some files we know are in directories and we don't want them to process
      if (/(\/|^)(Thumbs\.db|desktop\.ini|\.DS_Store|\..+)$/.test(file.name)) {
        return;
      }

      this.files.push(file);
    },

    removeFile(fileArrayIndex) {
      this.files.splice(fileArrayIndex, 1);
    }
  }
}
</script>
