export function setError(state, val) {
  state.error = val;
}

export function setNotification(state, val) {
  state.notification = val;
}

export function setAnnouncement(state, val) {
  state.announcement = val;
}

export function setTheme(state, val) {
  document.body.classList.remove('light', 'dark');

  if (['light', 'dark'].includes(val)) {
    state.theme = val;
    localStorage.setItem('theme', state.theme);
    document.body.classList.add(state.theme);
  } else {
    state.theme = null;
    localStorage.removeItem('theme');
    const media = window.matchMedia('(prefers-color-scheme: dark)');
    document.body.classList.add(media.matches ? 'dark' : 'light');
  }
}

export function registerOpenedUiModal(state, modalId) {
  if (state.openedUiModals.indexOf(modalId) === -1) {
    state.openedUiModals.push(modalId);
  }
}

export function removeOpenedUiModal(state, modalId) {
  let modalIndex = state.openedUiModals.indexOf(modalId);
  if (modalIndex !== -1) {
    state.openedUiModals.splice(modalIndex, 1);
  }
}
