<template>
  <div class="relative grid content-baseline self-end">
    <div v-if="active" class="absolute left-0 right-0 bg-white-100 dark:bg-gray-800" style="height: 2px; bottom: -2px"></div>
    <router-link
      :to="{ name: routeName, params: routeParams }"
      class="
        top-2
        inline-block
        whitespace-nowrap
        p-2
        mt-1
        border-t border-t-1
        border-l border-l-1
        border-r border-r-1
        dark:border-gray-700
        rounded-t
        shadow
        font-medium
        text-sm
        text-gray-700 dark:text-gray-400
        hover:border-red-400
        hover:no-underline
        select-none
        bg-white-100 dark:bg-gray-800
        h-9
      "
      :class="{ 'active': active }"
      aria-current="page"
      @click="trackClick"
    >
      <span class="truncate">
        <slot></slot>
      </span>
    </router-link>
  </div>
</template>

<style scoped>
.active {
  @apply
    border-red-400 dark:border-red-400
    text-black-900
    dark:text-gray-300
    bg-red-50
    h-10;
}
</style>

<script>
export default {
  name: 'uiNavigationUnderlineTabsItem',

  components: {
  },

  props: {
    routeName: {
      type: String,
      required: false
    },
    routeParams: {
      type: Object,
      required: false
    },
    active: {
      type: Boolean,
      default: false
    },
    dataTitle: {
      type: String,
      required: false
    }
  },
  methods: {
    trackClick() {
      this.$gtag.event('click', {
        'event_category': 'Interaction',
        'event_label': 'Tab',
        'value': this.dataTitle || this.$el.innerText,
      })
    }
  }
}
</script>
