import { CanceledError } from 'axios';


export function throwNetworkUnhandledError(err) {
  if (
    !(err instanceof NetworkOfflineError)
    && !(err instanceof ForbiddenError)
    && !(err instanceof CanceledError)
    ) {
      throw err;
  }
}

export class KarmenError extends Error {
  constructor(title, description, error) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(title, { cause: error })

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, KarmenError)
    }

    this.name = 'KarmenError';
    this.title = title;
    this.description = description;
    this.date = new Date();
  }
}

export class NetworkOfflineError extends Error {
  constructor(message, error) {
    super(message, { cause: error });
    this.name = 'NetworkOfflineError';
  }
}

export class ServerMaintenanceError extends Error {
  constructor(message, error) {
    super(message, { cause: error });
    this.name = 'ServerMaintenanceError';
  }
}

export class ForbiddenError extends Error {
  constructor(message, error) {
    super(message, { cause: error });
    this.name = 'ForbiddenError';
  }
}
