export function setGcodes(state, val) {
  let data = Array.isArray(val?.data?.results) ? val.data.results : [];
  let append = val.append === true;

  // append indicates, that we are loading next page for same query, so data
  // will be only added to previous dataset instead of starting with new empty dataset
  if (append) {
    state.gcodes.results = state.gcodes.results.concat(data);
  } else {
    state.gcodes.results = data;
  }

  state.gcodes.count = val?.data?.count;
  state.gcodes.next = val?.data?.next;
}

export function setGcodeDetail(state, val) {
  state.gcodeDetail = val;

  if (val && state?.gcodes?.results?.length > 0) {
    let existingItemIndex = state?.gcodes?.results?.findIndex((item) => {
      return item.id == val.id;
    });
    if (existingItemIndex >= 0) {
      state.gcodes.results[existingItemIndex] = val;
    }
  }
}

export function setFileLabels(state, val) {
  state.fileLabels = val;
}

export function setVideos(state, val) {
  state.videos = val;
}

export function setVideoDetail(state, val) {
  state.videoDetail = val;
}

export function setPrintJobs(state, val) {
  state.printJobs = val;
}

export function setPrintDialogGcodes(state, val) {
  let data = Array.isArray(val?.data?.results) ? val.data.results : [];
  let append = val.append === true;

  // append indicates, that we are loading next page for same query, so data
  // will be only added to previous dataset instead of starting with new empty dataset
  if (append) {
    state.printDialogGcodes.results = state.printDialogGcodes.results.concat(data);
  } else {
    state.printDialogGcodes.results = data;
  }

  state.printDialogGcodes.count = val?.data?.count;
  state.printDialogGcodes.next = val?.data?.next;
}
