<template>
  <div>
    <authorized-layout>
      <ui-heading back>
        {{ $t('views.timelapseVideos.title') }}

        <template
          v-if="
            $store.getters['account/currentGroup']?.videos_count > 0
            && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)
          "
          v-slot:actionButton
        >
          <div class="flex justify-center space-x-2 sm:space-x-4 -mt-2 sm:mt-0 mr-3 sm:ml-0">
            <template v-if="isSelectable">
              <ui-button
                v-if="selectedVideos"
                @click="deleteSelectedVideos()"
                color="danger"
                :data-title="$t('views.timelapseVideos.deleteSelected')"
              >
                {{ $t('views.timelapseVideos.deleteSelected') }}
              </ui-button>
              <ui-button
                @click="toggleIsSelectable()"
                :data-title="$t('views.settings.cancel')"
              >
                {{ $t('views.settings.cancel') }}
              </ui-button>
            </template>

            <ui-button
              v-else-if="!isSelectable"
              @click="toggleIsSelectable()"
              :data-title="$t('views.timelapseVideos.select')"
            >
              {{ $t('views.timelapseVideos.select') }}
            </ui-button>
          </div>
        </template>
      </ui-heading>

      <ui-page-section-box
        v-if="$store.getters['account/currentGroup']?.videos_count === 0"
        :tight="this.$isMobile()"
      >
        <empty-state
          icon="camera"
          :title="$t('views.timelapseVideos.listEmpty')"
          :ctaSubtitle="$t('views.timelapseVideos.listEmptySubtitle')"
          >
        </empty-state>
      </ui-page-section-box>

      <ui-page-section-box v-if="$store.getters['account/currentGroup']?.videos_count > 0">
        <video-list
          ref="videoListRef"
          :videos="videos"
          :loading="loading"
          :selectable="isSelectable"
          @videosDeleted="onVideosDeleted"
          @deletingVideos="loading=true;"
          @isSelectedVideosChanged="onIsSelectedVideosChanged"
        />
      </ui-page-section-box>

    </authorized-layout>
  </div>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import EmptyState from '@/components/EmptyState.vue';
import VideoList from '@/components/VideoList.vue';

export default {
  name: 'TimelapseVideos',

  components: {
    AuthorizedLayout,
    uiButton,
    uiHeading,
    uiPageSectionBox,
    EmptyState,
    VideoList
  },

  data: () => ({
    loading: true,
    videos: null,
    isSelectable: false,
    selectedVideos: false
  }),

  created: async function() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await this.$store.dispatch('gcodes/loadVideos');
      this.videos = this.$store.getters['gcodes/videos'];
      this.loading = false;
    },

    onVideosDeleted() {
      this.init();
      this.toggleIsSelectable();
    },

    onIsSelectedVideosChanged(isAnyVideoSelected) {
      this.selectedVideos = isAnyVideoSelected;
    },

    toggleIsSelectable() {
      this.isSelectable = !this.isSelectable;
      if (!this.isSelectable) {
        this.$refs.videoListRef.clearSelection();
      }
    },

    deleteSelectedVideos() {
      this.$refs.videoListRef.deleteSelectedVideos();
    }
  }
}
</script>
