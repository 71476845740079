<template>
  <div :class="type">
    <slot></slot>
  </div>
</template>

<style scoped>
  .default {}
  .main {
    @apply min-w-0 flex-1 sm:flex sm:justify-between;
  }
  .right {
    @apply mt-1 flex-shrink-0 sm:mt-0;
  }
  .right-action {
    @apply ml-5 flex-shrink-0 relative;
  }
</style>

<script>
export default {
  name: 'uiGridItemCol',

  props: {
    type: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'main', 'right', 'right-action'].indexOf(value) !== -1;
      }
    }
  }
}
</script>
