export default function () {
  return {
    error: null,
    notification: null,
    announcement: null,
    theme: null,
    openedUiModals: [],  // this value is used for registering visible ui-modal(s), because other components (printer, camera...) needs to know, that they are covered by modal layer
    printersListShowCamera: false,

    filamentTypes: ['PLA', 'PETG', 'ASA', 'ABS', 'PC', 'FLEX', 'HIPS', 'PP', 'OTHER']
  };
}
