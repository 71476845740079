<template>
  <dl class="sm:divide-y sm:divide-gray-200 dark:divide-gray-700">
    <slot></slot>
  </dl>
</template>

<script>
export default {
  name: 'uiDescriptionList',

  components: {},

  props: {}
}
</script>
