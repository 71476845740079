import { CanceledError } from 'axios';
import * as Sentry from '@sentry/vue';

import { NetworkOfflineError, ServerMaintenanceError } from './errors';
import store from './store';


const ErrorService = {
  onError(error) {
    
    let e = error;
    
    // fired by httpClient when request was cancelled - typically becuase of missing authorization tokens
    // we want to skip these errors as they are handled with "logout" action
    if (error.reason instanceof CanceledError) {
      return;
    }

    // show error message dialog
    store.commit('app/setError', e);

    // don't send some specific errors to sentry
    if (
      error instanceof NetworkOfflineError
      || error instanceof ServerMaintenanceError
    ) {
      return;

    } else if (process.env.VUE_APP_SENTRY_ENABLED == 'true') {
      // send error to sentry if enabled
      let user = store.getters['account/user'];
      if (user) {
        Sentry.setUser({
          id: user.id,
          email: user.username
        });
      }

      if (error instanceof PromiseRejectionEvent) {
        Sentry.captureException(e.reason);
      } else {
        Sentry.captureException(e);
      }
    }
  }
}

export default ErrorService;
