<template>
  <span :class="color" class="badge whitespace-nowrap">
    <slot></slot>
  </span>
</template>

<style scoped>
  .badge {
    @apply
      inline-flex items-center
      px-1 py-0.5
      border dark:border-gray-700 rounded-sm
      text-gray-700 dark:text-gray-400 text-2xs font-light leading-none;
    &.default {
      /* @apply bg-gray-100 text-white-100; */
    }
    &.primary,
    &.danger {
      @apply border-red-400 text-red-400;
    }
    &.success {
      @apply border-green-400 text-green-400;
    }
    &.warning {
      @apply border-yellow-400 text-yellow-400;
    }
  }
</style>

<script>
export default {
  name: 'uiBadge',

  props: {
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'primary', 'success', 'warning', 'danger'].indexOf(value) !== -1
      }
    }
  }
}
</script>
