<template>
  <div class="bg-white-100 dark:bg-gray-800">
    <ul>
      <template v-if="loading">
        <ui-grid-item :loading="loading">
          <ui-grid-item-col type="main">
            <ui-grid-item-col-content class="flex-1">
              <ui-loader />
            </ui-grid-item-col-content>
          </ui-grid-item-col>
        </ui-grid-item>
      </template>

      <template v-if="!loading" :loading="loading">
        <slot></slot>
      </template>
    </ul>
  </div>
</template>

<script>
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'uiGrid',

  components: {
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiLoader
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
