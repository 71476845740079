<template>
  <nav
    class="bg-white-100 sm:bg-transparent"
    :class="{ 'dark:bg-gray-800': $isMobile() }"
  >
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'uiAsideMenu'
}
</script>
