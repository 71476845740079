<template>
  <tr>
    <th>{{ $t('views.printerDetail.controlsHeadAdjustment') }}</th>
    <td>
      <div class="flex space-x-8">
        <div class="grid grid-cols-3 gap-0.5">
          <div class="grid-col-start-1"></div>
          <div class="grid-col-start-2">
            <ui-button
              small
              @click.stop="controlMoveHead('y', 10)"
              icon="chevron-up"
              busy
            />
          </div>
          <div class="grid-col-start-3"></div>
          <div class="grid-col-start-1">
            <ui-button
              small
              @click.stop="controlMoveHead('x', -10)"
              icon="chevron-left"
              busy
            />
          </div>
          <div class="grid-col-start-2">
            <ui-button
              small
              @click.stop="controlHomeHead(['x', 'y'])"
              icon="home"
              busy
            />
          </div>
          <div class="grid-col-start-3">
            <ui-button
              small
              @click.stop="controlMoveHead('x', 10)"
              icon="chevron-right"
              busy
            />
          </div>
          <div class="grid-col-start-1"></div>
          <div class="grid-col-start-2">
            <ui-button
              small
              @click.stop="controlMoveHead('y', -10)"
              icon="chevron-down"
              busy
            />
          </div>
          <div class="grid-col-start-3"></div>
        </div>

        <div class="grid grid-cols-1 gap-0.5">
          <ui-button
            small
            @click.stop="controlMoveHead('z', 10)"
            icon="chevron-up"
            busy
          />
          <ui-button
            small
            @click.stop="controlHomeHead(['z'])"
            icon="home"
            busy
          />
          <ui-button
            small
            @click.stop="controlMoveHead('z', -10)"
            icon="chevron-down"
            busy
          />
        </div>
      </div>
    </td>
  </tr>
  <tr v-if="['PAUSED', 'PRINTING'].includes(printer.printerState)">
    <th>{{ $t('views.printerDetail.controlsPrint') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-button
          small
          busy
          v-if="printer.printerState == 'PRINTING'"
          @click.stop="controlPause()"
          color="primary"
        >
          {{ $t('views.printerDetail.controlsPrintPause') }}
        </ui-button>
        <ui-button
          small
          busy
          v-if="printer.printerState == 'PAUSED'"
          @click.stop="controlResume()"
          color="primary"
        >
          {{ $t('views.printerDetail.controlsPrintResume') }}
        </ui-button>
        <ui-button
          small
          busy
          @click.stop="controlCancel()"
          color="danger"
        >
          {{ $t('views.printerDetail.controlsPrintCancel') }}
        </ui-button>
      </div>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsFan') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-button
          small
          busy
          @click.stop="controlSetFan(true)"
        >
          {{ $t('views.printerDetail.controlsFanOn') }}
        </ui-button>
        <ui-button
          small
          busy
          @click.stop="controlSetFan(false)"
        >
          {{ $t('views.printerDetail.controlsFanOff') }}
        </ui-button>
      </div>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsMotors') }}</th>
    <td>
      <ui-button
        small
        busy
        @click.stop="controlDisableMotors()"
      >
        {{ $t('views.printerDetail.controlsMotorsDisable') }}
      </ui-button>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsTool') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-form-input
          v-model:value="toolTemperature"
          id="toolTemperature"
          type="number"
          class="inline-block w-20"
          small
          @keyup.enter="this.$refs.btnSetToolTemperature.$el.focus(); this.$refs.btnSetToolTemperature.$el.click();"
        />
        <ui-button
          ref="btnSetToolTemperature"
          small
          busy
          @click.stop="controlSetTemperature('tool0', toolTemperature)"
        >
          {{ $t('views.printerDetail.controlsSet') }}
        </ui-button>
        <div v-if="printer?.temperatures?.tool0?.target > 0" class="mt-1">{{ printer?.temperatures?.tool0?.actual?.toFixed(1) }} &raquo; {{ printer?.temperatures?.tool0?.target?.toFixed(1) }} °C<br></div>
      </div>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsBed') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-form-input
          v-model:value="bedTemperature"
          id="bedTemperature"
          type="number"
          class="inline-block w-20"
          small
          @keyup.enter="this.$refs.btnSetBedTemperature.$el.focus(); this.$refs.btnSetBedTemperature.$el.click();"
        />
        <ui-button
          ref="btnSetBedTemperature"
          small
          busy
          @click.stop="controlSetTemperature('bed', bedTemperature)"
        >
          {{ $t('views.printerDetail.controlsSet') }}
        </ui-button>
        <div v-if="printer?.temperatures?.bed?.target > 0" class="mt-1">{{ printer?.temperatures?.bed?.actual?.toFixed(1) }} &raquo; {{ printer?.temperatures?.bed?.target?.toFixed(1) }} °C</div>
      </div>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsMaterial') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-form-input
          v-model:value="moveMaterialLength"
          id="moveMaterialLength"
          type="number"
          class="inline-block w-20"
          small
        />
        <ui-button
          small
          busy
          @click.stop="controlMoveMaterial('extrude', moveMaterialLength)"
        >
          {{ $t('views.printerDetail.controlsMaterialExtrude') }}
        </ui-button>
        <ui-button
          small
          busy
          @click.stop="controlMoveMaterial('retract', moveMaterialLength)"
        >
          {{ $t('views.printerDetail.controlsMaterialRetract') }}
        </ui-button>
      </div>
    </td>
  </tr>
  <tr>
    <th>{{ $t('views.printerDetail.controlsCommand') }}</th>
    <td>
      <div class="flex space-x-3">
        <ui-form-textarea
          v-model:value="printerCommands"
          id="printerCommands"
          :rows="2"
          small
        />
        <div class="grid place-items-center">
          <ui-button
            class="object-bottom"
            small
            busy
            @click.stop="controlsSendCommand(printerCommands)"
          >
            {{ $t('views.printerDetail.controlsSendCommand') }}
          </ui-button>
        </div>
      </div>
    </td>
  </tr>
</template>

<style scoped>
  .emptyCell {
    @apply w-10;
  }
  .actionCell {
    @apply w-10 hover:bg-red-700 border border-gray-200 text-center;
  }
</style>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import { mapActions, mapMutations } from 'vuex';
import { KarmenError } from '@/errors';
import uiFormTextarea from '@/components/ui/uiFormTextarea.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';

export default {
  name: 'PrinterControls',

  components: {
    uiButton,
    uiFormInput,
    uiFormTextarea,
  },
  emits: ['busy'],
  props: {
    printer: {
      type: Object,
      default: null,
      required: true
    },
  },

  data: () => ({
    toolTemperature: 0,
    bedTemperature: 0,
    moveMaterialLength: 5,
    printerCommands: ''
  }),

  methods: {
    ...mapActions('printers', ['executeControlCommand']),
    ...mapMutations('app', { setNotification: 'setNotification' }),

    executePrinterCommand: async function(action, payload) {
      try {
        await this.executeControlCommand({
          printerId: this.printer.id,
          action: action,
          obj: payload
        });
      } catch (e) {
        if ( e?.response?.status == 408 && e?.response.data?.error?.code == 'axis-homing-required') {
          this.setNotification({
            title: 'Move error',
            text: 'Must home axis first.'
          });
        } else if ( e?.response?.status == 408 && e?.response.data?.error?.code == 'out-of-range') {
          this.setNotification({
            title: 'Move error',
            text: 'Out of range.'
          });
        } else {
          throw new KarmenError(
            'Printer control command error (' + action + ')',
            'Error executing action ' + action + ' with payload ' + JSON.stringify(payload),
            e
          );
        }
      }
    },

    checkPrinterStateReadyToPrint: function() {
      if (this.printer?.printerState != 'READY_TO_PRINT') {
        throw new KarmenError(
          'Can`t execute this command.',
          'Please check your printer is not printing. Current printer status is ' + this.printer?.printerState
        );
      }
    },

    controlMoveHead: function(axis, length) {
      this.checkPrinterStateReadyToPrint();
      let payload = { absolute: false };
      payload[axis] = length;
      this.executePrinterCommand('move_head', payload);
    },

    controlHomeHead: function(axes) {
      this.checkPrinterStateReadyToPrint();
      let payload = { axes: axes };
      this.executePrinterCommand('home_head', payload);
    },

    controlSetTemperature: function(tool, temperature) {
      let payload = { part: tool, temperature: temperature };
      this.executePrinterCommand('temperature', payload);
    },

    controlSetFan: function(state) {
      let payload = { state: state ? 'on' : 'off' };
      this.executePrinterCommand('fan_state', payload);
    },

    controlDisableMotors: function() {
      this.checkPrinterStateReadyToPrint();
      this.executePrinterCommand('disable_motors');
    },

    controlPause: function() {
      this.executePrinterCommand('pause');
    },

    controlResume: function() {
      this.executePrinterCommand('resume');
    },

    controlCancel: function() {
      this.executePrinterCommand('cancel');
    },

    controlMoveMaterial: function(action, length) {
      this.checkPrinterStateReadyToPrint();
      if (action == 'extrude') {
        length = Math.abs(length);
      } else if (action == 'retract') {
        length = -Math.abs(length);
      } else {
        throw new KarmenError(
          'Invalid printer control MoveMaterial action - ' + action,
          'error',
          { action: action, length: length }
        );
      }
      this.executePrinterCommand('extrude', { amount: length });
    },

    controlsSendCommand: function(commands) {
      let lines = commands.replace(/\r\n/g,'\n').split('\n');
      lines = lines.filter(n => n);  // remove empty lines
      if (lines.length > 0) {
        this.executePrinterCommand('send_command', {commands: lines});
      }
    }
  }
}
</script>
