export default {
  install: (app) => {
    // inject a globally available $printDialogService() method
    app.config.globalProperties.$printDialogService = {

      // callback to be called from showPrintDialog() function
      callback_onShowRequest: null,
      callback_onDialogEvent: null,

      // show print dialog
      showPrintDialog: function (payload) {
        if (this.callback_onShowRequest) {
          this.callback_onShowRequest(payload);
        }
      },

      // should be called when there is event from dialog (close, cancelled, started, error)
      reportDialogEvent: function (event, data) {
        if (this.callback_onDialogEvent) {
          this.callback_onDialogEvent(event, data);
        }
      },

      // set callback, that should be called after print dialog is shown
      setOnShowDialogRequestCallback: function (callback) {
        this.callback_onShowRequest = callback;
      },

      setOnDialogEventCallback: function (callback) {
        this.callback_onDialogEvent = callback;
      }
    }
  }
}
