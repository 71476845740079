// These are permissions implemented on karmen-backend.

export default {
    VIEW_GROUP: 'VIEW_GROUP',
    CHANGE_GROUP: 'CHANGE_GROUP',
    VIEW_GROUP_MEMBERS: 'VIEW_GROUP_MEMBERS',
    MANAGE_GROUP_MEMBERS: 'MANAGE_GROUP_MEMBERS',
    OPERATE_PRINTERS: 'OPERATE_PRINTERS',
    CHANGE_FILES: 'CHANGE_FILES',
    CHANGE_UPLOADED_FILES: 'CHANGE_UPLOADED_FILES',
    UPLOAD_FILE: 'UPLOAD_FILE'
}
