<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>
        {{ $t('views.settings.notifications.title') }}
      </ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>

      <ui-page-section-box tight class="p-4">
        {{ $t('views.settings.notifications.infoText') }}
      </ui-page-section-box>

      <ui-page-section-box v-if="loading">
        <ui-loader />
      </ui-page-section-box>

      <div v-if="!loading" class="space-y-8">
        <ui-page-section-box
          tight
          v-for="section in groups"
          :key="section.id"
        >
          <ui-heading secondary class="px-4 sm:px-6">
            {{ section.name }}
            <template v-slot:actionButton>
              <div v-if="!loading" class="space-x-2 whitespace-nowrap">
                <ui-button
                  v-if="!section.settingsChanged"
                  @click="testNotification(section.notificationsSettings?.data?.id)"
                  color="default"
                  small
                  class="whitespace-nowrap"
                >
                  Send test notification
                </ui-button>
                <ui-button
                  v-if="section.settingsChanged"
                  @click="cancelChanges(section)"
                  color="default"
                  small
                >
                  {{ $t('views.settings.notifications.cancelBtn') }}
                </ui-button>
                <ui-button
                  :disabled="section.settingsChanged ? false : true"
                  @click="saveGroupNotificationSettings(section)"
                  color="primary"
                  small
                >
                  {{ $t('views.settings.notifications.saveBtn') }}
                </ui-button>
              </div>
            </template>
          </ui-heading>

          <div class="pb-6 mx-6">
            <ui-loader v-if="section.loading" />
            <div v-if="section?.notificationsSettings?.data && section.loading === false">

              <!-- TELEGRAM SETTINGS -->
              <div class="text-sm border-b dark:border-gray-700 pb-4 mb-4">
                <label class="block cursor-pointer">
                  <input
                    v-model="section.notificationsSettings.data.telegram.enabled"
                    type="checkbox"
                    class="h-4 w-4 mr-2 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 bg-white-100 dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 cursor-pointer"
                    @click="section.settingsChanged=true"
                  />
                  {{ $t('views.settings.notifications.telegramTitle') }}: {{ section.notificationsSettings.data.telegram.enabled ? $t('views.settings.notifications.enabledText') : $t('views.settings.notifications.disabledText') }}
                </label>
                <div class="ml-8 my-2" v-if="section.notificationsSettings?.data?.telegram?.enabled">

                  <!-- Printjob video failure alert -->
                  <label class="block cursor-pointer">
                    <input
                      v-model="section.notificationsSettings.data.telegram.printjob_video_failure_alert"
                      type="checkbox"
                      class="h-4 w-4 mr-2 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 bg-white-100 dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 cursor-pointer"
                      @click="section.settingsChanged=true"
                    />
                    {{ $t('views.settings.notifications.printjob_video_failure_alertTitle') }}
                  </label>

                  <!-- Printjob done -->
                  <label class="block cursor-pointer">
                    <input
                      v-model="section.notificationsSettings.data.telegram.printjob_event_done"
                      type="checkbox"
                      class="h-4 w-4 mr-2 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 bg-white-100 dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 cursor-pointer"
                      @click="section.settingsChanged=true"
                    />
                    {{ $t('views.settings.notifications.printjob_event_doneTitle') }}
                  </label>

                </div>
                <div v-if="!isTelegramEnabled && section.notificationsSettings.data.telegram.enabled" class="ml-6 mt-4">
                  <ui-alert color="default" icon="information-circle">
                    <div v-html="$t('views.settings.notifications.telegramNotSetupText')"></div>
                  </ui-alert>
                </div>
              </div>

              <!-- EMAIL SETTINGS -->
              <div class="text-sm">
                <label class="block cursor-pointer">
                  <input
                    v-model="section.notificationsSettings.data.email.enabled"
                    type="checkbox"
                    class="h-4 w-4 mr-2 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 bg-white-100 dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 cursor-pointer"
                    @click="section.settingsChanged=true"
                  />
                  {{ $t('views.settings.notifications.emailTitle') }}: {{ section.notificationsSettings.data.email.enabled ? $t('views.settings.notifications.enabledText') : $t('views.settings.notifications.disabledText') }}
                </label>
                <div class="ml-8 my-2" v-if="section.notificationsSettings?.data?.email?.enabled">

                  <!-- Printjob video failure alert -->
                  <label class="block cursor-pointer">
                    <input
                      v-model="section.notificationsSettings.data.email.printjob_video_failure_alert"
                      type="checkbox"
                      class="h-4 w-4 mr-2 border border-gray-200 dark:border-gray-700 rounded-sm text-gray-700 bg-white-100 dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 cursor-pointer"
                      @click="section.settingsChanged=true"
                    />
                    {{ $t('views.settings.notifications.printjob_video_failure_alertTitle') }}
                  </label>

                </div>
              </div>

            </div>
          </div>
        </ui-page-section-box>
      </div>
    </template>
  </authorized-col-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiAlert from '@/components/ui/uiAlert.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';

export default {
  name: 'SettingsPrinters',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiHeading,
    uiButton,
    uiLoader,
    uiAlert,
    SettingsNavigation
  },

  data: () => ({
    items: [],
    itemId: null,
    loading: true,
    showGroupsFilter: false,
    groups: [],
    showAddPrinterDialog: false,
    selectedGroupId: null,
    isTelegramEnabled: false
  }),

  created: function() {
    this.init();
  },

  methods: {
    ...mapActions('account', { loadGroups: 'groups' }),
    ...mapActions('account', { getNotificationsSettings: 'userInGroupNotificationSettings' }),
    ...mapActions('account', { updateNotificationsSettings: 'updateUserInGroupNotificationSettings' }),
    ...mapActions('account', { sendTestNotification: 'sendTestNotification' }),
    ...mapGetters('account', { getGroups: 'groups' }),
    ...mapGetters('account', { user: 'user' }),

    async init() {
      this.loading = true;

      this.isTelegramEnabled = this.user().is_telegram;

      await this.loadGroups();
      this.groups = structuredClone(this.getGroups());
      this.loading = false;

      await this.loadGroupsNotificationsSettingsData();
    },

    async loadGroupsNotificationsSettingsData() {
      for (let group of this.groups) {
        if (group.loading == undefined) {
          group.loading = true;
        }
        this.loadGroupSettings(group);
      }
    },

    cancelChanges(group) {
      group.loading = true;
      this.loadGroupSettings(group);
    },

    async loadGroupSettings(group) {
      group.notificationsSettings = await this.getNotificationsSettings(group.id)
      group.settingsChanged = false;
      group.loading = false;
    },

    async saveGroupNotificationSettings(group) {
      group.loading = true;
      group.settingsChanged = false;
      await this.updateNotificationsSettings(group.notificationsSettings.data);
      await this.loadGroupsNotificationsSettingsData();
    },

    testNotification(notificationSettingsId) {
      if (notificationSettingsId) {
        this.sendTestNotification(notificationSettingsId);
      }
    }
  }
}
</script>
