import httpClient from '@/httpClient';
import { throwNetworkUnhandledError } from '@/errors';

export function loadPrintQueue({ commit, rootGetters }) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/print-queue/';
    return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
      commit('setPrintQueue', response?.data);
    }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}

export function createItem({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/print-queue/';
    let obj = {
      file: payload.gcodeId,
      state: 'to-be-printed',
      printer: payload.printerId == null || payload.printerId == '' ? undefined : payload.printerId,
      jobox_autostart: payload.joboxAutostart
    };
    return httpClient.post(url, obj, { authorizeRequest: true });
  }
}

export function patchItem({ rootGetters }, payload) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/print-queue/' + payload.id + '/';
    let obj = {
      state: payload.state == null ? undefined : payload.state,
      print_job: payload.printJob == null ? undefined : payload.printJob,
      printer: payload.printerId === null || payload.printerId == '' ? null : payload.printerId,
      jobox_autostart: payload.joboxAutostart === undefined ? undefined : Boolean(payload.joboxAutostart)
    };
    return httpClient.patch(url, obj, { authorizeRequest: true });
  }
}

export function deleteItem({ rootGetters }, itemId) {
  let groupId = rootGetters['account/currentGroupIdOrLogout'];
  if (groupId) {
    let url = '/groups/' + groupId + '/print-queue/' +  itemId + '/';
    return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }
}
