<template>
  <div class="min-h-screen flex flex-col justify-center">
    <ui-announcement-bar class="fixed w-full top-0" />

    <div class="sm:mx-auto sm:w-full sm:max-w-md mb-6">
      <transition name="layoutTransition" appear>
        <div>
          <router-link :to="{ name: 'Logout' }"><img class="mx-auto h-16 sm:h-28 w-auto" src="/karmen-logo.svg" alt="Karmen.tech logo"></router-link>
        </div>
      </transition>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <ui-page-section-box tight class="space-y-4 m-4 pb-8">
        <h2 class="block pt-12 text-center text-lg md:text-xl font-bold text-gray-700 dark:text-gray-400">
          {{ headerText }}
        </h2>
        <div class="h-16 text-center" :class="{ 'hidden': !showError }">
          <ui-form-error :show="showError" :text="errorHeaderText"></ui-form-error>
        </div>
        <transition name="layoutTransition" appear>
          <div>
            <slot></slot>
          </div>
        </transition>
        <div v-if="loading" class="absolute flex justify-center content-center h-screen w-screen bg-white-100 dark:bg-black-900 opacity-75 pointer-events-none inset-0">
          <ui-loader class="self-center" />
        </div>
      </ui-page-section-box>
    </div>
  </div>
</template>

<style scoped>
.layoutTransition-enter-active,
.layoutTransition-leave-active { @apply transform transition ease-in duration-300; }
.layoutTransition-enter-from,
.layoutTransition-leave-to { @apply opacity-0; }
.layoutTransition-enter-to,
.layoutTransition-leave-from { @apply opacity-100; }
</style>

<script>
import uiAnnouncementBar from '@/components/ui/uiAnnouncementBar.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiFormError from '@/components/ui/uiFormError.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';

export default {
  name: 'UnauthorizedLayout',

  components: {
    uiAnnouncementBar,
    uiLoader,
    uiFormError,
    uiPageSectionBox
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    errorHeaderText: {
      type: String,
      default: null
    },
    headerText: {
      type: String,
      default: null
    }
  },
}
</script>
