<template>
  <unauthorized-layout
    headerText="Logging out..."
    :loading="true"
  >
    <div class="pb-5"></div>
  </unauthorized-layout>
</template>

<script>
import UnauthorizedLayout from '@/components/layouts/UnauthorizedLayout.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Logout',

  components: {
    UnauthorizedLayout
  },

  created: function() {
    this.logout().then(() => {
      this.$router.push({ name: 'Login', query: { forceReload: true } });
    }).catch((e) => {
      this.$router.push({ name: 'Login' });
      if (e.response?.status != 401) {
        throw e;
      }
    });
  },

  methods: {
    ...mapActions('account', ['logout'])
  }
}
</script>
