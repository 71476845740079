import { createStore } from 'vuex';

import app from './app';

import account from './account';
import gcodes from './gcodes';
import printers from './printers';
import printQueue from './printQueue';
import printerGroups from './printerGroups';

export default createStore({
  modules: {
    app: app,
    account: account,
    gcodes: gcodes,
    printers: printers,
    printQueue: printQueue,
    printerGroups: printerGroups
  },
  // enable strict mode for dev mode only (adds overhead!)
  strict: process.env.NODE_ENV === 'development'
});
