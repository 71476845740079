<template>
  <dt class="text-sm font-medium text-gray-700 dark:text-gray-400">
    <slot></slot>
  </dt>
</template>

<script>
export default {
  name: 'uiDescriptionListTerm',

  components: {},

  props: {}
}
</script>
