<template>
  <authorized-layout>
    <ui-heading back v-if="!loading">
      <template v-if="!$isMobile()">
        {{ gcodeData.name }}
      </template>
      <template v-else>
        {{ $t('views.gcodeDetail.title') }}
      </template>
    </ui-heading>

    <div v-if="loading" class="mt-32 mb-32">
      <ui-loader />
    </div>

    <ui-page-section-box tight class="lg:flex lg:p-6" v-if="!loading && gcodeData">
      <div
        v-if="!loading"
        class="w-full lg:w-1/2 self-start p-2 lg:p-0"
        :class="{
          'h-36': !$filters.previewImageExists(gcodeData.previewImages)
        }"
      >
        <div class="border border-1 border-gray-400 dark:border-0 rounded-md h-full w-full">
          <gcode-preview-image
            :color="gcodeData.filament_color"
            :gcodeId="gcodeData.id"
            :previewExists="$filters.previewImageExists(gcodeData.previewImages)"
            :previewImageId="$filters.primaryPreviewImage(gcodeData.previewImages)?.id"
            :images="gcodeData.previewImages"
          ></gcode-preview-image>
        </div>
      </div>

      <div class="p-4 lg:p-0 lg:pl-8 lg:w-1/2">
        <ui-form @submit="save()">
          <ui-description-list>
            <ui-description-list-item
              :class="{
                'py-4 sm:py-5': !editMode,
                'py-2 sm:py-3': editMode
              }"
            >
              <ui-description-list-term :class="{'py-2': editMode}">
                {{ $t('views.gcodeDetail.txtName') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                <template v-if="!editMode">
                  <span class="break-all dark:text-white-100">
                    {{ gcodeData.name }}
                  </span>
                </template>

                <ui-form-input
                  v-else-if="editMode"
                  @input="validateForm"
                  v-model:value="gcodeData.name"
                  id="name"
                  :required="true"
                  class="absolute"
                ></ui-form-input>
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item>
              <ui-description-list-term>
                {{ $t('views.printer.filamentType') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                <filament-type
                  enableColors
                  :name="gcodeData.filament_type"
                  :color="gcodeData.filament_color"
                  @typeChange="onFilamentTypeChange($event, gcodeData.id)"
                  @colorChange="onFilamentColorChange($event, gcodeData.id)"
                  :hasPermission="
                    $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_FILES)
                    || (
                      // user can change own files
                      $store.getters['account/hasPermission']($PERMISSIONS.CHANGE_UPLOADED_FILES)
                      && gcodeData.uploadedBy.username == this.$store.getters['account/user'].username
                    )
                  "
                />
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item v-if="gcodeData.printing_time_sec > 0">
              <ui-description-list-term>
                {{ $t('views.gcodes.titlePrintingTime') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ $filters.secondsToPrintTime(gcodeData.printing_time_sec) }}
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item v-if="gcodeData.layer_height_mm > 0">
              <ui-description-list-term>
                {{ $t('views.gcodes.titleLayerHeight') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ gcodeData.layer_height_mm }}mm
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item v-if="gcodeData.filament_used_mm > 0">
              <ui-description-list-term>
                {{ $t('views.gcodes.titleFilamentUsed') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ $filters.milimitersToHumanReadable(gcodeData.filament_used_mm) }} / {{ $filters.gramsToHumanReadable(gcodeData.filament_used_g) }}
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item v-if="gcodeData.nozzle_diameter_mm > 0">
              <ui-description-list-term>
                {{ $t('views.gcodes.titleNozzleDiameter') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ gcodeData.nozzle_diameter_mm }}mm
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item>
              <ui-description-list-term>
                {{ $t('views.gcodeDetail.txtLabels') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                <template v-if="gcodeData.fileLabels.length === 0">
                  <i>
                    {{ $t('views.gcodeDetail.txtNoLabels') }}
                  </i>
                </template>
                <router-link
                  v-else
                  v-for="item of gcodeData.fileLabels"
                  :key="item.id"
                  :to="{ name: 'Gcodes', query: { labels: item.id } }"
                  class="cursor-pointer"
                >
                  <ui-badge
                    class="mr-1"
                  >{{ item.name }}</ui-badge>
                </router-link>
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item>
              <ui-description-list-term>
                {{ $t('views.gcodeDetail.txtUploadedBy') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ gcodeData.uploadedBy.username }}
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item>
              <ui-description-list-term>
                {{ $t('views.gcodeDetail.txtUploadedOn') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ $filters.dateTimeFormat(gcodeData.uploadedOn) }}
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item>
              <ui-description-list-term>
                {{ $t('views.gcodeDetail.txtSize') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                {{ $filters.humanFileSize(gcodeData.size) }}
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item
              :class="{
                'py-4 sm:py-5': !editMode,
                'py-1 sm:py-2': editMode
              }"
            >
              <ui-description-list-term
                :class="{'py-3': editMode}"
              >
                {{ $t('views.gcodeDetail.txtNote') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                <template v-if="!editMode">
                  <p class="whitespace-pre-line">
                  {{ gcodeData.note }}
                  </p>
                </template>

                <ui-form-textarea
                  v-if="editMode"
                  v-model:value="gcodeData.note"
                  id="note"
                  :maxlength="2048"
                ></ui-form-textarea>
              </ui-description-list-definition>
            </ui-description-list-item>

            <ui-description-list-item v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)">
              <ui-description-list-term >
                {{ $t('views.gcodeDetail.isPublic') }}
              </ui-description-list-term>
              <ui-description-list-definition>
                <ui-button-switch
                  id="is_public"
                  v-model:value="gcodeData.is_public"
                  :automaticValueChange="false"
                  @click.stop="handleConfirmSetPublicDialog()"
                />
              </ui-description-list-definition>
            </ui-description-list-item>

          </ui-description-list>
        </ui-form>

        <div class="space-x-2 mt-6">
          <template v-if="!editMode">
            <ui-button
              v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
              @click="openPrintDialog(gcodeData)"
              color="primary"
            >
              {{ $t('views.gcodeDetail.btnPrint') }}
            </ui-button>

            <ui-button
              v-if="
                $store.getters['account/currentGroup']?.settings?.print_queue_enabled
                && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)
              "
              @click="showPrintqueueItemDialog = true;"
            >
              {{ $t('views.gcodeDetail.btnAddToPrintQueue') }}
            </ui-button>

            <ui-button @click="download(gcodeData.id)">{{ $t('views.gcodeDetail.btnDownload') }}</ui-button>

            <template v-if="
              this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)
              || (
                this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_UPLOADED_FILES)
                && gcodeData.uploadedBy.username == this.$store.getters['account/user'].username
              )"
            >
              <ui-button @click="edit()">{{ $t('views.gcodeDetail.btnEdit') }}</ui-button>
              <ui-button @click="isVisibleDeleteDialog = true" color="clear">{{ $t('views.gcodeDetail.btnDelete') }}</ui-button>
            </template>
          </template>
          <template v-else-if="editMode">
            <ui-button @click="save(gcodeData.id)" :disabled='!isValid' color="primary">{{ $t('components.modal.save') }}</ui-button>
            <ui-button @click="gcodeData.name=origName;gcodeData.note=origNote;cancel()">{{ $t('components.modal.cancel') }}</ui-button>
          </template>
        </div>
      </div>
    </ui-page-section-box>

    <div v-if="!loading">
      <ui-navigation-underline-tabs class="mt-6">
        <ui-navigation-underline-tabs-item
          @click.prevent="showVideos=false;showPrintHistory=true;"
          :active="showPrintHistory"
          data-title="Gcode Detail: PrintHistory"
        >
          {{ $t('views.gcodeDetail.titlePrintHistory') }}
        </ui-navigation-underline-tabs-item>
        <ui-navigation-underline-tabs-item
          @click.prevent="showVideos=true;showPrintHistory=false;"
          :active="showVideos"
          data-title="Gcode Detail: Videos"
        >
          {{ $t('views.gcodeDetail.titleVideos') }}
        </ui-navigation-underline-tabs-item>
      </ui-navigation-underline-tabs>

      <ui-page-section-box tight>
        <print-jobs-list v-if="showPrintHistory" :fileId="gcodeData.id" :showFileName="false" />

        <div class="p-4 sm:p-6" v-if="showVideos">
          <video-list :loading="loadingVideos" :videos="videos" />
        </div>
      </ui-page-section-box>
    </div>

    <delete-files-confirm-dialog
      :gcodesToDelete="[ gcodeData ]"
      :show="isVisibleDeleteDialog"
      @canceled="handleDeleteConfirmDialogEvent('canceled')"
      @deleting="handleDeleteConfirmDialogEvent('deleting')"
      @finished="handleDeleteConfirmDialogEvent('finished')"
    >
    </delete-files-confirm-dialog>

    <ui-confirm-dialog
      ref="confirmChangePublicDialog"
      :headerText="$t('views.gcodeDetail.confimDialogTitle')"
      :confirmBtnText="$t('components.modal.confirm')"
      :dismissBtnText="$t('components.modal.doNothing')"
    >
      <p>{{ $t('views.gcodeDetail.confimDialogMessageToPublic') }}</p>
    </ui-confirm-dialog>

    <print-queue-item-dialog :show="showPrintqueueItemDialog" @canceled="showPrintqueueItemDialog = false" @saved="onPrintQueueItemDialogSaved()" :gcodeId="gcodeDetail?.id" />

  </authorized-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiButtonSwitch from '@/components/ui/uiButtonSwitch.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiDescriptionList from '@/components/ui/uiDescriptionList.vue';
import uiDescriptionListItem from '@/components/ui/uiDescriptionListItem.vue';
import uiDescriptionListTerm from '@/components/ui/uiDescriptionListTerm.vue';
import uiDescriptionListDefinition from '@/components/ui/uiDescriptionListDefinition.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiFormTextarea from '@/components/ui/uiFormTextarea.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiNavigationUnderlineTabs from '@/components/ui/uiNavigationUnderlineTabs.vue';
import uiNavigationUnderlineTabsItem from '@/components/ui/uiNavigationUnderlineTabsItem.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import DeleteFilesConfirmDialog from '@/components/DeleteFilesConfirmDialog.vue';
import VideoList from '@/components/VideoList.vue';
import { KarmenError } from '@/errors';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';
import PrintQueueItemDialog from '@/components/PrintQueueItemDialog.vue';
import PrintJobsList from '@/components/PrintJobsList.vue';
import FilamentType from '@/components/FilamentType.vue';

export default {
  name: 'GcodeDetail',

  components: {
    AuthorizedLayout,
    uiButton,
    uiButtonSwitch,
    uiConfirmDialog,
    uiBadge,
    uiDescriptionList,
    uiDescriptionListItem,
    uiDescriptionListTerm,
    uiDescriptionListDefinition,
    uiForm,
    uiFormInput,
    uiFormTextarea,
    uiHeading,
    uiLoader,
    uiNavigationUnderlineTabs,
    uiNavigationUnderlineTabsItem,
    uiPageSectionBox,
    DeleteFilesConfirmDialog,
    VideoList,
    GcodePreviewImage,
    PrintQueueItemDialog,
    PrintJobsList,
    FilamentType
  },

  props: ['id'],

  computed: {
    ...mapGetters('gcodes', ['gcodeDetail'])
  },

  watch: {
    gcodeDetail() {
      this.gcodeData = structuredClone(this.$store.getters['gcodes/gcodeDetail']);
    }
  },

  data: () => ({
    loading: true,
    loadingPrintJobHistory: true,
    loadingVideos: true,
    isVisibleDeleteDialog: false,
    isValid: true,
    editMode: false,
    origName: '',
    origNote: '',
    gcodeData: null,
    videos: [],
    showVideos: false,
    showPrintHistory: true,
    showPrintqueueItemDialog: false
  }),

  created: async function() {
    this.init();

    // load videos
    await this.$store.dispatch('gcodes/loadVideosByFileId', {id: this.id});
    this.videos = this.$store.getters['gcodes/videos'];
    this.loadingVideos = false;
  },

  methods: {
    async init() {
      this.loading = true;

      // load gcode detail
      try {
        await this.$store.dispatch('gcodes/loadGcodeDetail', {id: this.id});
        this.loading = false;
      } catch(e) {
        if (e?.response?.status == 404) {
          this.$store.commit('app/setNotification', ({
            title: 'Gcode not found',
            text: 'Gcode does not exists.',
            onClose: () => {
              this.$router.push({ name: 'Gcodes' });
            }
          }));
        } else {
          throw new KarmenError(
            'Gcode problem',
            'Error loading gcode detail.',
            e
          );
        }
        return;
      }
    },

    download(gcodeId) {
      window.open(this.$store.getters['gcodes/gcodeDownloadLink'](gcodeId));
    },

    edit() {
      this.editMode = !this.editMode;
      this.origName = this.gcodeData.name;
      this.origNote = this.gcodeData.note;
    },

    validateForm() {
      this.isValid = !!this.gcodeData.name;
      return this.isValid;
    },

    async save() {
      if (this.isValid) {
        await this.$store.dispatch('gcodes/updateGcode',{
          id: this.gcodeData.id,
          name: this.gcodeData.name,
          note: this.gcodeData.note
        });
        this.editMode = false;
      }
    },

    cancel() {
      this.editMode = false;
    },

    handleDeleteConfirmDialogEvent(event) {
      this.isVisibleDeleteDialog = false;
      if (event == 'deleting') {
        this.loading = true;
      } else if (event == 'finished') {
        this.$router.push({ name: 'Gcodes' });
      }
    },

    async handleConfirmSetPublicDialog() {
      if (this.gcodeData.is_public) {
        // if "is_public = true", automatically set "is_public = false" - don't show confirmation dialog
        this.loading = true;
        await this.$store.dispatch('gcodes/updateGcodeIsPublic', {id: this.gcodeData.id, isPublic: false});
        await this.init();
      } else {
        // show confirm dialog when "is_public = false"
        let result = await this.$refs.confirmChangePublicDialog.showDialog();
        if (result) {
          // update "is_public = true"
          this.loading = true;
          await this.$store.dispatch('gcodes/updateGcodeIsPublic', {id: this.gcodeData.id, isPublic: true});
          await this.init();
        }
      }
    },

    onPrintQueueItemDialogSaved() {
      this.$router.push({ name: 'PrintQueue' });
    },

    openPrintDialog(item) {
      this.$printDialogService.showPrintDialog({ gcodeId: item.id });
    },

    async onFilamentTypeChange(filamentType, gcodeId) {
      await this.$store.dispatch('gcodes/updateGcode', { id: gcodeId, filament_type: filamentType });
    },

    async onFilamentColorChange(filamentColor, gcodeId) {
      await this.$store.dispatch('gcodes/updateGcode', { id: gcodeId, filament_color: filamentColor });
    }
  },

  beforeUnmount: function() {
    this.$store.commit('gcodes/setGcodeDetail', null);
  }
}
</script>
