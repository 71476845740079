<template>
  <div>
    <authorized-layout v-if="item">
      <ui-heading>
        {{ item.title }}
      </ui-heading>
      <ui-page-section-box>
        <div v-if="item.date" class="w-full text-right text-sm"><time :datetime="item.publish_from">{{ $filters.dateFormat(item.date) }}</time></div>

        <div v-html="formatContent(item.content)" class="markdownContent"></div>

        <div class="flex space-x-2 mt-10 justify-end">
          <ui-button color="primary" @click="goBack()">{{ $t('views.news.detailBtnBack') }}</ui-button>
          <ui-button color="clear" @click="setAsUnread()">{{ $t('views.news.detailBtnMarkAsUnread') }}</ui-button>
        </div>
      </ui-page-section-box>
    </authorized-layout>
  </div>
</template>

<style scoped>
.markdownContent {
  @apply mx-4 text-sm text-gray-700 dark:text-white-100;

  :deep(h1) {
    @apply text-xl font-bold pt-4;
  }

  :deep(h2) {
    @apply text-base font-bold pt-4;
  }

  :deep(p) {
    @apply my-2;
  }

  :deep(a) {
    @apply underline text-red-400;
  }

  :deep(ol) {
    @apply list-decimal space-y-2 ml-8 my-4;
  }
}
</style>

<script>
import { marked } from 'marked';

import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import uiButton from '@/components/ui/uiButton.vue';

export default {
  name: 'NewsDetail',

  components: {
    AuthorizedLayout,
    uiHeading,
    uiPageSectionBox,
    uiButton
  },

  props: {
    id: {
      type: String,
      default: null,
      required: true
    }
  },

  data: () => ({
    item: null
  }),

  created: async function() {
    await this.$store.dispatch('account/loadNews');
    this.item = this.$store.getters['account/newsById'](this.id);
    await this.$store.dispatch('account/updateNewsIsReadStatus', { newsId: this.id, isRead: true });
    this.$store.dispatch('account/loadNews'); // reload news list as read status of this news item has changed
  },

  methods: {
    formatContent(content) {
      return marked.parse(content);
    },

    async setAsUnread() {
      await this.$store.dispatch('account/updateNewsIsReadStatus', { newsId: this.id, isRead: false });
      await this.$store.dispatch('account/loadNews');
      this.goBack();
    },

    goBack() {
      this.$router.push({ name: 'News' });
    }
  }
}
</script>
