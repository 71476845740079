import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueMobileDetection from 'vue-mobile-detection';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import Smartlook from 'smartlook-client';

import App from './App.vue';
import router from './router';
import store from './store';
import vueFilters from '@/vueFilters';
import { i18n } from './i18n.js';
import permissions from './permissions';

import * as Sentry from '@sentry/vue';
import ErrorService from './errorService';
import printDialogService from './printDialogService';
import asyncTasksService from './asyncTasksService';
import printersService from './printersService';

import mitt from 'mitt';

import './assets/styles/tailwind.css';
import './registerServiceWorker'

export const app = createApp(App);

if (process.env.VUE_APP_SMARTLOOK_ENABLED == 'true') {
  Smartlook.init(process.env.VUE_APP_SMARTLOOK_KEY, { region: 'eu' });
}

if (process.env.VUE_APP_SENTRY_ENABLED == 'true') {
  Sentry.init({
    Vue: app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`
  });  
}

app.use(router);
app.use(store);
app.use(i18n);
app.use(VueMobileDetection);
app.use(FloatingVue);

app.use(printDialogService);
app.use(asyncTasksService);
app.use(printersService);

app.use(
  VueGtag, {
    config: {
      id: process.env.VUE_APP_GTAG_ID,
      enabled: process.env.VUE_APP_GTAG_ENABLED == 'true',
      appName: 'Karmen App',
      params: {
        user_id: ''
      }
    }
  },
  router
);

app.config.globalProperties.emitter = mitt();

app.config.globalProperties.$filters = vueFilters;

app.config.globalProperties.$VUE_APP_SHOW_DEV_FEATURES = (process.env.VUE_APP_SHOW_DEV_FEATURES === 'true');

app.config.globalProperties.$PERMISSIONS = permissions;

app.config.errorHandler = ErrorService.onError;

app.mount('#app');

window.onerror = ErrorService.onError;
window.onunhandledrejection = ErrorService.onError;


/////////// >>> THEME (dark/light mode) START >>> ///////////
// set theme (light/dark mode) function
const setBodyClassName = (theme) => {
  document.body.classList.remove('light', 'dark');
  document.body.classList.add(theme);
}

const storedTheme = store.getters['app/getTheme'];
if (storedTheme) {
  // if theme is selected, let's set it
  setBodyClassName(storedTheme)
} else {
  // if theme is not selected, let's set it automatically
  const media = window.matchMedia('(prefers-color-scheme: dark)');
  setBodyClassName(media.matches ? 'dark' : 'light');
  // listen to system theme change
  media.addEventListener('change', function() {
    // if still not selected, let's change current color mode automatically
    if (!store.getters['app/getTheme']) {
      setBodyClassName(media.matches ? 'dark' : 'light');
    }
  });
}
/////////// <<< THEME (dark/light mode) END <<< ///////////
