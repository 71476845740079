<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>{{ $t('views.settings.appearance.title') }}</ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-page-section-box>
        <ui-form>
          <ui-form-select
            v-model:value="locale"
            :labelText="$t('views.settings.langugage')"
            :values="availableLocales"
            id="appLocale"
          />
          <ui-form-select
            v-model:value="selectedTheme"
            :labelText="$t('views.settings.appearance.theme')"
            :values="availableThemes"
            id="theme"
          />
        </ui-form>
      </ui-page-section-box>
    </template>
  </authorized-col-layout>
</template>

<script>
import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormSelect from '@/components/ui/uiFormSelect.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';

export default {
  name: 'SettingsAppearance',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiForm,
    uiFormSelect,
    uiHeading,
    SettingsNavigation
  },

  data: () => {
    return {
      locale: localStorage.getItem('locale') || 'en',
      availableLocales: [
        {id: 'en', name: 'English'},
        {id: 'cs', name: 'Česky'},
      ],
      availableThemes: [
        {id: 'auto', name: 'Auto (system)'},
        {id: 'dark', name: 'Dark Mode'},
        {id: 'light', name: 'Light Mode'}
      ],
      selectedTheme: null
    }
  },

  watch: {
    locale(value) {
      localStorage.setItem('locale', value);
      this.$i18n.locale = value;
    },
    selectedTheme() {
      this.setTheme();
    },
  },

  mounted: function() {
    this.selectedTheme = this.$store.getters['app/getTheme'];
    if (!this.selectedTheme) {
      this.selectedTheme = 'auto';
    }
  },

  methods: {
    setTheme() {
      this.$store.commit('app/setTheme', this.selectedTheme);
    }
  }
}
</script>
