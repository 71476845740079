<template>
  <form class="space-y-6" @submit.stop="submit()" onsubmit="return false;" autocomplete="off">
    <ui-form-error v-if="showError" :text="errorText"></ui-form-error>
    <slot></slot>
  </form>
</template>

<script>
import uiFormError from '@/components/ui/uiFormError.vue';

export default {
  name: 'uiForm',

  components: {
    uiFormError
  },

  emits: ['submit'],

  props: {
    showError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: null
    }
  },

  methods: {
    submit() {
      this.$emit('submit');
      return false;
    }
  }
}
</script>
