<template>
  <div 
    class="relative inline select-none"
    :class="{ 'cursor-default': !hasPermission, 'cursor-context-menu': hasPermission }"
  >
    <div class="inline-flex items-center">
      <ui-button tiny @click.stop.prevent="toggleTypes()" :disabled="!hasPermission" :class="{ 'cursor-context-menu': hasPermission }">
        <ui-icon v-if="loading" name="animate-spin-circle" class="animate-spin h-4 w-4 inline-block m-1" />
        <ui-icon v-else name="filament" class="h-4 w-4 inline-block m-1" />
        <div v-if="name == 'OTHER'" class="italic">- other -</div>
        <div v-else-if="name">{{ name }}</div>
        <div v-else>-----</div>
        <ui-icon v-if="hasPermission" name="chevron-down" class="h-4 w-4 inline-block ml-1" />
      </ui-button>
      <div v-if="enableColors && hasPermission" @click.stop.prevent="toggleColors()" class="w-5 h-5 overflow-hidden ml-1 NULL" :class="color != '' ? [color] : 'NULL'">
        <svg v-if="color=='NULL' || color==null || color==''" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" x="0" y="0" style="fill:rgb(255,255,255);" />
          <line x1="15" y1="1" x2="1" y2="15" style="stroke:red;stroke-width:2" />
        </svg>
      </div>
    </div>

    <div
      v-if="showColors && enableColors && hasPermission"
      class="absolute top-2 md:right-0 z-50"
    >
      <div class="inline-flex sm:border sm:border-t-2 border-gray-200 dark:border-gray-800 shadow-md bg-white-100 dark:bg-black-900 p-3 space-x-1">
        <div
          :key="item"
          v-for="item in colors"
          class="w-5 h-5 NULL cursor-pointer"
          :class="[item]"
          @click.stop.prevent="onColorChangeValue(item)"
        >
          <svg v-if="item=='NULL'" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" x="0" y="0" style="fill:rgb(255,255,255);" />
            <line x1="15" y1="1" x2="1" y2="15" style="stroke:red;stroke-width:2" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <ui-modal v-if="showTypes" autoWidth :show="showTypes" @close="hide()" data-title="FilamentTypeDialog">
    <ui-grid class="border border-1 border-gray-300 mt-2 rounded w-64">
      <ui-grid-item
        v-for="item in filamentTypes"
        :key="item"
        @click.stop.prevent="onTypeChangeValue(item)"
        class="bg-gray-100 dark:bg-gray-900 select-none"
      >
        <ui-grid-item-col>
          <input type="radio" :checked="name==item" />
        </ui-grid-item-col>
        <ui-grid-item-col type="main" class="w-full pl-2">
          <ui-grid-item-col-content type="heading" class="w-full">
            {{ item == 'OTHER' ?  '- other -' : item }}
          </ui-grid-item-col-content>
        </ui-grid-item-col>
      </ui-grid-item>
    </ui-grid>
    <div class="my-2 w-full pt-6">
      <div class="w-full text-center space-x-2">
        <ui-button @click.stop.prevent="hide()">Cancel</ui-button>
      </div>
    </div>
  </ui-modal>
</template>

<style scoped>
.item {
  @apply text-left cursor-pointer p-2 pr-6
    hover:bg-gray-100 dark:hover:bg-gray-750
    text-gray-700 dark:text-gray-400
    hover:text-red-400 dark:hover:text-white-100;
}

.NULL { @apply bg-transparent border border-2 border-gray-300; }

.GRAY { @apply bg-gray-400; }
.BLUE { @apply bg-blue-400; }
.RED { @apply bg-red-400; }
.GREEN { @apply bg-green-400; }
.PINK { @apply bg-pink-400; }
.ORANGE { @apply bg-orange-400; }
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiModal from '@/components/ui/uiModal.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';

export default {
  name: 'FilamentType',

  components: {
    uiIcon,
    uiButton,
    uiModal,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent
  },

  props: {
    name: {
      type: String
    },
    color: {
      type: String,
      default: 'NULL',
      required: false
    },
    enableColors: {
      type: Boolean,
      default: false
    },
    allowEmptyTypeValue: {
      type: Boolean,
      default: false
    },
    hasPermission: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  emits: ['typeChange', 'colorChange'],

  watch: {
    name() {
      this.loading = false;
    },
    color() {
      this.loading = false;
    }
  },

  data: () => ({
    showTypes: false,
    showColors: false,
    filamentTypes: [],
    colors: [
      'GRAY', 'BLUE', 'RED', 'GREEN', 'PINK', 'ORANGE', 'NULL'
    ],
    loading: false
  }),

  created: function() {
    this.filamentTypes = this.$store.getters['app/filamentTypes'];
  },

  methods: {
    toggleTypes() {
      this.showColors = false;
      if (! this.loading) {
        this.showTypes = !this.showTypes;
      }
    },

    toggleColors() {
      this.showTypes = false;
      if (! this.loading) {
        this.showColors = !this.showColors;
      }
    },

    hide() {
      this.showTypes = false;
      this.showColors = false;
    },

    onTypeChangeValue(item) {
      if (item != this.name) {
        this.$emit('typeChange', item);
        this.loading = true;
      }

      this.hide();
    },

    onColorChangeValue(item) {
      // empty string color means NULL
      let color = this.color == '' ? 'NULL' : this.color;

      if (item != color) {
        this.$emit('colorChange', item);
        this.loading = true;
      }

      this.hide();
    }
  }
}
</script>
