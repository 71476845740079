<template>
  <intersection-observer
    class="inline"
    sentinal-name="image-visible"
    @on-intersection-element="handleVisibility()"
  ></intersection-observer>

  <div v-if="loading" class="h-full">
    <ui-loader class="h-full flex-row justify-center"/>
  </div>

  <div
    v-show="!loading"
    class="flex h-full bg-gray-200 dark:bg-gray-900"
  >
    <div
      class="self-center"
      :style="{ 'width': previewWidth + 'px', 'overflow': 'hidden' }
    ">
      <div
        ref="containerRef"
        :style="{ 'width': + (previewWidth * imgCount) + 'px', 'position': 'relative' }"
        @mousemove="updateImagePosition($event)"
        @mouseout="setLastImagePosition()"
      >
        <img ref="imageRef" />
      </div>
    </div>
  </div>
</template>

<script>
import uiLoader from '@/components/ui/uiLoader.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';

export default {
  name: 'VideoPreviewWidget',

  components: {
    uiLoader,
    IntersectionObserver
  },

  props: {
    imgUrl: {
      type: String,
      default: null
    },
    imgCount: {
      type: Number,
      default: 20
    },
    videoId: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data: () => ({
    loading: true,
    previewWidth: 0,
    isVisible: false
  }),

  mounted: function() {
    window.addEventListener('resize', this.updateWidth);

    this.$refs.imageRef.onload = () => {
      this.updateWidth();
      this.setLastImagePosition();
    }
  },

  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  },

  methods: {
    updateWidth() {
      this.previewWidth = this.$el.parentElement.offsetWidth;
      this.setLastImagePosition();
    },

    updateImagePosition(event) {
      let currentOffset = (event.offsetX + parseInt(this.$refs.containerRef?.style['left']));
      let shotNumber = Math.abs(Math.round(currentOffset / this.previewWidth * (this.imgCount - 1)));
      let position = this.previewWidth * shotNumber * -1;
      this.setContainerPosition(position);
    },

    setContainerPosition(pixels) {
      if (this.$refs.containerRef && this.$refs.containerRef.style) {
        this.$refs.containerRef.style['left'] = pixels + 'px';
      }
    },

    setLastImagePosition() {
      let position = this.previewWidth * (this.imgCount - 1) * -1;
      this.setContainerPosition(position);
    },

    handleVisibility() {
      if (!this.isVisible && this.show) {
        this.$nextTick(() => {
          // we know image public URL
          if (this.imgUrl) {
            this.$refs['imageRef'].src = this.imgUrl;
            this.loading = false;

          // we know video private ID
          } else if (this.videoId) {
            this.$store.dispatch('gcodes/downloadVideoPhotoStripeAsObjectURL', {videoId: this.videoId}).then((imgDataUrl) => {
              try {
                this.$refs.imageRef.src = imgDataUrl;
              } catch {
                // sometimes value in $refs may not exists, for example in situation, when user moved on another page before video image was downloaded
              }
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }
    }
  }
}
</script>
