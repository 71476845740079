import { app } from '@/main.js'
import store from './store';
import router from './router';
import { AsyncTask } from '@/asyncTasksService';


export function changeOctoprintConnectionState(state, printerId, printerName) {
  store.commit('printers/setPrinterOnStateChanging', { printerId: printerId, state: true });

  let asyncTask = new AsyncTask(
    // task name
    (state ? 'Connecting printer ' : 'Disconnecting printer ') + printerName,
    // promise for the task
    new Promise((resolve) => {
      if (state) {
        return store.dispatch('printers/connect', { printerId: printerId }).then(() => resolve());
      } else {
        return store.dispatch('printers/disconnect', { printerId: printerId }).then(() => resolve());
      }
    }),

    // onClick callback function
    () => {
      router.push({ name: 'PrinterDetail', params: { id: printerId } });
    }
  );

  app.config.globalProperties.$asyncTasksService.addTask(asyncTask);

  return asyncTask.promise;
}


export function emergencyStop(printerId, printerName) {
  store.commit('printers/setPrinterOnStateChanging', { printerId: printerId, state: true });

  let asyncTask = new AsyncTask(
    // task name
    `${printerName} EMERGENCY STOP`,
    // promise for the task
    new Promise((resolve) => {
      return store.dispatch('printers/executeControlCommand', { printerId: printerId, action: 'emergency_stop' }).then(() => resolve());
    }),

    // onClick callback function
    () => {
      router.push({ name: 'PrinterDetail', params: { id: printerId } });
    }
  );

  app.config.globalProperties.$asyncTasksService.addTask(asyncTask);

  return asyncTask.promise;
}


export function switchPowerOnOff(printerId, printerName, powerState) {
  store.commit('printers/setPrinterOnStateChanging', { printerId: printerId, state: true });

  let asyncTask = new AsyncTask(
    // task name
    (powerState ? 'Turning on printer ' : 'Turning off printer ') + printerName,

    // promise for the task
    new Promise((resolve) => {
      return store.dispatch('printers/changePowerState', { printerId: printerId, state: powerState }).then(() => resolve());
    }),

    // onClick callback function
    () => {
      this.$router.push({ name: 'PrinterDetail', params: { id: printerId } });
    }
  );

  app.config.globalProperties.$asyncTasksService.addTask(asyncTask);

  return asyncTask.promise;
}
