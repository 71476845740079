<template>
  <div
    :class="{
      'pointer-events-none': !enabled,
      'pt-2': text,
    }"
    class="items-center justify-center"
  >

    <div
      v-if="text"
      :class="{ 'opacity-50': !enabled }"
      class="block mb-1 text-sm font-medium text-gray-700 dark:text-gray-400 leading-7 cursor-pointer select-none"
    >
      {{ text }}
    </div>

    <div class="relative">

      <button
        :class="{
          'bg-red-400': value,
          'bg-gray-200 dark:bg-gray-900': !value,
          'opacity-25': loading,
          'opacity-50': !enabled
          }"
        @click="change()"
        type="button"
        class="
          relative
          inline-flex flex-shrink-0
          h-6 w-11
          border-2 dark:border-gray-700 rounded-full
          cursor-pointer
          transition-colors ease-in-out duration-200
          focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400
        "
        aria-pressed="false"
        :disabled="disabled"
      >
        <span class="sr-only">Use setting</span>
        <span aria-hidden="true" :class="{ 'translate-x-5': value, 'translate-x-0': !value }" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white-100 shadow transform ring-0 transition ease-in-out duration-200"></span>
      </button>

      <transition name="modalTransition" appear>
      <div v-if="loading" class="pl-3 mt-0.5 absolute inset-0">
        <ui-icon name="animate-spin-circle" class="animate-spin h-5 w-5 text-gray-700" />
      </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.modalTransition-enter-active,
.modalTransition-leave-active { @apply transform transition ease-out duration-500; }
.modalTransition-enter-from,
.modalTransition-leave-to { @apply opacity-0; }
.modalTransition-enter-to,
.modalTransition-leave-from { @apply opacity-100; }
</style>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiButtonSwitch',

  components: {
    uiIcon
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    automaticValueChange: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    // indicates, wheter component is "active" for user interaction
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    state: false
  }),

  mounted() {
    this.toggle(this.value);
  },

  methods: {
    toggle(state) {
      this.state = state;
    },

    change() {
      if (this.automaticValueChange) {
        this.toggle(!this.state);
        this.$emit('update:value', this.state);
      }
    }
  }
}
</script>
