<template>
    <ui-page-section-box class="border-l-2 border-red-400 relative">
      <div v-if="loading" class="absolute left-0 right-0 top-0 bottom-0 z-50 backdrop-blur-sm bg-white/30"></div>
      <div class="border-b border-gray-200 dark:border-gray-500 px-4 py-5 sm:px-6">
        <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div class="ml-4 mt-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">{{ state.title }}</h3>
            <p class="mt-1 text-sm text-gray-500">{{ state.desc }}</p>
          </div>
        </div>
      </div>
      <div class="grid gap-4 sm:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 mt-6 overflow-hidden">
        <template v-for="item of items" :key="item.id">
          <div
            @click="item._showButtons = true"
            class="cursor-pointer border border-gray-150 dark:border-gray-500 px-2 py-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-750 relative"
            @mouseover="item._showButtons = true"
            @mouseleave="item._showButtons = false"
          >
            <div v-if="item._showButtons" class="absolute left-2 right-2 bottom-2 z-40 drop-shadow-md bg-white-100 dark:bg-gray-800">
              <div class="flex flex-col p-6 space-y-2">
                <ui-button v-if="showShowPrinterBtn" @click.stop="$emit('showPrinterDetail', item)" color="default">{{ $t('views.printQueue.btnGoToPrinter') }}</ui-button>
                <ui-button v-if="showEditBtn" @click.stop="$emit('edit', item)" color="default">{{ $t('views.printQueue.btnEdit') }}</ui-button>
                <ui-button v-if="showPrintBtn" @click.stop="$emit('print', item)" color="default">{{ $t('views.printQueue.btnPrint') }}</ui-button>
                <ui-button v-if="showDuplicateBtn" @click.stop="$emit('duplicate', item)" color="default">{{ $t('views.printQueue.btnDuplicate') }}</ui-button>
                <ui-button v-if="showArchivateBtn" @click.stop="$emit('archivate', item)" color="default">{{ $t('views.printQueue.btnArchivate') }}</ui-button>
                <ui-button v-if="showPrintAgainBtn" @click.stop="$emit('duplicate', item)" color="default">{{ $t('views.printQueue.btnPrintAgain') }}</ui-button>
                <ui-button v-if="showRemoveBtn" @click.stop="$emit('remove', item)" color="clear" small>{{ $t('views.printQueue.btnRemove') }}</ui-button>
              </div>
            </div>
            <div class="w-24 h-24 bg-opacity-10 m-auto">
              <gcode-preview-image
                :gcodeId="item.file.id"
                :previewExists="$filters.previewImageExists(item.file.previewImages)"
                :previewImageId="$filters.primaryPreviewImage(item.file.previewImages)?.id"
                :images="item.file?.previewImages"
              ></gcode-preview-image>
            </div>
            <div class="mt-2 mx-2 text-center dark:text-gray-300 break-all font-semibold text-sm leading-tight">{{ item.file.name }}</div>
            <template v-if="showDetails">
              <div class="mt-1 text-2xs text-center">
                <div>{{ item.printer?.name }}</div>
                <ui-badge v-if="item.jobox_autostart" color="success" class="mb-1">Print automatically</ui-badge>
              </div>
              <div class="text-2xs text-center">{{ $filters.dateTimeFormat(item.added_on) }}</div>
              <div class="text-2xs text-center">{{ item.added_by.username }}</div>
            </template>
          </div>
        </template>
      </div>
    </ui-page-section-box>
</template>

<script>
import uiBadge from '@/components/ui/uiBadge.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';

export default {
  name: 'uiPrintQueueStateBox',

  components: {
    uiBadge,
    uiButton,
    uiPageSectionBox,
    GcodePreviewImage,
  },

  emits: [
    'duplicate',
    'print',
    'archivate',
    'edit',
    'remove',
    'showPrinterDetail'
  ],

  props: {
    state: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showDuplicateBtn: {
      type: Boolean,
      default: false
    },
    showArchivateBtn: {
      type: Boolean,
      default: false
    },
    showPrintAgainBtn: {
      type: Boolean,
      default: false
    },
    showPrintBtn: {
      type: Boolean,
      default: false
    },
    showEditBtn: {
      type: Boolean,
      default: false
    },
    showRemoveBtn: {
      type: Boolean,
      default: true
    },
    showShowPrinterBtn: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: true
    }
  }
}
</script>
