<template>
  <ui-confirm-dialog
    ref="confirmChangePublicDialog"
    :headerText="$t('views.timelapseVideoDetail.confimDialogTitle')"
    :confirmBtnText="$t('components.modal.confirm')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>{{ $t('views.timelapseVideoDetail.confimDialogMessageToPublic') }}</p>
  </ui-confirm-dialog>

  <ui-confirm-dialog
    ref="confirmDeleteDialog"
    :headerText="$t('views.timelapseVideoDetail.confimDeleteDialogTitle')"
    :confirmBtnText="$t('components.modal.delete')"
    :dismissBtnText="$t('components.modal.doNothing')"
  >
    <p>
      {{ $t('views.timelapseVideoDetail.confimDeleteDialogMessage') }}
    </p>
  </ui-confirm-dialog>

  <authorized-layout>
    <ui-heading back v-if="!loading">
      <template v-if="!$isMobile()">
        {{ videoDetail.name }}
      </template>
      <template v-else>
        {{ $t('views.timelapseVideoDetail.title') }}
      </template>
    </ui-heading>

    <div v-if="loading" class="mt-32 mb-32">
      <ui-loader />
    </div>

    <ui-page-section-box tight class="lg:flex lg:p-6" v-if="!loading && videoDetail">
      <video
        :src="this.$store.getters['gcodes/videoDownloadLink'](videoDetail.id, true, 'video')"
        controls
        loop
        class="video w-full lg:w-1/2 self-start"
      >
      </video>

      <div class="lg:pl-8 lg:w-1/2">
        <p
          v-if="$isMobile()"
          class="p-4 mt-4 lg:mt-0 lg:p-0 text-lg dark:text-gray-300 font-semibold overflow-ellipsis overflow-hidden"
        >
          {{ videoDetail.name }}
        </p>

        <ui-description-list class="p-4 lg:p-0">
          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideoDetail.description') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              <div v-html="videoDetail.description"></div>
            </ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideos.printer') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              <template v-if="videoDetail.print_job.printer_exists">
                <router-link :to="{ name: 'PrinterDetail', params: { id: videoDetail.print_job.printer_id } }">{{ videoDetail.print_job.printer_name }}</router-link>
              </template>
              <template v-else>
                {{ videoDetail.print_job.printer_name }}<br>
                <span class="italic text-xs">({{ $t('views.timelapseVideoDetail.originalPrinterDeleted') }})</span>
              </template>
            </ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideos.filename') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              {{ videoDetail.file.name }}
            </ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideoDetail.gcodeFilename') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              <template v-if="videoDetail.print_job.file_exists">
                <router-link
                  :to="{ name: 'GcodeDetail', params: { id: videoDetail.print_job.file_id } }"
                  class="break-all hover:underline"
                >
                  {{ videoDetail.print_job.file_name }}
                </router-link>
              </template>
              <template v-else>
                {{ videoDetail.print_job.file_name }}<br>
                <span class="italic text-xs">({{ $t('views.timelapseVideoDetail.originalFileDeleted') }})</span>
              </template>
            </ui-description-list-definition>
          </ui-description-list-item>
          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideos.user') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              {{ videoDetail.print_job.username }}
            </ui-description-list-definition>
          </ui-description-list-item>

          <ui-description-list-item>
            <ui-description-list-term>
              {{ $t('views.timelapseVideos.date') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              {{ $filters.dateTimeFormat( videoDetail.created_on) }}
            </ui-description-list-definition>
          </ui-description-list-item>

          <ui-description-list-item
            v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)"
          >
            <ui-description-list-term >
              {{ $t('views.timelapseVideoDetail.isPublic') }}
            </ui-description-list-term>
            <ui-description-list-definition>
              <ui-button-switch
                id="is_public"
                v-model:value="videoDetail.is_public"
                @click.stop="openConfirmationDialog()"
                :automaticValueChange="false"
                ref="isPublicButtonSwitch"
              />
            </ui-description-list-definition>
          </ui-description-list-item>
        </ui-description-list>

        <div class="p-4 md:px-0 space-x-4">
          <ui-button @click="downloadVideo">
            {{ $t('components.fileList.actionDownload') }}
          </ui-button>

          <ui-button
            v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_FILES)"
            @click="openDeleteConfirmationDialog()"
            color="clear"
          >
            {{ $t('components.fileList.actionDelete') }}
          </ui-button>
        </div>
      </div>
    </ui-page-section-box>
  </authorized-layout>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiButtonSwitch from '@/components/ui/uiButtonSwitch.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiDescriptionList from '@/components/ui/uiDescriptionList.vue';
import uiDescriptionListItem from '@/components/ui/uiDescriptionListItem.vue';
import uiDescriptionListTerm from '@/components/ui/uiDescriptionListTerm.vue';
import uiDescriptionListDefinition from '@/components/ui/uiDescriptionListDefinition.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiPageSectionBox from "@/components/ui/uiPageSectionBox.vue";
import uiLoader from '@/components/ui/uiLoader.vue';

export default {
  name: 'TimelapseVideoDetail',

  components: {
    AuthorizedLayout,
    uiButton,
    uiButtonSwitch,
    uiConfirmDialog,
    uiDescriptionList,
    uiDescriptionListItem,
    uiDescriptionListTerm,
    uiDescriptionListDefinition,
    uiHeading,
    uiPageSectionBox,
    uiLoader
  },

  props: ['id'],

  data: () => ({
    loading: true,
    videoDetail: null
  }),

  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await this.$store.dispatch('gcodes/loadVideoDetail', {id: this.id});
      this.videoDetail = this.$store.getters['gcodes/videoDetail'];
      this.loading = false;
    },

    downloadVideo() {
      window.open(this.$store.getters['gcodes/videoDownloadLink'](this.videoDetail.id, false, 'video'));
    },

    async openConfirmationDialog() {
      if (this.videoDetail.is_public) {
        // if "is_public = true", automatically set "is_public = false" - don't show confirmation dialog
        await this.$store.dispatch('gcodes/updateVideoIsPublic', {id: this.videoDetail.id, isPublic: false});
        await this.init();
      } else {
        // show confirm dialog when "is_public = false"
        let result = await this.$refs.confirmChangePublicDialog.showDialog();
        if (result) {
          // update "is_public = true"
          await this.$store.dispatch('gcodes/updateVideoIsPublic', {id: this.videoDetail.id, isPublic: true});
          await this.init();
        }
      }
    },

    async openDeleteConfirmationDialog() {
      let result = await this.$refs.confirmDeleteDialog.showDialog();
      if (result) {
        this.loading = true;
        await this.$store.dispatch('gcodes/deleteVideo', {id: this.videoDetail.id});
        this.$router.push({ name: 'TimelapseVideos' });
      }
    }
  }
}
</script>
