<template>
  <authorized-layout>
    <slot name="header"></slot>

    <div class="sm:grid sm:grid-cols-12 gap-4">
      <aside class="sm:col-span-3">
        <slot name="left"></slot>
      </aside>

      <article class="space-y-6 sm:col-span-9">
        <slot name="right"></slot>
      </article>
    </div>
  </authorized-layout>
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';

export default {
  name: 'AuthorizedColLayout',

  components: {
    AuthorizedLayout,
  }
}
</script>
