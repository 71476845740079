<template>
  <teleport to="#modal">
    <transition name="modalTransition" appear>
      <div v-if="show" class="modal-backdrop">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div @click="this.$emit('close')" class="absolute inset-0 bg-black-900 opacity-50"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div class="
              inline-block
              align-bottom sm:align-middle
              bg-white-100 dark:bg-gray-800
              rounded-sm
              text-left
              shadow-xl
              overflow-hidden
              transform transition-all
              border border-1 border-gray-700
            "
            :class="{
              'sm:max-w-2xl': wide,
              'sm:max-w-lg': !wide,
              'w-full': !autoWidth
            }"
            role="dialog" aria-modal="true" aria-labelledby="modal-headline"
          >
            <div v-if="title" class="border-b border-b-2 border-gray-200 dark:border-gray-700 py-3 px-4">
              <h3 class="text-base leading-6 font-medium text-gray-700 dark:text-gray-300">
                {{ title }}
              </h3>
            </div>
            <!-- <div class="px-4 py-5 sm:p-6 sm:my-8"> -->
            <div class="p-4">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
.modalTransition-enter-active,
.modalTransition-leave-active { @apply transform transition ease-out duration-300; }
.modalTransition-enter-from,
.modalTransition-leave-to { @apply opacity-0; }
.modalTransition-enter-to,
.modalTransition-leave-from { @apply opacity-100; }
.modal-backdrop {
  @apply fixed inset-0 overflow-y-auto backdrop-filter backdrop-blur-xs;

  z-index: 100;
}
</style>

<script>
import { useKeypress } from 'vue3-keypress';

export default {
  name: 'uiModal',

  emits: ['close'],

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    dataTitle: {
      type: String
    },
    wide: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show: function() {
      this.$gtag.event('toggle', {
        'event_category': 'Interaction',
        'event_label': 'Modal',
        'value': this.dataTitle,
      })

      this.registerModal();
    }
  },

  data: () => ({
    // this value is used for registering modal in $store, because other components (printer, camera...) needs to know, that they are covered by modal layer
    MODAL_ID: Math.random().toString(36)
  }),

  created: function() {
    useKeypress({
      keyEvent: 'keydown',
      keyBinds: [{
        keyCode: 27, // 27 == ESC
        success: this.emitClose,
        preventDefault: false
      }]
    });
  },

  methods: {
    emitClose() {
      if (this.show) {
        this.$emit('close');
      }
    },

    registerModal(forceClose=false) {
      if (this.show && !forceClose) {
        document.body.classList.add('overflow-hidden');
        this.$store.commit('app/registerOpenedUiModal', this.MODAL_ID);
        this.pollRegisterCleanup();
      } else {
        this.$store.commit('app/removeOpenedUiModal', this.MODAL_ID);
        document.body.classList.remove('overflow-hidden');
        this.emitClose();
      }
    },

    /*
      This function is to check whether this MODAL_ID is not registered as active in $store.
      It can happen in situation, when modal is displayed to user and user will click on browser back button.
      In such situation it's not called in VUE unmounted lifecycle hook etc. So this is workaround.
    */
    pollRegisterCleanup: async function() {
      // if element is not in DOM, it means, that compoment is not registered, so make sure to remove it from $store register
      if (!document.body.contains(this.$el)) {
        this.registerModal(true);
      }

      // if modal is not visible or component is not even in DOM, we don't have to call pollRegisterCleanup
      // as in lines before it was already removed from registry if it was necessary
      if (!this.show || !document.body.contains(this.$el)) {
        return;
      }

      setTimeout(() => {
        this.pollRegisterCleanup();
      }, 1000);
    },
  }
}
</script>
