<template>
  <authorized-col-layout>
    <template v-slot:header>
      <ui-heading back>{{ $t('views.settings.account') }}</ui-heading>
    </template>

    <template v-slot:left>
      <settings-navigation v-if="!$isMobile()" />
    </template>

    <template v-slot:right>
      <ui-modal :show="isKeyMasterModalVisible" @close="hideKeyMasterModal()" data-title="KeyMasterModal" :title="$t('views.settings.newDeviceToken')">
        <div class="mt-2 text-sm text-gray-700 dark:text-gray-400 break-all border-2 p-2 mr-4 w-full">
          {{ newDeviceKeyToken }}
        </div>
        <div class="mt-8 flex justify-center">
          <ui-button @click.stop="hideKeyMasterModal()" color="primary" class="">
            {{ $t('components.modal.close') }}
          </ui-button>
        </div>
      </ui-modal>

      <ui-page-section-box>
        <ui-form>
          <div>
            <ui-form-input
              id="email"
              :labelText="$t('views.settings.emailLabel')"
              :messageText="$t('views.settings.emailMessage')"
              v-model:value="user().email"
              :disabled="true"
            />
          </div>
          <ui-form-input
            id="octoUrl"
            :labelText="$t('views.settings.uploadUrl')"
            v-model:value="octoUrl"
            :disabled="true"
          />
          <p class="mt-8">
            <ui-button @click.stop="showKeyMasterModal()">
              {{ $t('views.settings.createNewToken') }}
            </ui-button>
          </p>
        </ui-form>
      </ui-page-section-box>

      <!-- TELEGRAM -->
      <ui-page-section-box tight class="px-4 pb-6 sm:px-6">
        <ui-heading secondary>
          {{ $t('views.settings.accountSettings.telegramTitle') }}
        </ui-heading>

        <div v-if="!currentUser.is_telegram" class="mb-4">
          {{ $t('views.settings.accountSettings.telegramNotConnectedText') }}
        </div>

        <div v-if="telegramBotName == 'undefined'">
          WARNING: telegram bot name (env.VUE_APP_TELEGRAM_BOT_NAME) is not defined
        </div>

        <!-- Telegram widget placeholder -->
        <div ref="telegram"></div>

        <div v-if="currentUser.is_telegram">
          <div class="mb-4" v-html="$t('views.settings.accountSettings.telegramConnectedText')"></div>
          <ui-button @click.stop="deleteTelegramAuth()">{{ $t('views.settings.accountSettings.telegramDisconnectBtn') }}</ui-button>
        </div>

      </ui-page-section-box>

    </template>
  </authorized-col-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AuthorizedColLayout from '@/components/layouts/AuthorizedColLayout.vue';
import SettingsNavigation from '@/components/SettingsNavigation.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import uiForm from '@/components/ui/uiForm.vue';
import uiFormInput from '@/components/ui/uiFormInput.vue';
import uiModal from '@/components/ui/uiModal.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';

export default {
  name: 'SettingsAccount',

  components: {
    AuthorizedColLayout,
    uiPageSectionBox,
    uiButton,
    uiForm,
    uiFormInput,
    uiHeading,
    uiModal,
    SettingsNavigation
  },

  data: () => ({
    currentUser: null,
    octoUrl: null,
    isKeyMasterModalVisible: false,
    newDeviceKeyToken: null,
    isLoaded: false,
    telegramBotName: process.env.VUE_APP_TELEGRAM_BOT_NAME,
  }),

  created: function() {
    this.currentUser = this.user();

    this.octoUrl = this.octoprintFilesUploadUrl();

    if (!this.currentUser.is_telegram && this.telegramBotName != 'undefined') {
      this.showTelegramWidget();
    }
  },

  methods: {
    ...mapGetters('account', { user: 'user' }),
    ...mapGetters('account', ['octoprintFilesUploadUrl']),
    ...mapActions('account', ['requestNewDeviceKey']),
    ...mapActions('account', ['setTelegramAccount']),
    ...mapActions('account', ['deleteTelegramAccount']),

    showKeyMasterModal() {
      this.newDeviceKeyToken = null;
      this.isKeyMasterModalVisible = true;
      this.requestNewDeviceKey().then((response) => {
        this.newDeviceKeyToken = response.data.token;
      });
    },

    hideKeyMasterModal() {
      this.isKeyMasterModalVisible = false;
    },

    showTelegramWidget() {
      let tag = document.createElement('script');
      tag.async = true

      tag.src = 'https://telegram.org/js/telegram-widget.js?21'
      tag.setAttribute('data-size', 'large')
      tag.setAttribute('data-telegram-login', this.telegramBotName)
      tag.setAttribute('data-request-access', 'write')
      tag.setAttribute('data-radius', '2')
      tag.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
      this.$nextTick(() => {
        this.$refs['telegram'].appendChild(tag);
      });
      window.onTelegramAuth = this.onTelegramAuth;
    },

    async onTelegramAuth(user) {
      await this.setTelegramAccount(user);
      window.location.reload();
    },

    async deleteTelegramAuth() {
      await this.deleteTelegramAccount();
      window.location.reload();
    }
  }
}
</script>
