<template>
  <public-layout>
    <div class="text-center py-24 sm:pt-32">
      <h1 class="mb-10 font-bold text-9xl text-red-400">404</h1>
      <h2 class="mb-16 font-semibold text-xl">We are sorry, the page you requested does not exist.</h2>
      <router-link
        :to="{ name: 'Dashboard' }"
        class="btn"
      >
        Continue to main screen.
      </router-link>
    </div>
  </public-layout>
</template>

<style scoped>
  .btn {
    @apply
      inline-flex justify-center
      py-2 px-3
      border border-transparent
      shadow-sm rounded-sm
      text-sm font-medium
      focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400
      text-white-100 bg-red-400
      hover:bg-red-700;
  }
</style>

<script>
import PublicLayout from '@/components/layouts/PublicLayout.vue';

export default {
  components: {
    PublicLayout
  }
}
</script>
