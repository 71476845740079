<template>
  <a
    v-if="!to"
    class="
      flex items-center
      px-4 py-5 sm:p-4
      hover:bg-gray-100 dark:hover:bg-gray-750
      text-gray-700 dark:text-gray-400
      hover:text-red-400 dark:hover:text-white-100
      text-sm
      select-none
    "
    :class="{'border-t border-t-1 border-gray-200 dark:border-gray-800': divider}"
    aria-current="page"
    @click="trackClick"
  >
    <ui-icon
      v-if="icon && icon != ''"
      :name="icon"
      class="h-4 w-4 mr-2"
    />

    <span class="truncate">
      <slot></slot>
    </span>
  </a>

  <router-link
    v-else-if="to"
    :to="to"
    class="
      flex items-center
      px-4 py-5 sm:p-4
      hover:bg-gray-100 dark:hover:bg-gray-750
      text-gray-700 dark:text-gray-400
      hover:text-red-400 dark:hover:text-white-100
      text-sm
      select-none
    "
    :class="{'border-t border-t-1 border-gray-200': divider}"
    @click="trackClick"
  >
    <span class="truncate">
      <slot></slot>
    </span>
  </router-link>
</template>

<script>
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'uiContextMenuItem',

  components: {
    uiIcon
  },

  props: {
    to: {
      type: Object,
      default: null
    },
    divider: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },

  methods: {
    trackClick() {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('sm:overflow-auto');

      this.$gtag.event('click', {
        'event_category': 'Navigation',
        'event_label': 'Context Menu Item',
        'value': this.$el.dataset.title || this.$el.innerText
      })
    }
  }
}
</script>
