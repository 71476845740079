<template>
  <add-printer-selector-modal
    :show="showAddPrinterDialog"
    :groupId="groupId"
    @close="showAddPrinterDialog=false"
  />

  <authorized-layout>
    <ui-heading back>
      {{ $t('views.printers.title') }}
      <template v-slot:actionButton v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)">
        <div class="flex space-x-2 mr-2 sm:mr-0">
          <ui-button
            @click.stop="openAddSelector()"
            color="secondary"
            :data-title="$t('views.settings.printerAdd')"
            :small="!$isMobile()"
            :tiny="$isMobile()"
          >
            <span>{{ $t('views.settings.printerAdd') }}</span>
          </ui-button>

          <ui-button
            @click.stop="changeVisibilityPrinterGroupDialog(true)"
            :color="'secondary'"
            :data-title="$t('views.settings.printerAdd')"
            :small="!$isMobile()"
            :tiny="$isMobile()"
          >
            <span>{{ $t('components.printerGroup.addPrinterGroupBtn') }}</span>
          </ui-button>
        </div>
      </template>
    </ui-heading>

    <printers-list />
  </authorized-layout>

  <printer-group-dialog :show="showPrinterGroupDialog" @canceled="changeVisibilityPrinterGroupDialog(false)" @saved="init()" />
</template>

<script>
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import AddPrinterSelectorModal from '@/components/AddPrinterSelectorModal.vue';
import PrintersList from '@/components/PrintersList.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiHeading from '@/components/ui/uiHeading.vue';
import PrinterGroupDialog from '@/components/PrinterGroupDialog.vue';

export default {
  name: 'Printers',

  components: {
    AuthorizedLayout,
    AddPrinterSelectorModal,
    PrintersList,
    uiButton,
    uiHeading,
    PrinterGroupDialog
  },

  data: () => ({
    groupId: '',
    showAddPrinterDialog: false,
    showPrinterGroupDialog: false
  }),

  created: function() {
    this.init();
  },

  methods: {
    async init() {
      this.showAddPrinterDialog = false;
      this.showPrinterGroupDialog = false;
    },

    openAddSelector() {
      this.groupId = this.$store.getters['account/currentGroupId'];
      this.showAddPrinterDialog = true;
    },

    changeVisibilityPrinterGroupDialog(visible) {
      this.showPrinterGroupDialog = visible;
    }
  }
}
</script>
