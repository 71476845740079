import { createWebHistory, createRouter } from "vue-router";

import Dashboard from '@/views/Dashboard.vue';
import SelectGroup from '@/views/SelectGroup.vue';
import Printers from '@/views/Printers.vue';
import PrinterDetail from '@/views/PrinterDetail.vue';
import Gcodes from '@/views/Gcodes.vue';
import GcodeDetail from '@/views/GcodeDetail.vue';
import PrintQueue from '@/views/PrintQueue.vue';
import Settings from '@/views/Settings.vue';
import SettingsAccount from '@/views/SettingsAccount.vue';
import SettingsPrinters from '@/views/SettingsPrinters.vue';
import SettingsPrinterAddOrEdit from '@/views/SettingsPrinterAddOrEdit.vue';
import SettingsPrinterAddOctoprint from '@/views/SettingsPrinterAddOctoprint.vue';
import SettingsNotifications from '@/views/SettingsNotifications.vue';
import SettingsWorkspaces from '@/views/SettingsWorkspaces.vue';
import SettingsWorkspaceAddOrEdit from '@/views/SettingsWorkspaceAddOrEdit.vue';
import SettingsWorkspaceInviteUsers from '@/views/SettingsWorkspaceInviteUsers.vue';
import SettingsAppearance from '@/views/SettingsAppearance.vue';
import SettingsApiTokens from '@/views/SettingsApiTokens.vue';
import SettingsApiTokensAdd from '@/views/SettingsApiTokensAdd.vue';
import TimelapseVideos from '@/views/TimelapseVideos.vue';
import TimelapseVideoDetail from '@/views/TimelapseVideoDetail.vue';
import News from '@/views/News.vue';
import NewsDetail from '@/views/NewsDetail.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Registration from '@/views/Registration.vue';
import RegistrationConfirm from '@/views/RegistrationConfirm.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PasswordResetConfirm from '@/views/PasswordResetConfirm.vue';

import NotFound from '@/views/NotFound.vue';

import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { gtm: 'Dashboard' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/select-group',
    name: 'SelectGroup',
    component: SelectGroup,
    meta: { gtm: 'SelectGroup' },
    beforeEnter: [checkAuth]
  },
  {
    path: '/printers',
    name: 'Printers',
    component: Printers,
    meta: { gtm: 'Printers' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/printers/:id',
    name: 'PrinterDetail',
    meta: { gtm: 'PrinterDetail' },
    component: PrinterDetail,
    props: true,
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/gcodes',
    name: 'Gcodes',
    component: Gcodes,
    meta: { gtm: 'Gcodes' },
    props: { labels: []},
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/gcodes/:id',
    name: 'GcodeDetail',
    meta: { gtm: 'GcodeDetail' },
    component: GcodeDetail,
    props: true,
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/print-queue',
    name: 'PrintQueue',
    component: PrintQueue,
    meta: { gtm: 'PrintQueue' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/timelapse-videos',
    name: 'TimelapseVideos',
    meta: { gtm: 'TimelapseVideos' },
    component: TimelapseVideos,
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/timelapse-videos/:id',
    name: 'TimelapseVideoDetail',
    meta: { gtm: 'TimelapseVideoDetail' },
    component: TimelapseVideoDetail,
    props: true,
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { gtm: 'Settings' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/account',
    name: 'SettingsAccount',
    component: SettingsAccount,
    meta: { gtm: 'SettingsAccount' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/workspaces/create',
    name: 'SettingsWorkspaceAdd',
    component: SettingsWorkspaceAddOrEdit,
    props: { action: 'create' },
    meta: { gtm: 'SettingsWorkspaceAdd' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/workspaces/:id',
    name: 'SettingsWorkspaceEdit',
    component: SettingsWorkspaceAddOrEdit,
    props: route => ({ id: route.params.id, action: 'edit' }),
    meta: { gtm: 'SettingsWorkspaceEdit' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/workspaces/:id/invite-users',
    name: 'SettingsWorkspaceInviteUsers',
    component: SettingsWorkspaceInviteUsers,
    props: route => ({ id: route.params.id }),
    meta: { gtm: 'SettingsWorkspaceInviteUsers' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/workspaces',
    name: 'SettingsWorkspaces',
    component: SettingsWorkspaces,
    meta: { gtm: 'SettingsWorkspaces' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/printers/:groupId/create',
    name: 'SettingsPrinterAdd',
    component: SettingsPrinterAddOrEdit,
    props: route => ({ groupId: route.params.groupId, action: 'create' }),
    meta: { gtm: 'SettingsPrinterAdd' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/printers/:groupId/create-octoprint',
    name: 'SettingsPrinterAddOctoprint',
    component: SettingsPrinterAddOctoprint,
    props: route => ({ groupId: route.params.groupId }),
    meta: { gtm: 'SettingsPrinterAddOctoprint' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/printers/:groupId/:id',
    name: 'SettingsPrinterEdit',
    component: SettingsPrinterAddOrEdit,
    props: route => ({ groupId: route.params.groupId, id: route.params.id, action: 'edit' }),
    meta: { gtm: 'SettingsPrinterEdit' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/printers',
    name: 'SettingsPrinters',
    component: SettingsPrinters,
    meta: { gtm: 'SettingsPrinters' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/notifications',
    name: 'SettingsNotifications',
    component: SettingsNotifications,
    meta: { gtm: 'SettingsNotifications' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/api-tokens',
    name: 'SettingsApiTokens',
    component: SettingsApiTokens,
    meta: { gtm: 'SettingsApiTokens' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/api-tokens/create',
    name: 'SettingsApiTokensAdd',
    component: SettingsApiTokensAdd,
    meta: { gtm: 'SettingsApiTokensAdd' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/settings/appearance',
    name: 'SettingsAppearance',
    component: SettingsAppearance,
    meta: { gtm: 'SettingsAppearance' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: { gtm: 'News' },
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    meta: { gtm: 'NewsDetail' },
    component: NewsDetail,
    props: true,
    beforeEnter: [checkAuth, checkGroupSelected]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { gtm: 'Login' },
    beforeEnter: [dashboardIfAuthorized]
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { gtm: 'Logout' },
    component: Logout
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { gtm: 'Registration' },
    beforeEnter: [dashboardIfAuthorized]
  },
  {
    path: '/registration/confirm',
    name: 'RegistrationConfirm',
    component: RegistrationConfirm,
    props: route => ({ token: route.query.activate }),
    meta: { gtm: 'RegistrationConfirm' },
    beforeEnter: [dashboardIfAuthorized]
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { gtm: 'PasswordReset' },
    beforeEnter: [dashboardIfAuthorized]
  },
  {
    path: '/password-reset/confirm',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    props: route => ({ token: route.query.reset }),
    meta: { gtm: 'PasswordResetConfirm' },
    beforeEnter: [dashboardIfAuthorized]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: { gtm: '404' },
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // scroll page to top on route change
  window.scrollTo(0, 0)
});

async function checkAuth(to, from, next) {
  try {
    await store.dispatch('account/hasPermission');
    next();
  } catch (e) {
    next({ name: 'Logout' });
    throw(e);
  }
}

async function checkGroupSelected(to, from, next) {
  try {
    if (await store.getters['account/currentGroupId']) {
      next();
    } else {
      next({ name: 'SelectGroup' });
    }
  } catch (e) {
    next({ name: 'Logout' });
    throw(e);
  }
}

async function dashboardIfAuthorized(to, from, next) {
  try {
    await store.dispatch('account/hasPermission');
    next({ name: 'Dashboard' });
  } catch (e) {
    next();
    throw(e);
  }
}

export default router;
